import React from 'react';

const FileInput = ({ fileList, handleFileChange, handleFileDelete }) => {
  return (
    <div className="select-file-container">
      {/* <label htmlFor="selectedFiles" className="input-label">
        
      </label> */}
      <div className="file-input-wrapper">
        <label className="file-input-button">
          Browse files to upload
          <input
            type="file"
            accept=".pdf"
            onChange={handleFileChange}
            className="file-input"
            multiple
          />
        </label>
      </div>
      <div className="selected-files-list">
        {fileList.map((file, index) => (
          <div key={index} className="file-item">
            <span className="file-name">{file.name}</span>
            <span
              className="delete-button"
              onClick={() => handleFileDelete(index)}
            >
              &#x2715;
            </span>
          </div>
        ))}
      </div>
      
    </div>
  );
};

export default FileInput;
