import React from 'react';
import Header from './Header';
import SideBar from './Sidebar';

const Layout = (Component) => ({ ...props }) => (
  <div className="layout">
    
    <div className="container">
      <SideBar />
      <div className="main-container">
        <Component {...props} />
      </div>
    </div>
 </div>
);

export default Layout;