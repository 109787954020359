import React from "react";

function SearchIcon(props: any) {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M6.80524 15.7137C5.9404 14.8306 5.35381 13.7131 5.11824 12.4997C4.88072 11.2829 5.00269 10.0233 5.46924 8.87469C5.93181 7.73253 6.72153 6.75251 7.73924 6.05769C9.80409 4.64744 12.5224 4.64744 14.5872 6.05769C15.605 6.75251 16.3947 7.73253 16.8572 8.87469C17.3238 10.0233 17.4458 11.2829 17.2082 12.4997C16.9727 13.7131 16.3861 14.8306 15.5212 15.7137C14.3759 16.889 12.8044 17.5519 11.1632 17.5519C9.52213 17.5519 7.95059 16.889 6.80524 15.7137V15.7137Z" 
            stroke="currentColor" {...props} 
            strokeWidth="1.5" 
            strokeLinecap="round" 
            strokeLinejoin="round"/>
            <path d="M11.1702 7.20292C10.7583 7.24656 10.4598 7.61586 10.5034 8.02777C10.5471 8.43968 10.9164 8.73821 11.3283 8.69457L11.1702 7.20292ZM13.0216 9.69213C13.1831 10.0736 13.6232 10.2519 14.0047 10.0904C14.3861 9.92892 14.5644 9.4888 14.4029 9.10736L13.0216 9.69213ZM16.1421 15.0869C15.849 14.7943 15.3741 14.7947 15.0815 15.0879C14.7888 15.381 14.7893 15.8559 15.0824 16.1485L16.1421 15.0869ZM18.4704 19.5305C18.7636 19.8232 19.2384 19.8228 19.5311 19.5296C19.8237 19.2364 19.8233 18.7616 19.5301 18.4689L18.4704 19.5305ZM11.3283 8.69457C12.0508 8.61801 12.7384 9.02306 13.0216 9.69213L14.4029 9.10736C13.8622 7.83005 12.5496 7.05676 11.1702 7.20292L11.3283 8.69457ZM15.0824 16.1485L18.4704 19.5305L19.5301 18.4689L16.1421 15.0869L15.0824 16.1485Z" 
            fill="currentColor" {...props}/>
        </svg>
    );
}

export default SearchIcon;
