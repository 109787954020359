// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.layout {
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.container {
 display: flex; 
 height: 100%;
 flex-grow: 1;
}

.main-container {
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-grow: 1;
  padding: 60px;
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,aAAa;EACb,sBAAsB;AACxB;;AAEA;CACC,aAAa;CACb,YAAY;CACZ,YAAY;AACb;;AAEA;EACE,WAAW;EACX,gBAAgB;EAChB,aAAa;EACb,YAAY;EACZ,aAAa;AACf","sourcesContent":[".layout {\n  height: 100vh;\n  display: flex;\n  flex-direction: column;\n}\n\n.container {\n display: flex; \n height: 100%;\n flex-grow: 1;\n}\n\n.main-container {\n  width: 100%;\n  overflow: hidden;\n  display: flex;\n  flex-grow: 1;\n  padding: 60px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
