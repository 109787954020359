import React from "react";

function TaskIcon(props: any) {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M9.0852 17.982L7.3182 18.882C7.24952 18.9162 7.16799 18.9121 7.10305 18.8713C7.03811 18.8304 6.99914 18.7587 7.0002 18.682V5.955C6.9896 5.4384 7.39961 5.01093 7.9162 5H17.0832C17.5998 5.01093 18.0098 5.4384 17.9992 5.955V18.682C18.0003 18.7587 17.9613 18.8304 17.8963 18.8713C17.8314 18.9121 17.7499 18.9162 17.6812 18.882L15.9142 17.982C15.8438 17.9461 15.7595 17.9514 15.6942 17.996L14.2722 18.962C14.1986 19.0121 14.1018 19.0121 14.0282 18.962L12.6222 18.007C12.5486 17.9569 12.4518 17.9569 12.3782 18.007L10.9722 18.962C10.8986 19.0121 10.8018 19.0121 10.7282 18.962L9.3052 18C9.2407 17.9542 9.1563 17.9472 9.0852 17.982Z" 
            stroke="currentColor" {...props} 
            stroke-width="1.5" 
            stroke-linecap="round" 
            stroke-linejoin="round"/>
            <path d="M9.49915 11.25C9.08493 11.25 8.74915 11.5858 8.74915 12C8.74915 12.4142 9.08493 12.75 9.49915 12.75V11.25ZM15.4991 12.75C15.9134 12.75 16.2491 12.4142 16.2491 12C16.2491 11.5858 15.9134 11.25 15.4991 11.25V12.75ZM10.4991 13.25C10.0849 13.25 9.74915 13.5858 9.74915 14C9.74915 14.4142 10.0849 14.75 10.4991 14.75V13.25ZM14.4991 14.75C14.9134 14.75 15.2491 14.4142 15.2491 14C15.2491 13.5858 14.9134 13.25 14.4991 13.25V14.75ZM10.4991 9.25C10.0849 9.25 9.74915 9.58579 9.74915 10C9.74915 10.4142 10.0849 10.75 10.4991 10.75V9.25ZM14.4991 10.75C14.9134 10.75 15.2491 10.4142 15.2491 10C15.2491 9.58579 14.9134 9.25 14.4991 9.25V10.75ZM9.49915 12.75H15.4991V11.25H9.49915V12.75ZM10.4991 14.75H14.4991V13.25H10.4991V14.75ZM10.4991 10.75H14.4991V9.25H10.4991V10.75Z" 
            fill="currentColor" {...props}/>
        </svg>
    );
}

export default TaskIcon;