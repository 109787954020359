import React, { createContext, useState, useEffect } from 'react';
import { backend } from '../../api/axios';
import Cookies from 'js-cookie';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true); // Add loading state

  useEffect(() => {
    //GET TOKENS AND EXPIRY DATES
    const token = Cookies.get('token');
    const refreshToken = Cookies.get('refreshToken');
    const tokenExpiration = new Date(Cookies.get('tokenExpiration'));
    const refreshTokenExpiration = new Date(Cookies.get('refreshTokenExpiration'));

    //GET USERNAME
    const userId = localStorage.getItem('user_id');
    console.log("**token", token)

    if (userId && token && refreshToken) {
      if (refreshTokenExpiration > new Date()) {
        setUser(userId);
        backend.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      }
      else { console.log("**logout", token); logout(); }

    }

    setLoading(false); // Set loading to false after checking the user's session
  }, []);

  const getUserIDByUsername = async (username) => {
    try {
      const response = await backend.get('/user');
      const users = response.data;
      const userWithMatchingUsername = users.find((user) => user.username === username);
      console.log(userWithMatchingUsername);
      console.log(username);
      if (userWithMatchingUsername) {
        return userWithMatchingUsername._id;
      }
    } catch (error) {
      console.error('Error fetching users:', error);
    }
    return null;
  };

  const login = async (username, token, refreshToken) => {
    backend.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    console.log("login auth provider")
    setToken(token);
    setRefreshToken(refreshToken);
    return new Promise(async (resolve) => {
      const user_id = await getUserIDByUsername(username);
      if (user_id) {
        setUser(user_id);
        localStorage.setItem('user_id', user_id);
        localStorage.setItem('username', username);
      }
      resolve(user_id);
    })
  };

  const logout = () => {
    console.log("** logout called")
    setUser(null);
    localStorage.removeItem('user_id');
    localStorage.removeItem('username');
    localStorage.removeItem('mindmapID')
    Cookies.remove('token');
    Cookies.remove('refreshToken');
    Cookies.remove('tokenExpiration');
    Cookies.remove('refreshTokenExpiration');

    delete backend.defaults.headers.common['Authorization'];
  };

  console.log("Set this user: ", user);
  return (
    <AuthContext.Provider value={{ user, login, logout, loading }}> {/* Pass loading state to context */}
      {children}
    </AuthContext.Provider>
  );
};

// Function to set the token with a 15-minute expiration
export function setToken(token) {
  const tokenExpiration = new Date();
  tokenExpiration.setMinutes(tokenExpiration.getMinutes() + 15);
  console.log("Set Token");
  //Cookies.set('token', token, { expires: tokenExpiration });
  Cookies.set('token', token);
  Cookies.set('tokenExpiration', tokenExpiration);
}

// Function to set the refresh token with a 7-day expiration
export function setRefreshToken(refreshToken) {
  const refreshTokenExpiration = new Date();
  refreshTokenExpiration.setDate(refreshTokenExpiration.getDate() + 7);

  Cookies.set('refreshToken', refreshToken, { expires: refreshTokenExpiration });
  Cookies.set('refreshTokenExpiration', refreshTokenExpiration);

}