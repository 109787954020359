
import React, { useState, useRef, useEffect } from "react";

import { connect, useSelector, useDispatch } from 'react-redux';
import { editCard, deleteCard } from '../../reducers/listReducer.js';
import TextareaAutosize from 'react-textarea-autosize';
import CommentsSection from './Comment.jsx'
import { backendURL } from "../../api/axios.js";
import { Icon, Checkbox, Select, MenuItem, InputLabel } from "@mui/material";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import styled from "styled-components";
import './Card.css';
import TaskIcon from "../../Icons/Task.tsx";
import CalendarIcon from "../../Icons/Calendar_Check.tsx";
import GoalIcon from "../../Icons/Bullseye-pointer.tsx";
import UserIcon from "../../Icons/User.tsx";

import DescriptionIcon from "../../Icons/Hamburger.tsx";
import AddIcon from "../../Icons/Circle_Add.tsx";
import RichTextEditor from "./RichTextEditor.jsx";

import CrossIcon from "../../Icons/Cross.tsx";
import ActivityIcon from "../../Icons/Arrow-trend-up.tsx";
import WatchIcon from "../../Icons/Stop Watch.tsx";

const styles = {
  dropDown: {
    backgroundColor: 'unset',
    height: 'max-content',
    outline: '1px solid #d0d1d280',
    borderRadius: 30,
    color: 'black',
    margin: 0,
    padding: '5px 20px',
    fontFamily: "Raleway",
    boxShadow: 'none'
  }
}
const CommunitySidebar = ({ listID, onClose, cardDetails, handleInputChange, handleDeleteCard, handleCancel, handleSaveCard, handleChange, options, selectedOptions, id, projects, setProjects, selectedProject, setSelectedProject, setCardDetails, idToTitleMapping
}) => {

  const tag_options = [
    { value: 'todo', label: 'Todo' },
    { value: 'in_progress', label: 'In Progress' },
    { value: 'done', label: 'Done' }
  ];
  const [isTitleEditing, setIsTitleEditing] = useState(false);

  /*try{
  const [editorState, setEditorState] = useState(EditorState.createWithContent(convertFromRaw(cardDetails.description === '' ? 'Add description...' : cardDetails.description)));
  }
  catch{
    const [editorState, setEditorState] = useState(EditorState.createEmpty());
  }*/
  const dispatch = useDispatch();
  const modalRef = useRef();
  const dialogRef = useRef();
  const selectRef = useRef();

  {/*useEffect(() => {
    const handleClickOutside = (event) => {
      console.log('outside',modalRef.current,!modalRef.current.contains(event.target),dialogRef.current,selectRef.current,!selectRef.current.contains(event.target) )
      if (modalRef.current && !modalRef.current.contains(event.target)  &&
      dialogRef.current &&
      !dialogRef.current.contains(event.target) &&
      selectRef.current &&
      !selectRef.current.contains(event.target) ){
        onClose();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onClose]);*/}


  const [open, setOpen] = useState(false);

  const handleOpenDialog = () => {
    setOpen(true);
  };

  const handleCloseDialog = () => {
    setOpen(false);
  };


  const handleTitleDoubleClick = () => {
    setIsTitleEditing(true);
  };


  const handleTitleBlur = () => {
    setIsTitleEditing(false);
  };



  const handleDropDownChange = (event) => {
    if (event.target.value === "new") {
      handleOpenDialog()
    }
    else {
      setSelectedProject(event.target.value);
      dispatch(editCard({ id: id, listID: listID, card: { ...cardDetails, project: event.target.value } }));
    }
  };




  const handleCreateProject = async () => {
    // Add your logic to handle creating a new project
    const newProjectName = selectedProject; // This is the name of the new project

    // Call your fetch API here to create the new project
    try {
      const response = await fetch(backendURL + "/boards/" + cardDetails.boardId + "/projects", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ projectId: newProjectName })
      });

      if (!response.ok) {
        throw new Error('Failed to create project');
      }

      setProjects([...projects, newProjectName]);
      console.log('Project created successfully');
    } catch (error) {
      console.error('Error creating project:', error);
      // Handle error gracefully, show a message to the user, etc.
    }
    handleCloseDialog();

  };


  const currentDate = new Date();

  // Get local date and time components
  const year = currentDate.getFullYear();
  const month = currentDate.getMonth();
  const day = currentDate.getDate();
  const hours = currentDate.getHours();
  const minutes = currentDate.getMinutes();
  const seconds = currentDate.getSeconds();
  const milliseconds = currentDate.getMilliseconds();

  // Create a new Date object without GMT offset
  const dateWithoutGMT = new Date(year, month, day, hours, minutes, seconds, milliseconds);

  const [showForm, setShowForm] = useState(false);
  const [newItemData, setNewItemData] = useState({
    title: 'none',
    description: '',
    assignee: options.length > 0 ? options[0].value : '',
    listId: listID,
    dueDate: dateWithoutGMT,
    boardId: cardDetails.boardId,
    tag: 'todo'
  });

  const handleInputChange2 = (e) => {
    const { name, value } = e.target;
    console.log("inp", name, value, new Date().toISOString())


    setNewItemData(prevData => ({
      ...prevData,
      [name]: value
    }));

  };
  const handleSubTaskDropDown = (e, index) => {
    const { name, value } = e.target;

    setCardDetails(prevState => ({
      ...prevState,
      child: prevState.child.map((child, i) => {
        if (i === index) {
          return {
            ...child,
            tag: value
          };
        }
        return child;
      })
    }));
    const updateTag = async () => {
      try {
        // Fetch request to update the tag of the child (subtask)
        const response = await fetch(`${backendURL}/cards/${cardDetails.child[index]._id}/tag`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ tag: value }),
        });

        if (!response.ok) {
          throw new Error('Failed to update tag');
        }

        console.log('Tag updated successfully');
      } catch (error) {
        console.error('Error updating tag:', error.message);
      }
    };

    // Call the inner asynchronous function
    updateTag();
  };

  const handleAddItemClick = () => {
    setShowForm(true);
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    // Create a new child card
    try {
      console.log("New card", newItemData)
      const newCard = await createChildCard(cardDetails._id, {
        title: newItemData.title,
        description: newItemData.description,
        listId: newItemData.listId,
        boardId: cardDetails.boardId,
        assignee: [newItemData.assignee],
        dueDate: new Date(newItemData.dueDate).toISOString(),
        // Add other properties as needed
      });


      // Update state with the new card
      setCardDetails(prevState => ({
        ...prevState,
        child: [...prevState.child, newCard]
      }));

      // Reset the form data and hide the form
      setNewItemData({
        title: 'none',
        description: '',
        assignee: options[0].value,
        listId: listID,
        dueDate: new Date(),
        boardId: cardDetails.boardId,
        tag: 'todo'
      });
      setShowForm(false);
    } catch (error) {
      console.error('Error creating child card:', error);
      // Handle error (e.g., show error message to user)
    }
  };

  const createChildCard = async (parentId, cardData) => {
    try {
      console.log("New card", parentId, cardData)
      const response = await fetch(`${backendURL}/cards/${parentId}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(cardData),
      });
      if (!response.ok) {
        throw new Error('Failed to create child card');
      }
      return await response.json();
    } catch (error) {
      console.error('Error creating child card:', error);
      throw error; // You might want to handle errors more gracefully in your application
    }
  };


  return (
    <div className="backdrop">
      <div className="card-details" ref={modalRef}>

        {/* TASK TITLE */}
        <div className="detail-row head">
          <TaskIcon></TaskIcon>
          <div style={{ marginLeft: 15 }} onDoubleClick={handleTitleDoubleClick} onBlur={handleTitleBlur}>
            {isTitleEditing ? (
              <textarea
                className="hidden-input h2"
                type="text"
                autoFocus
                margin="dense"
                id="title"
                name="title"
                placeholder="Title"
                fullWidth
                value={cardDetails.title}
                onChange={handleInputChange}
              />
            ) : (
              <h2 className="h2">{cardDetails.title}</h2>
            )}
          </div>
        </div>

        <div className="row">
          <div className="column">

            {/* PROJECT */}
            <div className="detail-row">
              <GoalIcon></GoalIcon>
              <span className="description-label">Project</span>
              <div className="detail-text">
                <select
                  // style={styles.dropDown}
                  labelId="single-select-label"
                  id="single-select"
                  value={selectedProject}
                  label="Project"
                  onChange={handleDropDownChange}
                  ref={selectRef}
                >
                  {projects.map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                  <option value="new" >Create new project</option>
                </select>
                <Dialog open={open} onClose={handleCloseDialog} ref={dialogRef}>
                  <DialogTitle>Create New Project</DialogTitle>
                  <DialogContent>
                    <TextField
                      autoFocus
                      margin="dense"
                      id="projectName"
                      label="Project Name"
                      type="text"
                      fullWidth
                      onChange={handleDropDownChange}
                    />
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleCloseDialog}>Cancel</Button>
                    <Button onClick={handleCreateProject} color="primary">Create</Button>
                  </DialogActions>
                </Dialog>
                {/* <select className="select"
                    labelId="single-select-label"
                    id="single-select"
                    value='1'
                    label="Project"
                  >
                    {projects.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select> */}
              </div>
            </div>
          </div>

          <div className="column">

            {/* ASSIGNEE */}
            <div className="detail-row">
              <UserIcon></UserIcon>
              <span className="description-label">Assignee</span>
              <div className="detail-text">
                {options.length > 0 ? (value => options.find(option => option.value === value).label) : 'No Assignee'}
              </div>
            </div>

          </div>
        </div>
        <div className="row">
          <div className="column">

            {/* DUE DATE */}
            <div className="detail-row">
              <CalendarIcon></CalendarIcon>
              <span className="description-label">Due Date</span>
              <div className="detail-text">
                <input
                  margin="dense"
                  id="dueDate"
                  name="dueDate"
                  placeholder="Due Date"
                  type="datetime-local"
                  value={cardDetails.dueDate ? cardDetails.dueDate.slice(0, 16) : ''}
                  onChange={handleInputChange}
                />
              </div>
            </div>
          </div>
          <div className="column">
            <div className="detail-row">
              <UserIcon></UserIcon>
              <span className="description-label">XP</span>
              <div className="detail-text">
                <input
                  margin="dense"
                  id="xp"
                  name="xp"
                  placeholder="xp"
                  type="number"
                  value={cardDetails.xp || 0}
                  onChange={handleInputChange}
                />
              </div>
            </div>

          </div>
          {/*<div className="column">
            <div className="detail-row">
              <TagIcon></TagIcon>
              <span className="description-label">Elapsed Time</span>
              <div className="detail-text">
              <input
                  margin="dense"
                  id="expectedHours"
                  name="expectedHours"
                  placeholder="Expected Hours"
                  type="number"
                  value={cardDetails.expectedHours}
                  onChange={handleInputChange}
              />
              </div>

              <TagIcon></TagIcon>
              <span className="description-label">Estimated Time</span>
              <div className="detail-text">
              <input
                  margin="dense"
                  id="actualHours"
                  name="actualHours"
                  placeholder="Elapased Hours"
                  type="number"
                  value={cardDetails.actualHours}
                  onChange={handleInputChange}
              />
              </div>

            </div>
            
                  </div>*/}
        </div>
        <hr></hr>

        {/* DESCRIPTION */}
        <div className="description-subtasks-header">
          <DescriptionIcon></DescriptionIcon>
          <span className="description-label">Description</span>
        </div>
        <div className="detail-text marginRight">
          {/*{isDescriptionEditing ? (
            <input
              type="text"
              value={desc}
              onChange={(e) => setDesc(e.target.value)}

              autoFocus // autofocus on input field when it appears
            />
          ) : (
            <div onClick={handleDescriptionDoubleClick} style={{ cursor: 'pointer' }}>
              {desc}
            </div>
          )}*/}
          <RichTextEditor cardDetails={cardDetails} setCardDetails={setCardDetails} />
        </div>
        {/*isDescriptionEditing ? <button onClick={handlesaveDesc}>Save</button> : <div></div>*/}
        <hr></hr>

        {/* SUB TASKS */}
        <div className="description-subtasks-header">
          <DescriptionIcon></DescriptionIcon>
          <span className="description-label">Sub-tasks</span>
        </div>
        <div className="detail-text marginRight">
          <form className="new-subtask-form" onSubmit={handleFormSubmit}>
            <table>
              <thead>
                <tr>
                  <th style={{ width: 40 }}></th>
                  <th style={{ width: '40%' }}>Description</th>
                  <th>Assignee</th>
                  <th>Status</th>
                  <th>Due</th>
                  <th></th>
                </tr>
              </thead>

              <tbody>
                {cardDetails.child.map((child, index) =>
                  <tr key={index}>
                    <td><TaskIcon></TaskIcon></td>
                    <td>{child.description}</td>
                    <td>{child.assignee.length > 0 && options.length > 0 ? options.find(option => option.value === child.assignee[0]).label : ''}</td>
                    <td>
                      <select
                        id={`sub-select-${index}`}
                        onChange={(e) => handleSubTaskDropDown(e, index)}
                        label="Status"
                        value={child.tag}
                        name="tag"
                      >
                        {tag_options.map(option => (
                          <option key={option.value} value={option.value}>{option.label}</option>
                        ))}
                      </select>
                    </td>
                    <td>{new Date(child.dueDate).toLocaleDateString('en-GB', { day: 'numeric', month: 'long', year: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true })}</td>
                    <td></td>
                  </tr>)}

                {!showForm ? (
                  <span onClick={handleAddItemClick} className="new-button">
                    <AddIcon /> New
                  </span>
                ) : (

                  <tr>
                    <td><TaskIcon /></td>
                    <td>
                      <input type="text" name="description" placeholder="Description" value={newItemData.description} onChange={handleInputChange2} />
                    </td>
                    <td><select
                      id="sub-select"
                      name="assignee"
                      value={newItemData.assignee}
                      onChange={handleInputChange2}
                      label="Assignees"
                    >
                      {options.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </select></td>
                    {/* <br></br> */}
                    {/*<select
                id="sub-select"
                onChange={handleInputChange2}
                label="Status"
                value={newItemData.listId}
                name="listId"
              >
                { Object.entries(idToTitleMapping).map(([key, value]) => (
                  <option key={key} value={key}>{value}</option>
                ))}
                </select>*/}
                    <td><select
                      id="sub-select"
                      onChange={handleInputChange2}
                      label="Status"
                      value={newItemData.tag}
                      name="tag"
                    >
                      {tag_options.map(option => (
                        <option key={option.value} value={option.value}>{option.label}</option>
                      ))}
                    </select></td>
                    <td><input
                      margin="dense"
                      id="dueDate"
                      name="dueDate"
                      placeholder="Due Date"
                      type="datetime-local"
                      value={newItemData.dueDate}
                      onChange={handleInputChange2}
                    /></td>
                    <td>
                      <button className="button-icon" type="submit"><AddIcon /></button>
                      <button className="button-icon" onClick={e => setShowForm(false)}><CrossIcon ></CrossIcon></button>
                      {/* <button className="button-outline" onClick={e => setShowForm(false)}>Close</button> */}
                    </td>
                  </tr>


                )}

              </tbody>

            </table>
          </form>

          {/* {!showForm ? (
            <span onClick={handleAddItemClick} className="new-button">
              <AddIcon /> New
            </span>
          ) : (
            <form className="new-subtask-form" onSubmit={handleFormSubmit}>
              <input type="text" name="description" placeholder="Description" value={newItemData.description} onChange={handleInputChange2} />
              <select
                id="sub-select"
                name="assignee"
                value={newItemData.assignee}
                onChange={handleInputChange2}
                label="Assignees"
              >
                {options.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
              <br></br>
              {/*<select
                id="sub-select"
                onChange={handleInputChange2}
                label="Status"
                value={newItemData.listId}
                name="listId"
              >
                { Object.entries(idToTitleMapping).map(([key, value]) => (
                  <option key={key} value={key}>{value}</option>
                ))}
                </select>*/}
          {/* <select
                id="sub-select"
                onChange={handleInputChange2}
                label="Status"
                value={newItemData.tag}
                name="tag"
              >
                {tag_options.map(option => (
                  <option key={option.value} value={option.value}>{option.label}</option>
                ))}
              </select>
              <input
                margin="dense"
                id="dueDate"
                name="dueDate"
                placeholder="Due Date"
                type="datetime-local"
                value={newItemData.dueDate}
                onChange={handleInputChange2}
              />
              <button type="submit">Add</button>
              <button onClick={e => setShowForm(false)}>Close</button>
            </form>
          )} */}
        </div>
        <hr></hr>

        {/* COMMENTS */}
        <div className="description-subtasks-header">
          <ActivityIcon />
          <span className="description-label">Activity</span>
        </div>
        {cardDetails.checklist.map((checklist) => <Checkbox />)}
        <CommentsSection comment={cardDetails.comments} id={id} users={options} />
        <hr></hr>
        <div className="row">
          <div className="column">

            {/* ELAPSED TIME */}
            <div className="detail-row">
              <WatchIcon />
              <span className="description-label">Elapsed Time</span>
              <div className="detail-text">
                <input
                  margin="dense"
                  id="expectedHours"
                  name="expectedHours"
                  placeholder="Expected Hours"
                  type="number"
                  value={cardDetails.expectedHours}
                  onChange={handleInputChange}
                  style={{ width: "7em" }}
                />
              </div>
            </div>
          </div>
          <div className="column">
            <div className="detail-row">

              {/* ESTIMATED TIME */}
              <div className="detail-row">
                <WatchIcon />
                <span className="description-label">Estimated Time</span>
                <div className="detail-text">
                  <input
                    margin="dense"
                    id="actualHours"
                    name="actualHours"
                    placeholder="Elapased Hours"
                    type="number"
                    value={cardDetails.actualHours}
                    onChange={handleInputChange}
                    style={{ width: "7em" }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '50px' }}>
          <button className="delete-button-outline" onClick={handleDeleteCard}>Delete task</button>
          <div>
            <button onClick={onClose}>Close</button>
            {/* <button onClick={handleSaveCard}>Save</button> */}
          </div>
        </div>
      </div>

    </div>
  );
};
export default CommunitySidebar;