import { useEffect, useState } from 'react';
import { Grid, Box, TextField, Select, MenuItem, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, IconButton } from '@mui/material';

import SearchIcon from '@mui/icons-material/Search';
import DateRangeIcon from '@mui/icons-material/DateRange';
import GoalIcon from "../../Icons/Bullseye-pointer.tsx";
import TaskIcon from "../../Icons/Task.tsx";
import { Stack } from '@mui/material';
import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isBetween from 'dayjs/plugin/isBetween';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import StatusChip from './StatusChip.jsx';
// Extend dayjs with the plugins
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);
dayjs.extend(isBetween);

const TableComponent = () => {
  const [selectedProject, setSelectedProject] = useState("All Projects");
  const [searchVisible, setSearchVisible] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [dateFilter, setDateFilter] = useState('All Dates');
  const [customDateRange, setCustomDateRange] = useState([null, null]);
  const [sortSettings, setSortSettings] = useState({});
  const data = [
    // Today (October 31, 2024)

    { task: "Final Testing of Features", status: "In Progress", dueDate: "2024-10-31", assignees: ["Ali Khan", "Sara Khan"], project: "Project Alpha" }, // Today's date
    { task: "Bug Fixes for UI", status: "Completed", dueDate: "2024-10-30", assignees: ["Sara Khan", "Hannan Sattar"], project: "Project Alpha" }, // Yesterday
    { task: "Client Review Meeting", status: "Pending", dueDate: "2024-11-02", assignees: ["Hannan Sattar", "Ali Khan"], project: "Project Alpha" }, // Upcoming
    { task: "Code Review", status: "Completed", dueDate: "2024-10-29", assignees: ["Ali Khan"], project: "Project Alpha" }, // Earlier this week
    { task: "Deployment to Production", status: "Completed", dueDate: "2024-10-28", assignees: ["Sara Khan", "Hannan Sattar"], project: "Project Alpha" }, // Earlier this week
    { task: "Documentation Updates", status: "Completed", dueDate: "2024-10-27", assignees: ["Sara Khan"], project: "Project Alpha" }, // Last week
    { task: "User Acceptance Testing", status: "Completed", dueDate: "2024-11-01", assignees: ["Hannan Sattar", "Ali Khan"], project: "Project Alpha" }, // Tomorrow


    // This Week (October 28, 2024, to November 3, 2024)
    { task: "Weekly Team Sync", status: "Pending", dueDate: "2024-10-30", assignees: ["Hannan Sattar"], project: "Team Management" },
    { task: "Deploy New Features to Production", status: "In Progress", dueDate: "2024-11-01", assignees: ["Laraib Jamal"], project: "Project INQUIDSITIVE" },
    { task: "Prepare Release Notes", status: "In Progress", dueDate: "2024-10-29", assignees: ["Talha Ahmed"], project: "Team Management" },
    { task: "Conduct User Acceptance Testing", status: "Pending", dueDate: "2024-11-02", assignees: ["Muhammad Ali"], project: "Project INQUIDSITIVE" },

    // This Month (October and November 2024)
    { task: "User Training Sessions", status: "In Progress", dueDate: "2024-11-20", assignees: ["Talha Ahmed"], project: "HR Team" },
    { task: "Finalize Budget for Next Month", status: "Completed", dueDate: "2024-11-15", assignees: ["Ayesha Noor"], project: "HR Team" },
    { task: "Monthly Team Performance Review", status: "Pending", dueDate: "2024-11-25", assignees: ["Fizah Khalid"], project: "HR Team" },
    { task: "Data Migration to New System", status: "In Progress", dueDate: "2024-11-05", assignees: ["Ali Khan"], project: "Project INQUIDSITIVE" }
  ];



  const getInitials = (name) => {
    const names = name.split(" ");
    const initials = names.map((n) => n[0]).join("");
    return initials.toUpperCase();
  };
  const getRandomColor = (index) => {
    const colors = ['#EAAA00', '#107A9E', '#3357FF', '#FF33A8', '#FFC300']; // Predefined set of colors
    return colors[index % colors.length]; // Cycle through the colors
  };

  const groupedData = data.reduce((acc, item) => {
    acc[item.project] = acc[item.project] || [];
    acc[item.project].push(item);
    return acc;
  }, {});

  const handleSort = (project, field) => {
    const currentSort = sortSettings[project];
    const newSortOrder = currentSort?.sortBy === field && currentSort?.sortOrder === 'asc' ? 'desc' : 'asc';

    const newSortSettings = {
      ...sortSettings,
      [project]: {
        sortBy: field,
        sortOrder: newSortOrder,
      },
    };

    setSortSettings(newSortSettings);

    // Save to local storage
    localStorage.setItem('sortSettings', JSON.stringify(newSortSettings));
  };

  useEffect(() => {
    // Load sort settings from local storage
    const savedSortSettings = localStorage.getItem('sortSettings');
    if (savedSortSettings) {
      setSortSettings(JSON.parse(savedSortSettings));
    }
  }, []);


  const getSortedData = (projectData, project) => {
    const { sortBy = "task", sortOrder = "asc" } = sortSettings[project] || {};
    return [...projectData].sort((a, b) => {
      const aValue = a[sortBy];
      const bValue = b[sortBy];
      if (sortOrder === "asc") {
        return aValue < bValue ? -1 : aValue > bValue ? 1 : 0;
      } else {
        return bValue < aValue ? -1 : bValue > aValue ? 1 : 0;
      }
    });
  };
  const handleDateFilterChange = (e) => {
    setDateFilter(e.target.value);
    // Reset custom date range if changing to a non-custom filter
    if (e.target.value !== 'Custom Date') {
      setCustomDateRange([null, null]);
    }
  };

  const filterByDate = (itemDate) => {
    const currentDate = dayjs();
    const itemDueDate = dayjs(itemDate);

    switch (dateFilter) {
      case "Today":
        return itemDueDate.isSame(currentDate, 'day');

      case "This Week":
        return itemDueDate.isSame(currentDate, 'week');

      case "This Month":
        return itemDueDate.isSame(currentDate, 'month');

      case "Custom Date":
        const [startDate, endDate] = customDateRange.map(date => dayjs(date));
        return startDate.isValid() && endDate.isValid() ? itemDueDate.isBetween(startDate, endDate, null, '[]') : true;

      default:
        return true;
    }
  };


  return (
    <Box sx={{ flexGrow: 1, height: '90vh', overflow: 'auto' }}>
      {/* Header with Select and Date Filter */}
      <Box sx={{ position: 'sticky', top: 0, zIndex: 1000, padding: '10px 0' }}>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end', width: '80%', marginLeft: 'auto', marginRight: '5%', marginBottom: '20px' }}>
          <Box sx={{ display: 'flex', alignItems: 'center', marginRight: '10px' }}> {/* Added gap between elements */}
            <DateRangeIcon />

            <Select
              labelId="date-filter-label"
              value={dateFilter}
              onChange={handleDateFilterChange}
              label="Date Filter"
              sx={{
                borderRadius: '20px',
                height: '36px',
                minWidth: '120px', // Optional: Ensure the select has a minimum width
                marginRight: '10px'
              }}
            >
              <MenuItem value="All Dates">All Dates</MenuItem>
              <MenuItem value="Today">Today</MenuItem>
              <MenuItem value="This Week">This Week</MenuItem>
              <MenuItem value="This Month">This Month</MenuItem>
              <MenuItem value="Custom Date">Custom Date</MenuItem>
            </Select>

            {dateFilter === 'Custom Date' && (
              <div style={{ display: 'flex', gap: '10px', alignItems: 'center', marginRight: '10px' }}>
                <input
                  type="date"
                  value={customDateRange[0] || ''}
                  onChange={(e) => setCustomDateRange([e.target.value, customDateRange[1]])}
                  style={{
                    background: '#DBC389',
                    padding: '4px',
                    borderRadius: '20px',
                    height: '36px',
                    border: '1px solid #ccc',
                    flex: '1',
                  }}
                />
                <input
                  type="date"
                  value={customDateRange[1] || ''}
                  onChange={(e) => setCustomDateRange([customDateRange[0], e.target.value])}
                  style={{
                    background: '#DBC389',
                    padding: '4px',
                    borderRadius: '20px',
                    height: '36px',
                    border: '1px solid #ccc',
                    flex: '1',
                  }}
                />
              </div>
            )}
          </Box>

          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <GoalIcon sx={{ marginRight: '8px' }} />
            <Select
              value={selectedProject}
              onChange={(e) => setSelectedProject(e.target.value)}
              displayEmpty
              sx={{ marginRight: '10px', fontSize: '0.875rem', height: '36px', borderRadius: '20px', '& .MuiSelect-select': { padding: '0 10px' } }}
            >
              <MenuItem value="All Projects">All Projects</MenuItem>
              {Object.keys(groupedData).map((project) => (
                <MenuItem key={project} value={project}>{project}</MenuItem>
              ))}
            </Select>
          </Box>
          <IconButton onClick={() => setSearchVisible(!searchVisible)}>
            <SearchIcon />
          </IconButton>
          {searchVisible && (
            <TextField
              placeholder="Search by status or assignee"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              variant="outlined"
              size="small"
              sx={{ width: '30%', marginLeft: '5px', '& .MuiOutlinedInput-root': { borderRadius: '20px' } }}
            />
          )}
        </Box>


        <hr style={{ margin: '20px 0', borderColor: '#ccc', borderWidth: '1px' }} />
      </Box>

      {/* Table Data Filtering */}
      <Box sx={{ height: 'calc(90vh - 100px)', overflowY: 'auto', padding: '10px' }}>
        {Object.keys(groupedData).map((project) => (
          (selectedProject === "All Projects" || selectedProject === project) && (
            <Grid container spacing={1} justifyContent="center" key={project}>
              <Grid item xs={12} sm={10} md={8} lg={10}>
                <TableContainer sx={{ width: '100%', padding: '30px', border: '1px solid #ccc', borderRadius: '35px', overflow: 'hidden', boxShadow: 2, marginBottom: '25px' }}>
                  <Stack direction="row" alignItems="center" spacing={1} sx={{ ml: 2 }}>
                    <GoalIcon />
                    <Typography variant="h7" sx={{ fontWeight: 'bold' }}>{project}</Typography>
                  </Stack>
                  <Table sx={{ minWidth: 450 }} size="small">

                    <TableHead>
                      <TableRow>
                        <TableCell></TableCell>
                        <TableCell sx={{ fontWeight: 'bold' }}>
                          Task
                        </TableCell>
                        <TableCell sx={{ fontWeight: 'bold', cursor: 'pointer', display: 'flex', alignItems: 'center' }} onClick={() => handleSort(project, 'status')}>
                          Status
                          {sortSettings[project]?.sortBy === 'status' && (
                            sortSettings[project]?.sortOrder === 'asc' ? (
                              <ArrowDropUpIcon sx={{ marginLeft: '4px' }} />
                            ) : (
                              <ArrowDropDownIcon sx={{ marginLeft: '4px' }} />
                            )
                          )}
                        </TableCell>

                        <TableCell sx={{ fontWeight: 'bold' }}>
                          Due Date
                        </TableCell>
                        <TableCell sx={{ fontWeight: 'bold' }}>
                          Assignees
                        </TableCell>
                      </TableRow>
                    </TableHead>


                    <TableBody>
                      {getSortedData(groupedData[project], project)
                        .filter((item) => filterByDate(item.dueDate))
                        .filter((item) =>
                          item.status.toLowerCase().includes(searchTerm.toLowerCase()) ||
                          item.assignees.some((assignee) => assignee.toLowerCase().includes(searchTerm.toLowerCase()))
                        )
                        .map((item, index) => (
                          <TableRow key={item.task}>
                            <TableCell component="th" scope="row" sx={{ padding: 0, marginRight: '-4px' }}> {/* Adjust negative margin as needed */}
                              <TaskIcon />
                            </TableCell>
                            <TableCell align="left" sx={{
                              padding: 0,
                              maxWidth: '150px', // Set a maximum width to limit space
                              whiteSpace: 'nowrap',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                            }}>
                              {item.task}
                            </TableCell>
                            <TableCell>
                              <StatusChip status={item.status} /> {/* Use StatusChip here */}
                            </TableCell>
                            <TableCell>{item.dueDate}</TableCell>
                            <TableCell>
                              {item.assignees.map((assignee, index) => (
                                <div
                                  key={assignee}
                                  style={{
                                    display: 'inline-flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    marginRight: '5px',
                                    width: '30px', // Set a fixed width
                                    height: '30px', // Set a fixed height
                                    borderRadius: '50%', // Make it circular
                                    backgroundColor: getRandomColor(index), // Use a function to get a random color
                                    color: '#fff', // White text color
                                    fontWeight: 'bold', // Bold text
                                    fontSize: '14px', // Adjust font size as necessary
                                  }}
                                >
                                  {getInitials(assignee)}
                                </div>
                              ))}
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>

                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
          )
        ))}
      </Box>
    </Box >
  );
};

export default TableComponent;
