import React from "react";

function TagIcon(props: any) {
    return (<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M6.885 6.67244L10.529 3.52744C11.3836 2.82419 12.6164 2.82419 13.471 3.52744L17.115 6.67244C17.4887 6.98222 17.7079 7.44011 17.715 7.92544L18 18.0004C18 19.6573 16.6569 21.0004 15 21.0004H9C7.34315 21.0004 6 19.6573 6 18.0004L6.282 7.92544C6.28982 7.43962 6.51021 6.98165 6.885 6.67244Z" 
        stroke="currentColor" {...props} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M10 13.2504C9.58579 13.2504 9.25 13.5862 9.25 14.0004C9.25 14.4147 9.58579 14.7504 10 14.7504V13.2504ZM14 14.7504C14.4142 14.7504 14.75 14.4147 14.75 14.0004C14.75 13.5862 14.4142 13.2504 14 13.2504V14.7504ZM9 16.2504C8.58579 16.2504 8.25 16.5862 8.25 17.0004C8.25 17.4147 8.58579 17.7504 9 17.7504V16.2504ZM15 17.7504C15.4142 17.7504 15.75 17.4147 15.75 17.0004C15.75 16.5862 15.4142 16.2504 15 16.2504V17.7504ZM10.5 8.50045L11.25 8.50078L10.5 8.50045ZM11.708 7.02982L11.562 6.29417L11.708 7.02982ZM13.386 7.92764L12.693 8.21438V8.21438L13.386 7.92764ZM12.8329 9.74862L12.4165 9.12487L12.8329 9.74862ZM10.939 9.56144L10.4085 10.0916L10.939 9.56144ZM10 14.7504H14V13.2504H10V14.7504ZM9 17.7504H15V16.2504H9V17.7504ZM11.25 8.50078C11.2502 8.14299 11.503 7.83513 11.854 7.76547L11.562 6.29417C10.5091 6.50314 9.75049 7.42672 9.75 8.50011L11.25 8.50078ZM11.854 7.76547C12.2049 7.69582 12.5562 7.88377 12.693 8.21438L14.0791 7.64089C13.6687 6.64905 12.6148 6.08521 11.562 6.29417L11.854 7.76547ZM12.693 8.21438C12.8298 8.54499 12.714 8.9262 12.4165 9.12487L13.2494 10.3724C14.1421 9.77635 14.4894 8.63273 14.0791 7.64089L12.693 8.21438ZM12.4165 9.12487C12.1189 9.32355 11.7224 9.28437 11.4695 9.03128L10.4085 10.0916C11.1673 10.8508 12.3567 10.9684 13.2494 10.3724L12.4165 9.12487ZM11.4695 9.03128C11.3289 8.89056 11.2499 8.69973 11.25 8.50078L9.75 8.50011C9.74973 9.09695 9.98661 9.66944 10.4085 10.0916L11.4695 9.03128Z" 
        fill="currentColor" {...props}/>
    </svg>
    );
}

export default TagIcon;
