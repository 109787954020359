import React, { useState } from 'react';
import { Box, IconButton } from '@mui/material';
import SendIcon from '../../Icons/Send_2.tsx';
const ChatFooter = ({ setMessages, messages }) => {
  const [message, setMessage] = useState('');
  const userID = localStorage.getItem('user_id');

  // Function to send the message to the backend
  const sendMessage = async (userID, message) => {
    const url = "https://quids.app/inq_cbot/receive_message/";
    const headers = { 'Content-Type': 'application/json' };
    const payload = { user_id: userID, user_message: message };

    try {
      const res = await fetch(url, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(payload),
      });

      if (res.ok) {
        const jsonResponse = await res.json();
        // Add the bot's response to the messages array
        setMessages(prevMessages => [
          ...prevMessages,
          { name: "bot", text: jsonResponse.response }
        ]);
      } else {
        const errorResponse = await res.text();
        console.log(errorResponse);
      }
    } catch (err) {
      console.log(`Error: ${err.message}`);
    }
  };

  // Handle sending the message when the user clicks send or presses Enter
  const handleSendMessage = (e) => {
    e.preventDefault();
    if (message.trim()) {
      // Add the user's message to the messages array
      setMessages(prevMessages => [
        ...prevMessages,
        { name: "user", text: message }
      ]);
      sendMessage(userID, message);  // Send message to the backend
      setMessage('');  // Clear the input field
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        position: "absolute",
        bottom: 0,
        left: 0,
        right: 0,
        padding: "10px",
        backgroundColor: "#fff",
      }}
    >
      <Box
        sx={{
          flex: 1,
          padding: "10px",
          backgroundColor: "#f0f0f0",
          borderRadius: "20px",
          marginRight: "10px",
        }}
      >
        <form onSubmit={handleSendMessage}>
          <input
            type="text"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            placeholder="I am just starting out, what policies/documents should I be aware of?"
            style={{
              width: '100%',
              border: 'none',
              outline: 'none',
              background: 'transparent',
              textAlign: 'left'
            }}
          />
        </form>
      </Box>
      <button className="comment-send-button" onClick={handleSendMessage}>
        <SendIcon />
      </button>
    </Box>
  );
};

export default ChatFooter;
