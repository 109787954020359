import React from "react";

function CalendarIcon(props: any) {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M5 12V15C5 17.7614 7.23858 20 10 20H14C15.3261 20 16.5979 19.4732 17.5355 18.5355C18.4732 17.5979 19 16.3261 19 15V12C19.0013 10.6735 18.475 9.40097 17.537 8.463C16.599 7.52503 15.3265 6.99867 14 7H10C8.67351 6.99867 7.40097 7.52503 6.463 8.463C5.52503 9.40097 4.99867 10.6735 5 12V12Z" 
            stroke="currentColor" {...props} 
            stroke-width="1.5" 
            stroke-linecap="round" 
            stroke-linejoin="round"/>
            <path d="M8.25 9C8.25 9.41421 8.58579 9.75 9 9.75C9.41421 9.75 9.75 9.41421 9.75 9H8.25ZM9.75 5C9.75 4.58579 9.41421 4.25 9 4.25C8.58579 4.25 8.25 4.58579 8.25 5H9.75ZM14.25 9C14.25 9.41421 14.5858 9.75 15 9.75C15.4142 9.75 15.75 9.41421 15.75 9H14.25ZM15.75 5C15.75 4.58579 15.4142 4.25 15 4.25C14.5858 4.25 14.25 4.58579 14.25 5H15.75ZM9.53033 13.4697C9.23744 13.1768 8.76256 13.1768 8.46967 13.4697C8.17678 13.7626 8.17678 14.2374 8.46967 14.5303L9.53033 13.4697ZM11 16L10.4697 16.5303C10.7626 16.8232 11.2374 16.8232 11.5303 16.5303L11 16ZM15.5303 12.5303C15.8232 12.2374 15.8232 11.7626 15.5303 11.4697C15.2374 11.1768 14.7626 11.1768 14.4697 11.4697L15.5303 12.5303ZM9.75 9V5H8.25V9H9.75ZM15.75 9V5H14.25V9H15.75ZM8.46967 14.5303L10.4697 16.5303L11.5303 15.4697L9.53033 13.4697L8.46967 14.5303ZM11.5303 16.5303L15.5303 12.5303L14.4697 11.4697L10.4697 15.4697L11.5303 16.5303Z" 
            fill="currentColor" {...props}/>
        </svg>
    );
}

export default CalendarIcon;