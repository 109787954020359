// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.list-title {
    background-color: #c5d0e23f;
    padding: 5px 10px;
    border-radius: 20px;
    border-left: 20px solid #C5D0E2;
    border-right: 20px solid #C5D0E2;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    cursor: text;
}

.list_title {
    margin: 0;
}

.list-title-edit {
    border: 0;
    outline: 0;
    padding: 0;
    padding: 5px 30px 4px 30px;
    margin-top: 1px;
    border-radius: 20px;
    outline: 1px solid rgba(0, 0, 0, 0.4);
    background-color: unset;
    font-family: "Montserrat";
    font-weight: 900;
    font-size: 20px;    
    width: 100%;
}

.list-length {
    font-size: 20px;
    color: rgba(40, 46, 49, 0.4);
}`, "",{"version":3,"sources":["webpack://./src/components/Kanban/List.css"],"names":[],"mappings":"AAAA;IACI,2BAA2B;IAC3B,iBAAiB;IACjB,mBAAmB;IACnB,+BAA+B;IAC/B,gCAAgC;IAChC,mBAAmB;IACnB,aAAa;IACb,8BAA8B;IAC9B,YAAY;AAChB;;AAEA;IACI,SAAS;AACb;;AAEA;IACI,SAAS;IACT,UAAU;IACV,UAAU;IACV,0BAA0B;IAC1B,eAAe;IACf,mBAAmB;IACnB,qCAAqC;IACrC,uBAAuB;IACvB,yBAAyB;IACzB,gBAAgB;IAChB,eAAe;IACf,WAAW;AACf;;AAEA;IACI,eAAe;IACf,4BAA4B;AAChC","sourcesContent":[".list-title {\n    background-color: #c5d0e23f;\n    padding: 5px 10px;\n    border-radius: 20px;\n    border-left: 20px solid #C5D0E2;\n    border-right: 20px solid #C5D0E2;\n    margin-bottom: 20px;\n    display: flex;\n    justify-content: space-between;\n    cursor: text;\n}\n\n.list_title {\n    margin: 0;\n}\n\n.list-title-edit {\n    border: 0;\n    outline: 0;\n    padding: 0;\n    padding: 5px 30px 4px 30px;\n    margin-top: 1px;\n    border-radius: 20px;\n    outline: 1px solid rgba(0, 0, 0, 0.4);\n    background-color: unset;\n    font-family: \"Montserrat\";\n    font-weight: 900;\n    font-size: 20px;    \n    width: 100%;\n}\n\n.list-length {\n    font-size: 20px;\n    color: rgba(40, 46, 49, 0.4);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
