import React, { useState, useEffect } from 'react';
import { experimentalStyled as styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import CircleIcon from '@mui/icons-material/Circle';
import MenuOpenSharpIcon from '@mui/icons-material/MenuOpenSharp';
import AddIcon from "../../Icons/Circle_Add";
import { List, ListItem, ListItemAvatar, ListItemText, Typography, TextField, IconButton } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import SearchIcon from '@mui/icons-material/Search';
const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    borderRadius: '35px',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));
const spaces = [
    { id: 1, name: 'Quids ML', color: '#ff5722' },
];

const Chat = () => {
    const [isHidden, setIsHidden] = useState(true); // State to control visibility
    const [isSmallScreen, setIsSmallScreen] = useState(false); // State for screen size
    const [selectedSpace, setSelectedSpace] = useState(null);


    const [isSearchOpen, setIsSearchOpen] = useState(false);

    const handleSearchClick = () => {
        setIsSearchOpen(true);
    };

    const handleBlur = () => {
        setIsSearchOpen(false);
    };

    const handleListItemClick = (space) => {
        setSelectedSpace(space);  // space should be the clicked item
    };


    const toggleVisibility = () => {
        setIsHidden((prev) => !prev);
    };

    const handleResize = () => {
        setIsSmallScreen(window.innerWidth < 600); // Adjust the threshold as needed
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        handleResize(); // Check screen size on mount

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    const spaces = [
        { name: 'QuidsML', color: '#FF6F00' }, // Orange

    ];
    const dummyMessages = [
        {
            id: 1,
            content: "Hello! How's everything going with the project?",
            senderName: "Alice",
            avatarUrl: "", // Leave empty for fallback initials
            type: "check-in", // Content type
            userId: 1, // Unique identifier for the user
        },
        {
            id: 2,
            content: "These adjustments ensure that your message content box behaves correctly within the grid structure, wrapping text as needed without overflowing its container. If you're still experiencing issues with overflow, make sure that the parent containers (like the one containing dummyMessages) have appropriate widths and styles to accommodate their children.",
            senderName: "Bob",
            avatarUrl: "", // Leave empty for fallback initials
            type: "task-check-in-update", // Content type
            userId: 2, // Unique identifier for the user
        },
        {
            id: 3,
            content: "Don't forget to review the new guidelines.",
            senderName: "Charlie",
            avatarUrl: "", // Leave empty for fallback initials
            type: "check-out", // Content type
            userId: 3, // Unique identifier for the user
        },
        {
            id: 4,
            content: "Let me know if you need any help with the design.",
            senderName: "Dana",
            avatarUrl: "", // Leave empty for fallback initials
            type: "task-check-out-update", // Content type
            userId: 4, // Unique identifier for the user
        },
        {
            id: 5,
            content: "Looking forward to our meeting tomorrow!",
            senderName: "Eve",
            avatarUrl: "", // Leave empty for fallback initials
            type: "check-in", // Content type
            userId: 5, // Unique identifier for the user
            
        },
        {
            id: 5,
            content: "Looking forward to our meeting tomorrow!",
            senderName: "Eve",
            avatarUrl: "", // Leave empty for fallback initials
            type: "check-in", // Content type
            userId: 5, // Unique identifier for the user
        },
    ];
    const getAvatarColor = (userId) => {
        const colors = [
            '#007bff', // Blue
            '#dc3545', // Red
            '#28a745', // Green
            '#ffc107', // Yellow
            '#17a2b8', // Teal
            '#6610f2', // Purple
            '#fd7e14', // Orange
        ];
        return colors[userId % colors.length]; // Cycle through the colors based on userId
    };
    return (
        <Box
            sx={{
                flexGrow: 1,
                height: '100vh',
                width: '100vw',
                padding: '20px',
                boxSizing: 'border-box',
                overflow: 'hidden',
            }}
        >
            <Grid
                container
                spacing={{ xs: 2, md: 3 }}
                columns={{ xs: 4, sm: 8, md: 12 }}
                style={{ height: '90%', justifyContent: 'flex-end' }} // Align content to the right
            >
                {/* Render the grid above the content grid if on small screen and hidden */}
                {/* {isHidden && isSmallScreen && (
                    <Grid item xs={12} sx={{ position: 'absolute', top: '50%', left: 0, width: '100%', zIndex: 1 }}>
                        <Item>
                            <IconButton onClick={toggleVisibility} aria-label='Show grid item'>
                                <MenuOpenSharpIcon />
                            </IconButton>
                        </Item>
                    </Grid>
                )} */}
                {/* Positioning the button */}
                <Box sx={{ position: 'absolute', top: 90, left: 200 }}>
                    <IconButton onClick={toggleVisibility} aria-label='Hide grid item'>
                        <MenuOpenSharpIcon />
                    </IconButton>
                </Box>
                {/* Small grid item shown when not hidden and not on small screen */}
                {!isHidden && !isSmallScreen && (

                    <Grid
                        item
                        xs={4}
                        sm={4}
                        md={3}
                        sx={{ height: '100%' }} // Ensure the Grid takes full height
                    >
                        <Item
                            sx={{
                                position: 'relative',
                                padding: '20px', // Keeps padding around the parent item
                                height: '100%', // Match height with the grid above
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'flex-start', // Align items to start with no gaps
                            }}
                        >
                            {isHidden && (
                                <Box sx={{ position: 'absolute', top: '100px', left: '2px' }}>
                                    <IconButton onClick={toggleVisibility} aria-label='Show grid item'>
                                        <MenuOpenSharpIcon />
                                    </IconButton>
                                </Box>
                            )}
                            {/* Box with text and round shape + icon */}
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    height: '100%',
                                    borderRadius: '10px',
                                    width: '100%',
                                    padding: '0px',
                                    marginTop: '0px',
                                    overflow: 'auto',
                                    boxSizing: 'border-box',
                                }}
                            >
                                {/* Top section: Spaces text and icon on the same row */}
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        width: '100%',
                                    }}
                                >
                                    {/* Text on the left */}
                                    <span style={{ fontWeight: 'bold', fontFamily: 'Raleway', color: '#282E31', fontSize: '20px' }}>
                                        Spaces
                                    </span>

                                    {/* Icon on the right */}
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            width: '30px',
                                            height: '30px',
                                        }}
                                    >
                                        <span style={{ fontWeight: 'bold', color: '#282E31', fontSize: '50px' }}>
                                            <AddIcon />
                                        </span>
                                    </Box>
                                </Box>
                                <Box
                                    sx={{
                                        flexGrow: 1,
                                        overflowY: 'auto',
                                        marginTop: '0px',
                                        padding: '0px',
                                    }}
                                >
                                    <List
                                        sx={{
                                            padding: '0px', // Remove padding from the List component itself
                                        }}
                                    >
                                        {spaces.map((space, index) => (
                                            <ListItem
                                                button
                                                key={index}
                                                sx={{
                                                    padding: '0px',
                                                    marginBottom: '1px',
                                                    alignItems: 'center',
                                                }}
                                                onClick={() => handleListItemClick(space)}
                                            >
                                                {/* Simple round circle */}
                                                <Box
                                                    sx={{
                                                        width: 24,
                                                        height: 24,
                                                        borderRadius: '50%',
                                                        backgroundColor: space.color,
                                                        marginRight: '8px',
                                                    }}
                                                />
                                                <ListItemText
                                                    primary={
                                                        <Typography
                                                            variant="body1"
                                                            sx={{ fontWeight: space.bold ? 'bold' : 'normal' }}
                                                        >
                                                            {space.name}
                                                        </Typography>
                                                    }
                                                />
                                            </ListItem>
                                        ))}
                                    </List>
                                </Box>
                            </Box>
                        </Item>
                    </Grid>


                )}
                {/* Main content grid item, positioned on the right */}
                <Grid
                    item
                    xs={isHidden || isSmallScreen ? 12 : 8}
                    sm={isHidden || isSmallScreen ? 12 : 8}
                    md={isHidden || isSmallScreen ? 12 : 9}
                    sx={{ height: '100%' }} // Ensure the Grid takes full height
                >
                    <Item
                        sx={{
                            position: 'relative',
                            padding: '20px',
                            height: '100%',

                            flexDirection: 'column',
                            justifyContent: 'space-between',
                            overflowY: 'auto', // Allows scrolling when content exceeds max-height
                        }}
                    >
                        {/* Heading Section */}
                        {selectedSpace && (
                            <Box
                                sx={{
                                    width: '100%',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    borderBottom: '1px solid #ccc',
                                    paddingBottom: '10px',
                                    textAlign: 'left',
                                }}
                            >
                                {/* Title Section */}
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    <Box
                                        sx={{
                                            width: 26,
                                            height: 26,
                                            borderRadius: '50%',
                                            backgroundColor: selectedSpace.color,
                                            marginRight: '16px',
                                        }}
                                    />
                                    <Typography variant="h5" gutterBottom sx={{ margin: 0, color: 'black' }}>
                                        {selectedSpace.name}
                                    </Typography>
                                </Box>

                                {/* Search Icon and Expandable Search Bar */}
                                {isSearchOpen ? (
                                    <TextField
                                        placeholder="Search"
                                        size="small"
                                        autoFocus
                                        onBlur={handleBlur} // Close search bar when it loses focus
                                        sx={{
                                            width: '250px',
                                            transition: 'width 0.3s ease-in-out',
                                            backgroundColor: '#f0f0f0',
                                            borderRadius: '15px',
                                            '& .MuiOutlinedInput-root': {
                                                borderRadius: '15px',
                                                '& fieldset': {
                                                    border: 'none', // Remove border
                                                },
                                            },
                                        }}
                                    />

                                ) : (
                                    <IconButton onClick={handleSearchClick} aria-label="Search">
                                        <SearchIcon />
                                    </IconButton>
                                )}
                            </Box>


                        )}

                        {/* Messages Content Section */}
                        {selectedSpace && (
                            <Box
                                sx={{
                                    width: '100%',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    marginTop: 'auto',
                                    textAlign: 'left',
                                    alignItems: 'flex-start',
                                    overflowY: 'auto', // Allow scrolling within this section
                                    maxHeight: 'calc(100% - 50px)', // Adjust based on heading section height
                                }}
                            >
                                {dummyMessages.map(message => {
                                    // Determine the message box color based on content type
                                    let messageBoxColor;
                                    switch (message.type) {
                                        case 'check-in':
                                            messageBoxColor = '#cce5ff'; // Light blue for check-in
                                            break;
                                        case 'check-out':
                                            messageBoxColor = '#d4edda'; // Light green for check-out
                                            break;
                                        case 'task-check-in-update':
                                            messageBoxColor = '#fff3cd'; // Light yellow for task check-in update
                                            break;
                                        case 'task-check-out-update':
                                            messageBoxColor = '#f8d7da'; // Light red for task check-out update
                                            break;
                                        default:
                                            messageBoxColor = '#f0f0f0'; // Default color
                                    }

                                    // Determine the avatar background color based on user ID
                                    const avatarColor = getAvatarColor(message.userId); // Get avatar color based on userId

                                    return (
                                        <Box
                                            key={message.id}
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'column', // Stack the name, time, and message vertically
                                                marginBottom: '10px', // Spacing between messages
                                                width: '100%', // Ensure the message box takes full width
                                            }}
                                        >
                                            {/* Avatar with Fallback to Initials */}
                                            <Box sx={{ display: 'flex', alignItems: 'flex-start', marginBottom: '5px' }}>
                                                <Avatar
                                                    src={message.avatarUrl} // Uses image if available
                                                    sx={{
                                                        width: 40,
                                                        height: 40,
                                                        marginRight: '10px',
                                                        backgroundColor: avatarColor, // Use determined avatar color
                                                        marginTop: '25px', // Pushes the avatar down slightly
                                                    }}
                                                >
                                                    {message.senderName ? message.senderName.charAt(0) : "?"} {/* Fallback to initials */}
                                                </Avatar>

                                                {/* Message content within a styled box */}
                                                <Box sx={{ width: 'auto' }}> {/* Make the box take full width */}
                                                    {/* Name, Time, and Type above the message content */}
                                                    <Box sx={{ display: 'flex', marginBottom: '5px', width: '100%', fontSize: '12px' }}>
                                                        {message.senderName || "Unknown Sender"} - {message.time || "Just now"} -
                                                        <span style={{ fontStyle: 'italic', marginLeft: '5px' }}>
                                                            {message.type.replace(/-/g, ' ').replace(/\b\w/g, char => char.toUpperCase()) || "N/A"}
                                                        </span>
                                                    </Box>

                                                    {/* Conditionally render messages for check-in and check-out types */}
                                                    {(message.type === 'check-in' || message.type === 'check-out') ? (
                                                        <Box
                                                            sx={{
                                                                backgroundColor: messageBoxColor, // Use determined message box color
                                                                padding: '10px 15px',
                                                                borderRadius: '10px',
                                                                maxWidth: '80%', // Adjusts width to avoid stretching too far
                                                                textAlign: 'left', // Ensure content is left-aligned
                                                                width: '100%', // Ensure it takes full width of the grid cell
                                                            }}
                                                        >
                                                            <Typography variant="body1" sx={{ textAlign: 'left' }}>
                                                                <span style={{ fontWeight: 'bold' }}>{message.senderName}</span> has checked {message.type === 'check-in' ? 'in' : 'out'} at <span style={{ fontWeight: 'bold' }}>{message.time || "Just now"}</span> .
                                                            </Typography>
                                                        </Box>
                                                    ) : (
                                                        <Box
                                                            sx={{
                                                                backgroundColor: messageBoxColor, // Use determined message box color
                                                                padding: '10px 15px',
                                                                borderRadius: '10px',
                                                                maxWidth: '80%', // Adjusts width to avoid stretching too far
                                                                textAlign: 'left', // Ensure content is left-aligned
                                                                maxHeight: '150px', // Set a maximum height for the message box
                                                                overflowY: 'auto', // Allow vertical scrolling if content exceeds max height
                                                                width: '100%', // Ensure it takes full width of the grid cell
                                                            }}
                                                        >
                                                            <Typography variant="body1" sx={{ textAlign: 'left', overflowWrap: 'break-word' }}>
                                                                {message.content}
                                                            </Typography>
                                                        </Box>
                                                    )}
                                                </Box>
                                            </Box>
                                        </Box>
                                    );
                                })}

                            </Box>
                        )}
                    </Item>
                </Grid>

            </Grid>
        </Box>
    );
}

export default Chat;