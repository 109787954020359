import React, { useEffect, useState } from 'react';
import { Box, Grid, Typography, TextField, Button } from '@mui/material';
import SentimentSatisfiedAltOutlinedIcon from '@mui/icons-material/SentimentSatisfiedAltOutlined';
import SentimentSatisfiedIcon from '@mui/icons-material/SentimentSatisfied';
import SentimentNeutralOutlinedIcon from '@mui/icons-material/SentimentNeutralOutlined';
import SentimentNeutralIcon from '@mui/icons-material/SentimentNeutral';
import SentimentDissatisfiedOutlinedIcon from '@mui/icons-material/SentimentDissatisfiedOutlined';
import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied';
import SickOutlinedIcon from '@mui/icons-material/SickOutlined';
import SickIcon from '@mui/icons-material/Sick';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Cookies from 'js-cookie';
import { backendURL } from "../../api/axios.js";

function OnBoardingCheckIn({ onCheckIn }) {
    const [selectedIcon, setSelectedIcon] = useState(null);
    // const [mood, setMood] = useState("");
    const [moodDescription, setMoodDescription] = useState('');
    const [tasks, setTasks] = useState([]);

    const handleIconClick = (icon) => {
        setSelectedIcon(icon);
    };

    // const handleMoodChange = (e) => setMood(e.target.value);
    // const handleMoodDescriptionChange = (e) => setMoodDescription(e.target.value);

    // const submitMood = () => {
    //     onCheckIn(mood, moodDescription);  // Send mood and description to Dashboard1
    // };

    // Function to get the lighter background color
    const getBackgroundColor = (color) => {
        switch (color) {
            case '#81D673':
                return '#E6F4E6'; // Light green
            case '#6169AD':
                return '#EBECF7'; // Light blue/purple
            case '#E3A357':
                return '#FBEDE3'; // Light orange
            case 'red':
                return '#FDE6E6'; // Light red
            default:
                return 'lightgray';
        }
    };

    // Function to get the darker icon color
    const getIconColor = (color) => {
        switch (color) {
            case '#81D673':
                return '#4F8C40'; // Darker green
            case '#6169AD':
                return '#3D428A'; // Darker blue
            case '#E3A357':
                return '#B97429'; // Darker orange
            case 'red':
                return '#B22222'; // Darker red
            default:
                return 'gray';
        }
    };

    const handleCheckIn = async () => {
        const token = Cookies.get('token'); // Retrieve the token from cookies
        if (!selectedIcon || !moodDescription) {
            alert("Please select a mood and add a description.");
            return;
        }
        console.log("token fetched from cookies", token)
        const moodData = {
            mood: selectedIcon,
            moodDescription: moodDescription,
        };

        try {
            const response = await fetch(`${backendURL}/userSession/checkin`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`, // Add token to header
                },
                body: JSON.stringify(moodData),
            });

            if (response.ok) {
                onCheckIn(selectedIcon, moodDescription);
                // After a successful check-in, log the activity
                const { userId, sessionId } = await response.json();
                const logData = {
                    userId,
                    sessionId,
                    type: 'check-in',
                    mood: selectedIcon,
                    moodDescription: moodDescription,
                };

                // Send the log data to the backend log endpoint
                const logResponse = await fetch(`${backendURL}/userActivity/log`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    },
                    body: JSON.stringify(logData),
                });
                if (!logResponse.ok) {
                    console.error("Failed to log check-in activity in Google Sheets.");
                }
            } else {
                alert("Failed to checkIn.");
            }
        } catch (error) {
            console.error("Error submitting mood:", error);
        }
    };

    useEffect(() => {
        const fetchTasks = async () => {
            try {
                const token = Cookies.get('token'); // Replace 'accessToken' with the actual cookie name
                const response = await fetch(`${backendURL}/cards/tasks/current`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    },
                });
                const data = await response.json();
                console.log("data from tasks", data)
                setTasks(data || []);
            } catch (error) {
                console.error('Error fetching tasks:', error);
                setTasks([]);
            }
        };
        fetchTasks();
    }, []);

    const iconStyle = (icon) => {
        const baseColor =
            icon === 'happy'
                ? '#81D673'
                : icon === 'neutral'
                    ? '#6169AD'
                    : icon === 'dissatisfied'
                        ? '#E3A357'
                        : icon === 'sick'
                            ? 'red'
                            : 'gray';

        return {
            fontSize: '40px',
            cursor: 'pointer',
            borderRadius: '20%',
            padding: '1px',
            border: selectedIcon === icon ? `2px solid ${getIconColor(baseColor)}` : 'none',
            backgroundColor: selectedIcon === icon ? getBackgroundColor(baseColor) : 'transparent',
            color: selectedIcon === icon ? getIconColor(baseColor) : baseColor,
        };
    };

    const getColor = (tag) => {
        switch (tag) {
            case "completed":
                return "#EFEFEF"; // Light blue for started tasks
            case "inprogress":
                return "#EFEFEF"; // Light red for time exceeded tasks
            case "todo":
                return "#EFEFEF"; // Light gray for pending tasks
        }
    };
    const TaskItem = ({ task }) => {
        return (
            <Box
                sx={{
                    backgroundColor: getColor(task.tag),
                    borderRadius: '15px',
                    padding: '5px',
                    paddingLeft: '20px',
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    position: 'relative',
                }}
            >
                <Box sx={{ width: '100%', wordWrap: 'break-word', overflowWrap: 'break-word' }}>
                    <Typography
                        variant="body1"
                        sx={{
                            color: '#666',
                            fontSize: '14px',
                            fontFamily: 'Raleway',
                            textAlign: 'left',
                            wordWrap: 'break-word',  // Ensures long words are wrapped
                            overflowWrap: 'break-word', // Handles word breaks
                            paddingRight: '16px', // Adds padding to the right side
                        }}
                    >
                        {task.title}
                    </Typography>
                    <Typography
                        variant="body2"
                        sx={{
                            fontFamily: 'Raleway',
                            color: '#666',
                            textAlign: 'left',
                            wordWrap: 'break-word',  // Ensures long words are wrapped
                            overflowWrap: 'break-word', // Handles word breaks
                            paddingRight: '16px', // Adds padding to the right side
                        }}
                    >
                        Estimated time: <span style={{ fontFamily: 'Raleway', color: 'black', fontWeight: 'bold' }}>{task.expectedHours}</span>
                    </Typography>
                </Box>

                {task.tag === "completed" && (
                    <Box
                        sx={{
                            backgroundColor: 'red', // Red color for 'Time Exceeded'
                            color: '#fff',
                            borderRadius: '0 20px 20px 0', // Rounded only on the right side
                            padding: '5px 10px', // Reduced padding to make the badge narrower
                            position: 'absolute',
                            right: '0', // Align the badge to the right
                            top: '0', // Start from the top of the container
                            bottom: '0', // Stretch to the bottom of the container
                            display: 'flex', // Flexbox for vertical alignment
                            alignItems: 'center', // Center the text vertically
                            fontSize: '12px',
                            fontWeight: 'bold',
                            width: 'auto', // Remove fixed width
                            textAlign: 'center', // Center align the text horizontally
                            border: 'none', // Remove borders
                            boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)', // Optional: subtle shadow for depth
                            zIndex: 1, // Ensure it stays within parent boundaries
                        }}
                    />
                )}
                {task.tag === "todo" && (
                    <Box
                        sx={{
                            backgroundColor: 'green', // Red color for 'Time Exceeded'
                            color: '#fff',
                            borderRadius: '0 20px 20px 0', // Rounded only on the right side
                            padding: '5px 10px', // Reduced padding to make the badge narrower
                            position: 'absolute',
                            right: '0', // Align the badge to the right
                            top: '0', // Start from the top of the container
                            bottom: '0', // Stretch to the bottom of the container
                            display: 'flex', // Flexbox for vertical alignment
                            alignItems: 'center', // Center the text vertically
                            fontSize: '12px',
                            fontWeight: 'bold',
                            width: 'auto', // Remove fixed width
                            textAlign: 'center', // Center align the text horizontally
                            border: 'none', // Remove borders
                            boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)', // Optional: subtle shadow for depth
                            zIndex: 1, // Ensure it stays within parent boundaries
                        }}
                    />
                )}
                {task.tag === "inprogress" && (
                    <Box
                        sx={{
                            backgroundColor: '#E3A357', // Red color for 'Time Exceeded'
                            color: '#fff',
                            borderRadius: '0 20px 20px 0', // Rounded only on the right side
                            padding: '5px 10px', // Reduced padding to make the badge narrower
                            position: 'absolute',
                            right: '0', // Align the badge to the right
                            top: '0', // Start from the top of the container
                            bottom: '0', // Stretch to the bottom of the container
                            display: 'flex', // Flexbox for vertical alignment
                            alignItems: 'center', // Center the text vertically
                            fontSize: '12px',
                            fontWeight: 'bold',
                            width: 'auto', // Remove fixed width
                            textAlign: 'center', // Center align the text horizontally
                            border: 'none', // Remove borders
                            boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)', // Optional: subtle shadow for depth
                            zIndex: 1, // Ensure it stays within parent boundaries
                        }}
                    />
                )}
            </Box>
        );
    };
    // const tasks = [
    //     {
    //         title: "The oversized font for Inquisitive commands attention, symbolizing innovation and curiosity, which aligns with the brand's name.",
    //         estimatedTime: "2 hours",
    //         status: "started",
    //     },
    //     {
    //         title: "Get update on keychains",
    //         estimatedTime: "30 minutes",
    //         status: "timeExceeded",
    //     },
    //     {
    //         title: "Add videos to Mamaar store",
    //         estimatedTime: "1 hour",
    //         status: "pending",
    //     },
    //     {
    //         title: "Add product descriptions to store",
    //         estimatedTime: "1 hour",
    //         status: "pending",
    //     },
    //     {
    //         title: "Add product descriptions to store",
    //         estimatedTime: "1 hour",
    //         status: "pending",
    //     },
    // ];
    return (
        <Box
            sx={{
                margin: '0 20px', // Adjust margins here (20px is an example; modify as needed)
            }}
        >
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', marginBottom: '20px' }}>
                <Typography sx={{ fontWeight: '550', fontSize: '15px', fontFamily: 'Raleway', marginRight: '30px', color: '#000000' }}>
                    How are you feeling today?
                </Typography>
                <Box sx={{ display: 'flex', gap: '10px' }}>
                    {selectedIcon === 'happy' ? (
                        <SentimentSatisfiedIcon sx={iconStyle('happy')} onClick={() => handleIconClick('happy')} />
                    ) : (
                        <SentimentSatisfiedAltOutlinedIcon sx={iconStyle('happy')} onClick={() => handleIconClick('happy')} />
                    )}

                    {selectedIcon === 'neutral' ? (
                        <SentimentNeutralIcon sx={iconStyle('neutral')} onClick={() => handleIconClick('neutral')} />
                    ) : (
                        <SentimentNeutralOutlinedIcon sx={iconStyle('neutral')} onClick={() => handleIconClick('neutral')} />
                    )}

                    {selectedIcon === 'dissatisfied' ? (
                        <SentimentDissatisfiedIcon sx={iconStyle('dissatisfied')} onClick={() => handleIconClick('dissatisfied')} />
                    ) : (
                        <SentimentDissatisfiedOutlinedIcon sx={iconStyle('dissatisfied')} onClick={() => handleIconClick('dissatisfied')} />
                    )}

                    {selectedIcon === 'sick' ? (
                        <SickIcon sx={iconStyle('sick')} onClick={() => handleIconClick('sick')} />
                    ) : (
                        <SickOutlinedIcon sx={iconStyle('sick')} onClick={() => handleIconClick('sick')} />
                    )}
                </Box>
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                    marginBottom: '20px',
                }}
            >
                <Typography
                    sx={{
                        fontWeight: '550',
                        fontSize: '15px',
                        fontFamily: 'Raleway',
                        marginRight: '40px',
                        color: '#000000',
                    }}
                >
                    Why do you feel like this?
                </Typography>
                <TextField
                    multiline
                    minRows={1}
                    maxRows={2}
                    sx={{
                        width: '70%',
                        height: '48px',
                        '& .MuiOutlinedInput-root': {
                            borderRadius: '15px',
                            padding: '0px',
                            '& fieldset': {
                                borderColor: 'rgba(0, 0, 0, 0.12)',
                            },
                            '&:hover fieldset': {
                                borderColor: 'rgba(0, 0, 0, 0.2)',
                            },
                            '&.Mui-focused fieldset': {
                                borderColor: 'rgba(0, 0, 0, 0.5)',
                            },
                        },
                        '& .MuiOutlinedInput-input': {
                            padding: '4px 12px',
                            lineHeight: '1.2',
                            overflow: 'hidden',
                        },
                        '& textarea': {
                            border: 'none',
                            outline: 'none',
                            boxShadow: 'none',
                            padding: '4px',
                            minHeight: '25px',
                        },
                    }}
                    value={moodDescription}
                    onChange={(e) => setMoodDescription(e.target.value)}
                />
            </Box>

            <Box sx={{ borderBottom: '1px solid #ccc', width: '100%', marginBottom: '20px' }} />
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', marginBottom: '2px' }}>
                <Typography sx={{ fontWeight: '550', fontSize: '15px', fontFamily: 'Raleway', marginRight: '30px', color: '#000000' }}>
                    Let’s see what you’ve got lined up for today
                </Typography>
            </Box>
            <Box sx={{ width: '90%', height: '30vh', overflow: 'auto', margin: '20px auto' }}>
                <Grid container rowSpacing={2} columnSpacing={3}>
                    {tasks.map((task, index) => (
                        <Grid item xs={6} key={index}>
                            <TaskItem task={task} style={{ margin: 0, padding: 0 }} />
                        </Grid>
                    ))}
                </Grid>
            </Box>
            <button
                style={{
                    
                    bottom: '10px', // Position it 10px from the bottom
                    width: '100%', // Full width of the box
                    padding: '10px',
                    fontFamily: 'Raleway',
                    fontSize: '16px',
                    textAlign: 'center',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
                onClick={handleCheckIn}
                variant="contained"
            >
                Check In
            </button>
        </Box>


    );
};

export default OnBoardingCheckIn;
