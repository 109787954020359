import { drag, zoom } from "d3";

export const bindData = (root, data, tag) =>
  root
    .append("g")
    .selectAll(tag)
    .data(data)
    .enter()
    .append(tag);

export const d3Connections = (svg, connections) =>
  bindData(svg, connections, "path")
  .attr("class", "mindmap-connection")
  .attr("cursor", "pointer");


export const d3Nodes = (svg, nodes) => {
  const selection = svg
    .append("g")
    .selectAll("foreignObject")
    .data(nodes)
    .enter();

  const d3nodes = selection
    .append("foreignObject")
    .attr("class", "mindmap-node")
    .attr("width", node => node.width + 4)
    .attr("height", node => node.height)
    .html(node => node.html);

  return {
    nodes: d3nodes,
  };
};

export const onTick = (conns, nodes) => {
  const d = (conn) =>
    [
      "M",
      conn.source.x,
      ",",
      conn.source.y,
      " ",
      "C",
      (conn.source.x + conn.target.x) / 2,
      ",",
      conn.source.y,
      " ",
      (conn.source.x + conn.target.x) / 2,
      ",",
      conn.target.y,
      " ",
      conn.target.x,
      ",",
      conn.target.y,
    ].join("");

  conns.attr("d", d);

  nodes
    .attr("x", (node) => node.x - node.width / 2)
    .attr("y", (node) => node.y - node.height / 2);
};

export const d3Drag = (simulation, svg, nodes) => {
    const dragStart = (event, d) => {
      if (!event.active) {simulation.alphaTarget(0.3).restart();}
      d.fx = d.x;
      d.fy = d.y;
    };
  
    const dragged = (event, d) => {
      d.fx = event.x;
      d.fy = event.y;
    };
  
    const dragEnd = (event, d) => {
      if (!event.active) {simulation.alphaTarget(0)};
    };
  
    return drag()
      .on("start", (event, d) => dragStart(event, d))
      .on("drag", (event, d) => dragged(event, d))
      .on("end", (event, d) => dragEnd(event, d));
  };
  

export const d3PanZoom = (el) =>
    zoom()
      .scaleExtent([0.3, 5])
      .on("zoom", (event) => el.selectAll("svg > g").attr("transform", event.transform));
  
  export const d3NodeClick = (event, d, i) => {
    console.log("In the d3 Node Click function: ",d);
    event.stopPropagation();
    let target = event.target;
  
    switch (target.className) {
      case "fas fa-plus":
        return "add";
      case "fa fa-regular fa-pen-to-square":
        return "edit";
      case "fa fa-regular fa-trash-can":
        return "remove";
      case "node-text":
        return "click";
      case "fa-solid fa-neuter":
        return "link";
      case "link":
        return "link";
      default:
        break;

    }
  };
  
