// SemanticSearchPage.js
import React, { useState } from 'react';
import './Search.css';
import '../Chat/Chat.css';
import SearchBar from './SemanticSearchBar';
import SelectionBar from '../SelectionBar';


const SemanticSearch = () => {
  const [selectedProject, setSelectedProject] = useState('');
  const [selectedDocument, setSelectedDocument] = useState('');

  const [searchResults, setSearchResults] = useState([]); // New state for search results

  const handleSelectionChange = async (project, document,projectID) => {
    setSelectedProject(project);
    setSelectedDocument(document);
};

return (
  <div className='main-page-container'>
    <div className="projects-container">
    <SelectionBar onSelectionChange={handleSelectionChange} />
    </div>
    <SearchBar
        selectedDocument={selectedDocument}
        selectedProjectName={selectedProject}
        setSearchResults={setSearchResults} // Pass setSearchResults to update search results
      />
      <div className="search-results">
      <h3>Search results</h3>
      <div className='results-container'>
        {searchResults.map((result, index) => (
          <div key={index} className="search-result">
            <div className="search-content">{result.query_result}</div>
            <div className="search-metadata">
              <span>Page: {result.pages}</span>
              <span>, {result.document_name}</span>
              {/* <span>Score: {result.score}</span> */}
              </div>
          {/* Add a horizontal line or border here to separate results */}
          <hr className="result-separator" />
        </div>
        ))}
      </div>
      </div>
    </div>

);
};

export default SemanticSearch;