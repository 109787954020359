import React, { useEffect ,useState } from 'react';
import { Grid, Box,Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useNavigate } from 'react-router-dom';
import { updatedStatus, fetchSameOrgUsers, backendURL } from '../../api/axios.js'; // Import API functions
import Cookies from 'js-cookie';

const StyledBox = styled(Box)(({ theme }) => ({
    backgroundColor: '#fff',
    borderRadius: '35px',
    padding: theme.spacing(2),
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)', // Optional: Add shadow for aesthetics
    height: '80vh', // Adjust height to 80% of the viewport height
    display: 'flex', // Enable flexbox layout
    flexDirection: 'column', // Stack children vertically
    justifyContent: 'space-between', // Space between children, pushing the button to the bottom
   
}));
const getColor = (tag) => {
    switch (tag) {
        case "inprogress":
            return "#EFEFEF"; // Light blue for started tasks
        case "timeExceeded":
            return "#EFEFEF"; // Light red for time exceeded tasks
        case "todo":
            return "#EFEFEF"; // Light gray for pending tasks
    }
};
const TaskItem = ({ task }) => {
    return (
        <Box
            sx={{
                backgroundColor: getColor(task.tag),
                borderRadius: '15px',
                padding: '5px',
                paddingLeft: '20px',
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
                position: 'relative',
            }}
        >
            <Box sx={{ width: '100%', wordWrap: 'break-word', overflowWrap: 'break-word' }}>
                <Typography
                    variant="body1"
                    sx={{
                        color: '#666',
                        fontSize: '14px',
                        fontFamily: 'Raleway',
                        textAlign: 'left',
                        wordWrap: 'break-word',  // Ensures long words are wrapped
                        overflowWrap: 'break-word', // Handles word breaks
                        paddingRight: '16px', // Adds padding to the right side
                    }}
                >
                    {task.title}
                </Typography>
                <Typography
                    variant="body2"
                    sx={{
                        fontFamily: 'Raleway',
                        color: '#666',
                        textAlign: 'left',
                        wordWrap: 'break-word',  // Ensures long words are wrapped
                        overflowWrap: 'break-word', // Handles word breaks
                        paddingRight: '16px', // Adds padding to the right side
                    }}
                >
                    Estimated time: <span style={{ fontFamily: 'Raleway', color: 'black', fontWeight: 'bold' }}>{task.expectedHours}</span>
                </Typography>
            </Box>

            {task.tag === "timeExceeded" && (
                <Box
                    sx={{
                        backgroundColor: 'red', // Red color for 'Time Exceeded'
                        color: '#fff',
                        borderRadius: '0 20px 20px 0', // Rounded only on the right side
                        padding: '5px 10px', // Reduced padding to make the badge narrower
                        position: 'absolute',
                        right: '0', // Align the badge to the right
                        top: '0', // Start from the top of the container
                        bottom: '0', // Stretch to the bottom of the container
                        display: 'flex', // Flexbox for vertical alignment
                        alignItems: 'center', // Center the text vertically
                        fontSize: '12px',
                        fontWeight: 'bold',
                        width: 'auto', // Remove fixed width
                        textAlign: 'center', // Center align the text horizontally
                        border: 'none', // Remove borders
                        boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)', // Optional: subtle shadow for depth
                        zIndex: 1, // Ensure it stays within parent boundaries
                    }}
                />
            )}
            {task.tag === "todo" && (
                <Box
                    sx={{
                        backgroundColor: 'green', // Red color for 'Time Exceeded'
                        color: '#fff',
                        borderRadius: '0 20px 20px 0', // Rounded only on the right side
                        padding: '5px 10px', // Reduced padding to make the badge narrower
                        position: 'absolute',
                        right: '0', // Align the badge to the right
                        top: '0', // Start from the top of the container
                        bottom: '0', // Stretch to the bottom of the container
                        display: 'flex', // Flexbox for vertical alignment
                        alignItems: 'center', // Center the text vertically
                        fontSize: '12px',
                        fontWeight: 'bold',
                        width: 'auto', // Remove fixed width
                        textAlign: 'center', // Center align the text horizontally
                        border: 'none', // Remove borders
                        boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)', // Optional: subtle shadow for depth
                        zIndex: 1, // Ensure it stays within parent boundaries
                    }}
                />
            )}
            {task.tag === "pending" && (
                <Box
                    sx={{
                        backgroundColor: '#E3A357', // Red color for 'Time Exceeded'
                        color: '#fff',
                        borderRadius: '0 20px 20px 0', // Rounded only on the right side
                        padding: '5px 10px', // Reduced padding to make the badge narrower
                        position: 'absolute',
                        right: '0', // Align the badge to the right
                        top: '0', // Start from the top of the container
                        bottom: '0', // Stretch to the bottom of the container
                        display: 'flex', // Flexbox for vertical alignment
                        alignItems: 'center', // Center the text vertically
                        fontSize: '12px',
                        fontWeight: 'bold',
                        width: 'auto', // Remove fixed width
                        textAlign: 'center', // Center align the text horizontally
                        border: 'none', // Remove borders
                        boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)', // Optional: subtle shadow for depth
                        zIndex: 1, // Ensure it stays within parent boundaries
                    }}
                />
            )}
        </Box>
    );
};
// const tasks = [
//     {
//         title: "The oversized font for Inquisitive commands attention, symbolizing innovation and curiosity, which aligns with the brand's name.",
//         estimatedTime: "2 hours",
//         status: "started",
//     },
//     {
//         title: "Get update on keychains",
//         estimatedTime: "30 minutes",
//         status: "timeExceeded",
//     },
//     {
//         title: "Add videos to Mamaar store",
//         estimatedTime: "1 hour",
//         status: "pending",
//     },
//     {
//         title: "Add product descriptions to store",
//         estimatedTime: "1 hour",
//         status: "pending",
//     },
//     {
//         title: "Add product descriptions to store",
//         estimatedTime: "1 hour",
//         status: "pending",
//     },
// ];
const SimpleQuillEditor = () => {
    const [value, setValue] = useState('');
    const userID = localStorage.getItem('user_id'); // Get user ID from local storage
    const [isCheckedIn, setIsCheckedIn] = useState(false); // Default is not checked in
    const navigate = useNavigate(); // Initialize the navigate function

    const [tasks, setTasks] = useState([]);

    useEffect(() => {
        const fetchTasks = async () => {
            try {
                const token = Cookies.get('token'); // Replace 'accessToken' with the actual cookie name
                const response = await fetch(`${backendURL}/cards/tasks/current`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    },
                });
                const data = await response.json();
                console.log("data from tasks", data)
                setTasks(data || []);
            } catch (error) {
                console.error('Error fetching tasks:', error);
                setTasks([]);
            }
        };
        fetchTasks();
    }, []);

    const handleCheckout  = async () => {
        const token = Cookies.get('token');
        const checkoutupdate = { taskUpdateText: value };
        try {
            const response = await fetch(`${backendURL}/userSession/checkout`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`, // Add token to header
                },
                body: JSON.stringify(checkoutupdate),
            });

            if (response.ok) {
                console.log("Checked out successfully!")
                await updatedStatus(userID, "Checked Out");
                // Update local state to reflect "Checked Out"
                setIsCheckedIn(false);
                // Remove the check-in status from local storage
                localStorage.removeItem('isCheckedIn');

                // Navigate to the home page after successful checkout
                navigate('/home');
                const { userId, sessionId } = await response.json(); // Assume backend returns sessionId of the check-in

                const logData = {
                    userId,
                    sessionId: sessionId,
                    taskUpdateText: value, // Add the task update text
                };

                // Now call the Google Sheets service to append the task update
                const sheetResponse = await fetch(`${backendURL}/userActivity/log`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    },
                    body: JSON.stringify(logData),
                });

                if (!sheetResponse.ok) {
                    console.error("Failed to update task in Google Sheets.");
                }

            } else {
                alert("Failed to checkOut.");
            }
        } catch (error) {
            console.error("Error checking out:", error);
        }
    };

    return (
        <Grid container justifyContent="center" alignItems="center" style={{ minHeight: '90vh' }}>
            <Grid item xs={10} sm={8} md={8}>
                <StyledBox>
                    <div>
                        <Box
                            sx={{
                                width: '100%',
                                fontFamily: 'Montserrat',
                                textAlign: 'center',
                                fontSize: '32px',
                                fontWeight: 'bold',
                                borderBottom: '1px solid #ccc',
                                marginBottom: '2px', // Set margin bottom to 2px
                            }}
                        >
                            Check Out
                        </Box>
                        <ReactQuill
                            theme="snow"
                            value={value}
                            onChange={setValue}
                            style={{
                                height: '150px',
                                marginTop: '10px',  // Adjust this value to move it higher or lower
                                marginBottom:'60px'
                            }}
                        />
                        <Box sx={{ width: '90%', height: '35vh', overflow: 'auto', margin: '0 auto' }}>
                            <Grid container rowSpacing={2} columnSpacing={3}>
                                {tasks.map((task, index) => (
                                    <Grid item xs={6} key={index}>
                                        <TaskItem task={task} style={{ margin: 0, padding: 0 }} />
                                    </Grid>
                                ))}
                            </Grid>
                        </Box>
                    </div>


                    {/* Checkout button at the bottom */}
                    <button
                        onClick={handleCheckout}
                        variant="contained"
                        style={{
                            width: '100%', // Full width for the button
                            padding: '10px',
                            fontFamily: 'Raleway',
                            fontSize: '16px',
                            textAlign: 'center', // Center the text within the button
                            margin: '20px auto 0', // Center the button and add margin on top
                            display: 'flex', // Change to flexbox layout
                            justifyContent: 'center', // Center the text horizontally
                            alignItems: 'center', // Center the text vertically
                            border: 'none', // Remove default border
                            backgroundColor: '#1b7690', // Set background color for visibility
                            color: '#fff', // Set text color
                            cursor: 'pointer', // Change cursor on hover
                            borderRadius: '15px', // Add border radius for aesthetics
                        }}
                    >
                        Checkout
                    </button>
                </StyledBox>
            </Grid>
        </Grid>
    );
};

export default SimpleQuillEditor;
