// ProjectsPage.js
import { Link } from 'react-router-dom';
import Modal from './Modal'; // Import the Modal component
import React, { useState, useEffect } from 'react';
import FileUploadComponent from '../FileUpload/FileUpload'; // Import the FileUploadComponent
import './Projects.css';
import SearchIcon from '../../Icons/Search_5';
import useAuth from '../../hooks/useAuth';
import { backend } from '../../api/axios';

function ProjectsPage() {
  const {user} = useAuth();
  console.log("PROJECTS: ",user);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [filteredProjects,setFilteredProjects] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');

  const handleNewProjectClick = () => {
    setIsModalOpen(true);
  };

  const fetchProjects = () => {
    backend.get(`user/${user}/projects`)
    .then(response => {
      const projectNames = response.data.map((project) => project.projectName);
    
      // Filter projects based on the search term
      const filteredProjects = projectNames.filter((projectName) =>
        projectName.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredProjects(filteredProjects);
    })
    .catch(error => {
      console.error('Error fetching user projects: ',error);
    });
  };

  useEffect(() => {
    fetchProjects();
  }, []); // This will run when the component mounts

  const closeModal = () => {
    setIsModalOpen(false);
    fetchProjects(); // This will refresh the projects after creating a new one
  };

   

  return (
    
    <div className="main-page-container">
      <div className='top-bar-container'>
        <SearchIcon></SearchIcon>
        <input
          className="search-input"
          type="text"
          placeholder="Search projects..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <button className='button-right-aligned' onClick={handleNewProjectClick}>New Project</button>
      </div>
      <table className="projects-table">
        <thead>
          <tr>
            <th className="table-header">Project name</th>
            <th className="table-header">Created</th>
            <th className='table-header'>Description</th>
          </tr>
        </thead>
        <tbody>
          {filteredProjects.map((projectName) => (
            <tr key={projectName} className="table-row">
              <td className="project-name">
                <Link to={`/projects/${projectName}`}>{projectName}</Link>
              </td>
              <td className="created-on">Created On Placeholder</td>
              <td>Add an editable field here</td>
            </tr>
          ))}
        </tbody>
      </table>
      {isModalOpen && (
        <Modal onClose={closeModal}>
          <FileUploadComponent />
        </Modal>
      )}
    </div>
  );
}

export default ProjectsPage;
