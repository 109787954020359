import React from "react";

function ActivityIcon( prop: any) {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15 7.75C14.5858 7.75 14.25 8.08579 14.25 8.5C14.25 8.91421 14.5858 9.25 15 9.25V7.75ZM19 9.25C19.4142 9.25 19.75 8.91421 19.75 8.5C19.75 8.08579 19.4142 7.75 19 7.75V9.25ZM19.75 8.5C19.75 8.08579 19.4142 7.75 19 7.75C18.5858 7.75 18.25 8.08579 18.25 8.5H19.75ZM18.25 12.5C18.25 12.9142 18.5858 13.25 19 13.25C19.4142 13.25 19.75 12.9142 19.75 12.5H18.25ZM19.5303 9.03033C19.8232 8.73744 19.8232 8.26256 19.5303 7.96967C19.2374 7.67678 18.7626 7.67678 18.4697 7.96967L19.5303 9.03033ZM12 15.5L11.4697 16.0303C11.7626 16.3232 12.2374 16.3232 12.5303 16.0303L12 15.5ZM9 12.5L9.53033 11.9697C9.23744 11.6768 8.76256 11.6768 8.46967 11.9697L9 12.5ZM4.46967 15.9697C4.17678 16.2626 4.17678 16.7374 4.46967 17.0303C4.76256 17.3232 5.23744 17.3232 5.53033 17.0303L4.46967 15.9697ZM15 9.25H19V7.75H15V9.25ZM18.25 8.5V12.5H19.75V8.5H18.25ZM18.4697 7.96967L11.4697 14.9697L12.5303 16.0303L19.5303 9.03033L18.4697 7.96967ZM12.5303 14.9697L9.53033 11.9697L8.46967 13.0303L11.4697 16.0303L12.5303 14.9697ZM8.46967 11.9697L4.46967 15.9697L5.53033 17.0303L9.53033 13.0303L8.46967 11.9697Z" 
            fill="black"/>
        </svg>
    );
}

export default ActivityIcon;