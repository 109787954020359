import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { IconButton } from '@mui/material';
import SendIcon from '../../Icons/Send_2.tsx';
import { Avatar, Menu, MenuItem } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { LinearProgress, Typography } from '@mui/material';
import { updatedStatus, fetchSameOrgUsers } from '../../api/axios.js'; // Import API functions
import DashboardButtons from './DashboardSwitch.jsx'; // Adjust path as per your structure
import OnBoardingCheckIn from './OnBoardingCheckIn.jsx';
import UserAvatar from './UserAvatar';
import rememberALLicon from '../../Icons/rememberALLicon.png'
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import PersonIcon from '@mui/icons-material/Person';
import Tooltip from '@mui/material/Tooltip'; // Import Tooltip from Material-UI
import axios from 'axios';
import Cookies from 'js-cookie';
import { backendURL } from "../../api/axios.js";

const Item = styled(Paper)(({ theme }) => ({
    borderRadius: '35px',
    ...theme.typography.body2,
    color: theme.palette.text.secondary,
}));


const Dashboard1 = (progress) => {
    const [isDashboardEnabled, setIsDashboardEnabled] = useState(false); // Track dashboard state
    const [isCheckedIn, setIsCheckedIn] = useState(false); // Track check-in status
    const [showTransition, setShowTransition] = useState(false); // Control transition on first check-in
    const [userImage, setUserImage] = useState([]); // State for user image
    const userID = localStorage.getItem('user_id'); // Get user ID from local storage
    const navigate = useNavigate();
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const [dismissedReminders, setDismissedReminders] = useState(new Set()); // Track dismissed reminders
    const [isFromInput, setIsFromInput] = useState(false); // State to track if reminder is from input
    const [reminders, setReminders] = useState([]); // Track active reminders

    const [mood, setMood] = useState('');
    const [moodDescription, setMoodDescription] = useState('');
    const [taskUpdateText, setTaskUpdateText] = useState('');
    const [message, setMessage] = useState('');

    const [tasks, setTasks] = useState([]);

    const handleInputChange = (e) => {
        setMessage(e.target.value);  // Update the message with input value
    };
    const handleAddReminder = (newReminder, fromInput = false) => {
        if (newReminder && !reminders.includes(newReminder)) {
            const updatedReminders = [...reminders, newReminder];
            setReminders(updatedReminders); // Update state with new reminder
            console.log('Reminders:', updatedReminders); // Log the updated reminders
            setIsFromInput(fromInput); // Update the source of the reminder
        }
    };

    const handleFormSubmit = (event) => {
        event.preventDefault(); // Prevent the default form submission behavior
        const inputValue = event.target.reminderInput.value; // Accessing the input value by name
        console.log('Input Value:', inputValue); // Log the input value
        if (inputValue) {
            handleAddReminder(inputValue, true); // Call to add reminder from input
            setMessage(''); // Clear the input field after submission
            event.target.reset(); // Reset the form
        }
    };

    // Function to add reminders not from input
    const addExternalReminder = (reminder) => {
        handleAddReminder(reminder, false);
    };


    const handleAvatarClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleAvatarClose = () => {
        setAnchorEl(null);
    };

    const handleonBoarding = () => {
        // Show the popup instead of checking in
        setIsPopupOpen(true);
    };

    const handleButtonClick = (route) => {
        navigate(route); // Navigate on button click
    };

    // Load reminders and dismissed reminders from localStorage on component mount
    useEffect(() => {
        const savedReminders = JSON.parse(localStorage.getItem('reminders')) || [];
        const savedDismissedReminders = new Set(JSON.parse(localStorage.getItem('dismissedReminders')) || []);

        setReminders(savedReminders);
        setDismissedReminders(savedDismissedReminders);

        fetchUserStatus();
    }, [userID]);

    const fetchUserStatus = async () => {
        if (userID) {
            try {
                const data = await fetchSameOrgUsers(userID);
                const user = data.find(item => item._id === userID);

                if (user) {
                    const userStatus = user.status;
                    const userImageUrl = user.profilePicture;

                    setUserImage(userImageUrl);
                    setIsCheckedIn(userStatus === "Checked In");
                    setIsDashboardEnabled(userStatus === "Checked In");

                    const alreadyCheckedIn = localStorage.getItem('isCheckedIn') === 'true';
                    if (alreadyCheckedIn) {
                        setShowTransition(false);
                    } else {
                        setTimeout(() => {
                            setShowTransition(true);
                        }, 100);
                    }

                    checkForReminders();
                }
            } catch (error) {
                console.error('Error fetching user data:', error);
            }
        }
    };


    useEffect(() => {
        const fetchTasks = async () => {
            try {
                const token = Cookies.get('token'); // Replace 'accessToken' with the actual cookie name
                const response = await fetch(`${backendURL}/cards/tasks/current`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    },
                });
                const data = await response.json();
                console.log("data from tasks", data)
                setTasks(data || []);
            } catch (error) {
                console.error('Error fetching tasks:', error);
                setTasks([]);
            }
        };
        fetchTasks();
    }, []);

    // Check for reminders and add new ones if the time matches
    const checkForReminders = () => {
        const currentTime = new Date();
        const currentHour = currentTime.getHours();
        const currentMinute = currentTime.getMinutes();

        if (currentHour === 16 && currentMinute === 0) {
            const newReminder = "It’s 4 pm. Please report your day’s progress! You can type your report below or in the space.";

            // Add the reminder only if it doesn't already exist
            if (!reminders.includes(newReminder)) {
                const updatedReminders = [...reminders, newReminder];
                setReminders(updatedReminders);
                localStorage.setItem('reminders', JSON.stringify(updatedReminders));
            }
        }
    };

    const handleGoToSpace = () => {
        localStorage.removeItem('reminders'); // Clear reminders from local storage
        console.log('Reminders cleared'); // Log the action for debugging
        navigate('/task_checkIn_Update'); // Navigate to the desired route (e.g., "/space")
    };
    // Load reminders from local storage when the component mounts
    useEffect(() => {
        const storedReminders = JSON.parse(localStorage.getItem('reminders')) || [];
        setReminders(storedReminders);
    }, []);

    useEffect(() => {
        // Set interval to check for reminders every minute
        const reminderInterval = setInterval(checkForReminders, 60000);
        return () => clearInterval(reminderInterval);
    }, [reminders]);

    // Handle the check-in process
    const handleCheckIn = async () => {
        try {
            await updatedStatus(userID, "Checked In"); // Update status in the DB
            setIsCheckedIn(true); // Update local state
            setIsPopupOpen(false);
            setIsDashboardEnabled(true); // Enable dashboard after check-in
            localStorage.setItem('isCheckedIn', 'true'); // Persist the check-in status
            setShowTransition(true); // Show the transition on first check-in
        } catch (error) {
            console.error("Error updating status:", error);
        }
    };
    const getColor = (tag) => {
        switch (tag) {
            case "started":
                return "#C3E0E5"; // Light blue for started tasks
            case "timeExceeded":
                return "#F8D7DA"; // Light red for time exceeded tasks
            case "pending":
                return "#EFEFEF"; // Light gray for pending tasks
            default:
                return "#FFFFFF"; // Default color
        }
    };
    const TaskItem = ({ task }) => {
        return (
            <Box
                sx={{
                    backgroundColor: getColor(task.tag),
                    borderRadius: '20px',
                    padding: '15px',
                    margin: '20px 0',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    // Match border color with respective status
                    border: `2px solid ${task.tag === "timeExceeded" ? '#BC4B4B' : // Color for time exceeded
                        task.tag === "started" ? '#81C1D3' : // Color for started
                            '#D9D9D9' // Default color for other statuses
                        }`,
                    position: 'relative',
                }}
            >
                <Box>
                    <Typography
                        variant="body1"
                        sx={{ fontWeight: '500', color: '#333', textAlign: 'left' }} // Align text to the left
                    >
                        {task.title}
                    </Typography>
                    <Typography
                        variant="body2"
                        sx={{ color: '#666', textAlign: 'left' }} // Align text to the left
                    >
                        Estimated time: <span style={{ color: 'black', fontWeight: 'bold' }}>{task.expectedHours}</span>
                    </Typography>
                </Box>
                {/* Status Badge timeExceeded */}
                {task.tag === "timeExceeded" && (
                    <Box
                        sx={{
                            backgroundColor: '#BC4B4B',
                            color: '#fff',
                            borderRadius: '20px',
                            padding: '5px 15px', // Adjusted padding for better width
                            position: 'absolute',
                            top: '0',
                            right: '0',
                            marginRight: '20px', // Right margin
                            transform: 'translateY(-50%)', // Adjust the position
                            fontSize: '12px',
                            fontWeight: 'bold',
                            minWidth: '100px', // Minimum width for the badge
                            textAlign: 'center', // Center align text
                            border: '2px solid #BC4B4B', // Optional: add a border for better visibility
                            boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)', // Optional: add shadow for depth
                        }}
                    >
                        Time exceeded
                    </Box>
                )}
                {/* Status Badge started */}
                {task.tag === "inprogress" && (
                    <Box
                        sx={{
                            backgroundColor: '#81C1D3',
                            color: '#fff',
                            borderRadius: '20px',
                            padding: '5px 15px', // Adjusted padding for better width
                            position: 'absolute',
                            top: '0',
                            right: '0',
                            marginRight: '20px', // Right margin
                            transform: 'translateY(-50%)', // Adjust the position
                            fontSize: '12px',
                            fontWeight: 'bold',
                            minWidth: '100px', // Minimum width for the badge
                            textAlign: 'center', // Center align text
                            border: '2px solid #81C1D3', // Optional: add a border for better visibility
                            boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)', // Optional: add shadow for depth
                        }}
                    >
                        Started
                    </Box>
                )}
                {/* Status Badge started */}
                {task.tag === "todo" && (
                    <Box
                        sx={{
                            backgroundColor: '#81D673', // Red color for 'Time Exceeded'
                            color: '#fff',
                            borderRadius: '0 20px 20px 0', // Rounded only on the right side
                            padding: '5px 10px', // Reduced padding to make the badge narrower
                            position: 'absolute',
                            right: '0', // Align the badge to the right
                            top: '0', // Start from the top of the container
                            bottom: '0', // Stretch to the bottom of the container
                            display: 'flex', // Flexbox for vertical alignment
                            alignItems: 'center', // Center the text vertically
                            fontSize: '12px',
                            fontWeight: 'bold',
                            width: 'auto', // Remove fixed width
                            textAlign: 'center', // Center align the text horizontally
                            border: 'none', // Remove borders
                            boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)', // Optional: subtle shadow for depth
                            zIndex: 1, // Ensure it stays within parent boundaries
                        }}
                    />
                )}
            </Box>
        );
    };
    // const tasks = [
    //     {
    //         title: "Design new Inquisitive UI",
    //         estimatedTime: "2 hours",
    //         status: "started",
    //     },
    //     {
    //         title: "Get update on keychains",
    //         estimatedTime: "30 minutes",
    //         status: "timeExceeded",
    //     },
    //     {
    //         title: "Add videos to Mamaar store",
    //         estimatedTime: "1 hour",
    //         status: "pending",
    //     },
    //     {
    //         title: "Add product descriptions to store",
    //         estimatedTime: "1 hour",
    //         status: "pending",
    //     },
    //     {
    //         title: "Add product descriptions to store",
    //         estimatedTime: "1 hour",
    //         status: "pending",
    //     },
    // ];

    return (
        <Box sx={{ width: '100%', height: '100vh', padding: { xs: 1, sm: 3, md: 5 }, overflow: 'hidden' }}>
            <Box
                sx={{
                    position: 'absolute', // Positioning to allow precise placement
                    top: '5%', // Align to the top
                    right: '1%', // Align to the right
                    display: 'flex', // Enables flexbox for layout
                    alignItems: 'center', // Center align items vertically
                    justifyContent: 'flex-end', // Aligns content to the right
                    padding: '16px', // Optional padding for spacing from the edges
                }}
            >
                <Tooltip
                    title="Task Check-In Update"
                    arrow
                    placement="left" // Position the tooltip to the left of the icon
                    PopperProps={{
                        modifiers: [
                            {
                                name: 'offset',
                                options: {
                                    offset: [-5, 0], // Adjust the offset to move the tooltip left (-5)
                                },
                            },
                        ],
                    }}
                    componentsProps={{
                        tooltip: {
                            sx: {
                                fontSize: '16px', // Adjust this value to increase/decrease font size


                                borderRadius: '4px', // Optional: customize border radius
                                padding: '8px', // Optional: customize padding
                            },
                        },
                    }}
                >
                    <div
                        onClick={handleGoToSpace}
                        style={{ cursor: 'pointer', marginLeft: '10px' }} // Adjust margin as needed
                    >
                        <TextSnippetIcon sx={{ fontSize: '50px', color: '#DBC389' }} />
                    </div>
                </Tooltip>
                <UserAvatar />
            </Box>

            <Grid
                container
                spacing={3}
                sx={{
                    mb: { xs: 1, sm: 2, md: 3 }, // Existing bottom margin
                    marginTop: { xs: '0px', sm: '-30px', md: '-60px' }, // Responsive top margin
                }}
            >
                {/*Today Progress BAr GRID  START*/}
                <Grid
                    item
                    xs={12}
                    md={12}
                    lg={12}
                    sx={{

                        padding: 1, // Optional: Add some padding if needed
                        marginTop: '-20px', // Apply negative margin to push upwards
                    }}
                >
                    {/* Dashboard Buttons Section */}
                    <Box
                        sx={{
                            display: 'flex', // Enables flexbox for the parent container
                            justifyContent: 'space-between', // Creates space between the two boxes
                            width: '100%', // Ensures full width for the parent container
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex', // Enables flexbox for layout
                                alignItems: 'center', // Vertically centers content
                                justifyContent: 'flex-start', // Aligns content to the left
                                width: '50%', // Adjust width to allow both boxes to fit in one row
                            }}
                        >
                            <DashboardButtons onButtonClick={handleButtonClick} />
                        </Box>
                        <Box
                            sx={{
                                display: 'flex', // Enables flexbox for layout
                                alignItems: 'center', // Vertically centers content
                                justifyContent: 'flex-end', // Aligns content to the right
                                width: '50%', // Adjust width to allow both boxes to fit in one row
                            }}
                        >
                            {/* Spacer to give some left space before the icon */}


                        </Box>
                    </Box>

                    <Item
                        sx={{
                            background: '#1B7690', // Background color
                            marginTop: '-12px',
                            height: { xs: '100px', sm: '120px', md: '140px' }, // Responsive heights
                            display: 'flex', // Enables flexbox for layout
                            alignItems: 'center', // Center content vertically
                            justifyContent: 'center', // Center content horizontally
                            boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)', // Subtle shadow effect
                            padding: { xs: '8px', sm: '12px', md: '16px' }, // Responsive padding
                            position: 'relative', // Relative position to maintain its context
                            backdropFilter: !isCheckedIn ? 'blur(80px)' : 'none', // Apply blur effect when not checked in
                            transition: 'backdrop-filter 0.3s ease-in-out', // Smooth transition for the blur effect
                        }}
                    >
                        {/* Check in button displayed above when not checked in */}
                        {!isCheckedIn && (
                            <Box
                                sx={{
                                    position: 'absolute', // Position the button absolutely within the Item
                                    top: '50%', // Center the button vertically
                                    left: '50%', // Center the button horizontally
                                    transform: 'translate(-50%, -50%)', // Centering adjustment
                                    zIndex: 1, // Ensure the button is above other elements
                                }}
                            >
                                <Button
                                    variant="contained"
                                    onClick={handleonBoarding}
                                    sx={{
                                        backgroundColor: '#e8e8e8',
                                        color: '#000',
                                        fontWeight: 'bold',
                                        fontSize: '24px', // Font size
                                        padding: '40px 100px', // Padding for larger button
                                        borderRadius: '35px', // Updated border radius to 35px
                                        border: '2px solid black',
                                        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)',
                                        '&:hover': {
                                            backgroundColor: '#d0d0d0',
                                        },
                                    }}
                                >
                                    Check in to get started
                                </Button>
                            </Box>
                        )}

                        {/* Container for the image and progress bar */}
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center', // Center content horizontally
                                width: '100%',
                                opacity: isCheckedIn ? 1 : 0.3,
                                filter: isCheckedIn ? 'none' : 'blur(8px)',
                                transition: showTransition ? 'opacity 0.5s ease, filter 0.5s ease' : 'none',
                            }}
                        >
                            {/* Icon on the left */}
                            <Box
                                sx={{
                                    width: '100px',
                                    height: '100px',
                                    backgroundColor: '#F7C24C',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    borderRadius: '20px',
                                    marginRight: '32px', // Space between icon and progress bar
                                }}
                            >
                                {userImage ? (
                                    <img
                                        src={userImage}
                                        alt="User"
                                        style={{ width: '45px', height: '45px', borderRadius: '50%' }} // Optional: make it circular
                                    />
                                ) : (
                                    <Avatar
                                        alt="Default User"
                                        sx={{
                                            width: 45, // Size of the Avatar
                                            height: 45,
                                            bgcolor: '#E0E0E0', // Default background color
                                        }}
                                    >
                                        <PersonIcon sx={{ fontSize: 30, color: '#fff' }} /> {/* Show user icon */}
                                    </Avatar>
                                )}
                            </Box>

                            {/* Progress bar and label */}
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    width: '80%',
                                    background: '#fff',
                                    padding: 2,
                                    borderRadius: '35px',
                                }}
                            >
                                <Typography
                                    variant="h6"
                                    sx={{
                                        color: 'black',
                                        marginRight: '8px', // Space between text and progress bar
                                        fontWeight: 'bold',
                                    }}
                                >
                                    Today's Progress
                                </Typography>
                                <LinearProgress
                                    variant="determinate"
                                    value={progress} // Replace with actual progress value
                                    sx={{
                                        height: '16px',
                                        borderRadius: '8px',
                                        backgroundColor: '#E0E0E0',
                                        flexGrow: 1, // Make the progress bar take the remaining space
                                        '& .MuiLinearProgress-bar': {
                                            backgroundColor: '#4CAF50', // Progress bar color
                                        },
                                    }}
                                />
                            </Box>
                        </Box>
                    </Item>



                </Grid>
                {/*Today Progress BAr GRID  End*/}

                {/*Remember All GRID  START*/}
                <Grid
                    item
                    xs={7}
                    sx={{
                        position: 'relative',
                        height: {
                            xs: 'auto',
                            sm: '30vh',
                            md: '40vh',
                            lg: '50vh'
                        },
                        paddingTop: 0,
                    }}
                >
                    <Item
                        sx={{
                            height: '100%',
                            background: '#DBC389',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'flex-start',
                            padding: { xs: 0, sm: 4 },
                            textAlign: 'center',
                            position: 'relative',
                        }}
                    >
                        {/* Heading */}
                        <Box
                            sx={{
                                width: '100%',
                                paddingBottom: '5px',
                                marginTop: '-10px',
                                position: "sticky",
                                top: 0,
                                zIndex: 1,
                                backgroundColor: '#DBC389',
                                height: 'auto',
                            }}
                        >
                            <Typography
                                variant="h6"
                                sx={{
                                    fontFamily: 'Montserrat',
                                    fontWeight: '900',
                                    fontSize: '24px',
                                    color: '#fff',
                                    textAlign: 'center',
                                }}
                            >
                                Remembrall
                            </Typography>
                        </Box>



                        {/* Display the submitted reminders */}
                        <Box
                            sx={{
                                width: '100%',
                                marginTop: '10px',
                                flex: 1,
                                overflowY: 'auto',
                                maxHeight: 'calc(100% - 90px)',
                                paddingBottom: '60px',
                            }}
                        >
                            {/* Check if the user is checked in */}
                            {!isCheckedIn ? (
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        backgroundColor: '#f4f4f4',
                                        borderRadius: '20px',
                                        padding: '7px',
                                        marginBottom: '10px',
                                        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                                        textAlign: 'left',
                                    }}
                                >
                                    <Avatar
                                        src={rememberALLicon}
                                        alt="Pink Brain"
                                        sx={{
                                            marginRight: '10px',
                                        }}
                                    />
                                    <Typography
                                        variant="body2"
                                        sx={{
                                            fontFamily: 'Raleway',
                                            fontSize: '14px',
                                            color: '#000',
                                            wordWrap: 'break-word',
                                            overflowWrap: 'break-word',
                                            textAlign: 'left',
                                        }}
                                    >
                                        Check in to start the day.
                                    </Typography>
                                </Box>
                            ) : (
                                reminders.length === 0 ? (
                                    <Typography
                                        variant="body2"
                                        sx={{
                                            fontFamily: 'Raleway',
                                            fontSize: '14px',
                                            color: '#000',
                                            textAlign: 'center',
                                        }}
                                    >
                                        No reminders yet.
                                    </Typography>
                                ) : (
                                    reminders.map((reminder, index) => (
                                        <Box
                                            key={index}
                                            sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                backgroundColor: '#f4f4f4',
                                                borderRadius: '25px',
                                                padding: '7px',
                                                marginBottom: '10px',
                                                boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                                                position: 'relative',
                                            }}
                                        >
                                            {/* Set avatar source conditionally */}
                                            <Avatar
                                                src={reminder.startsWith("It’s 4 pm") ? rememberALLicon : userImage}
                                                alt="User"
                                                sx={{
                                                    marginRight: '10px',
                                                    width: '30px',
                                                    height: '30px',
                                                }}
                                            />

                                            <Typography
                                                variant="body2"
                                                sx={{
                                                    fontFamily: 'Raleway',
                                                    fontSize: '14px',
                                                    color: '#000',
                                                    wordWrap: 'break-word',
                                                    overflowWrap: 'break-word',
                                                    textAlign: 'left',
                                                    flexGrow: 1,
                                                }}
                                            >
                                                {reminder}
                                            </Typography>

                                            {/* Button for task check-in, only shown if reminder is not from input */}
                                            {!isFromInput && (
                                                <Box
                                                    sx={{
                                                        marginLeft: '10px',
                                                        flexShrink: 0,
                                                    }}
                                                >
                                                    <button
                                                        variant="contained"
                                                        color="primary"
                                                        onClick={handleGoToSpace}
                                                        sx={{
                                                            width: 'auto',
                                                            fontSize: '10px',
                                                            padding: '5px 10px',
                                                        }}
                                                    >
                                                        Go To Space
                                                    </button>
                                                </Box>
                                            )}
                                        </Box>
                                    ))
                                )
                            )}
                        </Box>


                        {/* Input Form - Positioned at the bottom */}
                        <Box
                            sx={{
                                flexShrink: 0,
                                padding: "10px",
                                backgroundColor: "#f0f0f0",
                                borderRadius: "25px",
                                position: "absolute",
                                bottom: "20px",
                                left: "50%",
                                transform: "translateX(-50%)",
                                width: "90%",
                                maxWidth: "600px",
                                display: "flex",
                                alignItems: "center",
                            }}
                        >
                            <form onSubmit={handleFormSubmit} style={{ width: '100%' }}>
                                <input
                                    type="text"
                                    name="reminderInput" // Make sure this matches what you reference in the submit handler
                                    placeholder="What do you want to remember?"
                                    value={message}
                                    onChange={handleInputChange}
                                    style={{
                                        color: '#00000066',
                                        fontFamily: 'Raleway',
                                        fontSize: '16px',
                                        width: '100%',
                                        border: 'none',
                                        outline: 'none',
                                        background: 'transparent',
                                        textAlign: 'left',
                                        paddingRight: '40px',
                                    }}
                                />
                                <button
                                    className="comment-send-button"
                                    type="submit"
                                    style={{
                                        position: "absolute",
                                        right: "10px",
                                        top: "50%",
                                        transform: "translateY(-50%)",
                                        border: "none",
                                        background: "transparent",
                                        cursor: "pointer",
                                    }}
                                >
                                    <SendIcon
                                        style={{
                                            fill: "none",
                                            stroke: "#D8BD7D",
                                            strokeWidth: "2",
                                            width: "40px",
                                            height: "40px",
                                        }}
                                    />
                                </button>
                            </form>
                        </Box>

                    </Item>
                </Grid>
                {/*Remember All GRID  End*/}

                {/*Today Tasks GRID  START*/}
                <Grid
                    item
                    xs={5}
                    sx={{

                        position: 'relative', // Set relative position for proper positioning of child elements
                        height: {
                            xs: 'auto',           // Automatic height for extra-small screens
                            sm: '30vh',           // 40% of the viewport height for small screens
                            md: '40vh',           // 50% of the viewport height for medium screens
                            lg: '50vh'            // 60% of the viewport height for large screens
                        },
                        paddingTop: 0, // No top padding on the Grid item
                    }}
                >
                    <Item
                        sx={{
                            opacity: isCheckedIn ? 1 : 0.3,
                            filter: isCheckedIn ? 'none' : 'blur(4px)',
                            transition: showTransition ? 'opacity 0.5s ease, filter 0.5s ease' : 'none',
                            height: '100%', // Ensure the Item takes full height of the Grid
                            background: '#FFFFFF',
                            display: 'flex',
                            flexDirection: 'column', // Stack children vertically
                            justifyContent: 'flex-start',
                            padding: { xs: 0, sm: 4 }, // No padding at the top, keep the bottom padding
                            textAlign: 'center',
                            position: 'relative',
                        }}
                    >
                        {/* Heading for the Item */}
                        <Box
                            sx={{
                                width: '100%',
                                paddingBottom: '5px', // Reduced padding below heading
                                marginTop: '-10px', // Further reduced margin to pull heading up
                                position: "sticky",
                                top: 0,
                                left: 0,
                                right: 0,
                                zIndex: 1,
                                backgroundColor: '#FFFFFF', // Ensures background covers content when sticky
                                height: 'auto',
                            }}
                        >
                            <Typography
                                variant="h6"
                                sx={{
                                    fontFamily: 'Montserrat',
                                    fontWeight: '900',
                                    fontSize: '24px',
                                    color: '#333',
                                    textAlign: 'center',
                                }}
                            >
                                Today’s tasks
                            </Typography>
                        </Box>
                        {/* Task List */}
                        <Box
                            sx={{
                                width: '100%',
                                marginTop: '10px', // Spacing between heading and tasks
                                flex: 1, // Task list takes remaining space
                                overflowY: 'auto', // Enable scrolling for tasks
                                maxHeight: 'calc(100% - 20px)', // Adjust maxHeight to prevent overflow with header
                            }}
                        >
                            {Array.isArray(tasks) && tasks.map((task, index) => (
                                <TaskItem key={index} task={task} />
                            ))}
                        </Box>

                    </Item>
                </Grid>
                {/*Today Tasks GRID  End*/}

                {!isCheckedIn && (
                    <>

                        {isPopupOpen && (
                            <Grid
                                item
                                xs={7}
                                sx={{
                                    zIndex: 3,
                                    position: 'absolute',
                                    top: '50%',
                                    right: '15%',
                                    transform: 'translateY(-50%)',
                                    width: '80%',
                                    height: '80vh',
                                    backgroundColor: '#fff',
                                    borderRadius: '35px',
                                    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    padding: '20px',
                                    boxSizing: 'border-box',
                                }}
                            >
                                <Box
                                    sx={{
                                        position: 'relative', // Set position to relative for absolute positioning of children
                                        height: '100%',
                                        width: '100%',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'flex-start', // Changed to flex-start to stack items closer
                                        alignItems: 'center',
                                        padding: '10px',
                                        boxSizing: 'border-box',
                                    }}
                                >
                                    {/* Heading at the top, centered horizontally */}
                                    <div style={{
                                        width: '100%',
                                        fontFamily: 'Montserrat',
                                        textAlign: 'center',
                                        fontSize: '30px',
                                        fontWeight: 'bold',
                                        borderBottom: '1px solid #ccc',
                                        marginBottom: '20px'
                                    }}>
                                        Check In
                                    </div>


                                    <OnBoardingCheckIn onCheckIn={handleCheckIn} />

                                </Box>
                            </Grid>
                        )}
                    </>
                )}

            </Grid>
        </Box >
    );
};

export default Dashboard1;
