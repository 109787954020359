// Modal.js
import React, { useRef, useEffect } from 'react';

const Modal = ({ children, onClose }) => {
  const modalRef = useRef(null);

  // Handle clicks outside of the modal content
  const handleClickOutside = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      onClose();
    }
  };

  // Attach the event listener when the component mounts
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    // Remove the event listener when the component unmounts
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="modal">
      <div className="modal-content" ref={modalRef}>
        <span className="close" onClick={onClose}>
          &times;
        </span>
        {children}
      </div>
    </div>
  );
};

export default Modal;
