import React, { useState, useRef, useEffect } from "react";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { Draggable } from "react-beautiful-dnd";
import { connect, useSelector, useDispatch } from 'react-redux';
import { editCard, deleteCard } from '../../reducers/listReducer.js';
import TextareaAutosize from 'react-textarea-autosize';
import CommentsSection from './Comment.jsx'
import { backendURL } from "../../api/axios.js";
import { Icon, Checkbox, Select, MenuItem, InputLabel } from "@mui/material";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import styled from "styled-components";
import './Card.css';
import TaskIcon from "../../Icons/Task.tsx";
import CalendarIcon from "../../Icons/Calendar_Check.tsx";
import GoalIcon from "../../Icons/Bullseye-pointer.tsx";
import UserIcon from "../../Icons/User.tsx";
import CommentIcon from "../../Icons/Comment_Alt_Lines.tsx";
import DeleteIcon from "../../Icons/Trash.tsx";
import AssigneeImage from "../../Icons/Assignee_Image.jsx";
import DescriptionIcon from "../../Icons/Hamburger.tsx";
import AddIcon from "../../Icons/Circle_Add.tsx";
import RichTextEditor from "./RichTextEditor.jsx";
import { EditorState, convertToRaw, convertFromRaw } from 'draft-js';
import TagIcon from "../../Icons/Tag.tsx";
import CrossIcon from "../../Icons/Cross.tsx";
import ActivityIcon from "../../Icons/Arrow-trend-up.tsx";
import WatchIcon from "../../Icons/Stop Watch.tsx";
import Sidebar from "./Sidebar.jsx"
import CommunitySidebar from "./CommunitySidebar.jsx";

const styles = {
  cardContainer: {
    margin: "20px 0px",
    borderRadius: 30,
    boxShadow: 'none',
    backgroundColor: '#81d672',
    '&::WebkitScrollbar': { width: 0, height: 0 }
  },
  yellowcardContainer: {
    margin: "20px 0px",
    borderRadius: 30,
    boxShadow: 'none',
    backgroundColor: '#E3A357',
    '&::WebkitScrollbar': { width: 0, height: 0 }
  },
  redcardContainer: {
    margin: "20px 0px",
    borderRadius: 30,
    boxShadow: 'none',
    backgroundColor: '#BC4B4B',
    '&::WebkitScrollbar': { width: 0, height: 0 }
  },
  dropDown: {
    backgroundColor: 'unset',
    height: 'max-content',
    outline: '1px solid #d0d1d280',
    borderRadius: 30,
    color: 'black',
    margin: 0,
    padding: '5px 20px',
    fontFamily: "Raleway",
    boxShadow: 'none'
  }
}
function checkCommentStatus(comments, specificUserIds) {
  // Get the current time
  const currentTime = new Date();

  // Calculate the time 24 and 36 hours ago
  const twentyFourHoursAgo = new Date(currentTime.getTime() - (24 * 60 * 60 * 1000));
  const thirtySixHoursAgo = new Date(currentTime.getTime() - (36 * 60 * 60 * 1000));

  // Check if any user made a comment in the last 24 hours
  const anyCommentIn24Hours = !comments.some(comment => new Date(comment.timestamp) > twentyFourHoursAgo);

  // Check if any specific user made a comment in the last 24 hours
  const specificUserCommentIn24Hours = !comments.some(comment =>
    specificUserIds.includes(comment.userId) && new Date(comment.timestamp) > twentyFourHoursAgo
  );

  // Check if any specific user made a comment in the last 36 hours
  const specificUserCommentIn36Hours = !comments.some(comment =>
    specificUserIds.includes(comment.userId) && new Date(comment.timestamp) > thirtySixHoursAgo
  );

  // Determine the result based on the conditions
  if (specificUserCommentIn36Hours) {
    return 1;
  } else if (specificUserCommentIn24Hours) {
    return 2;
  } else if (anyCommentIn24Hours) {
    return 3;
  } else {
    return 4;
  }
}

function checkDueDate(dueDate) {
  // Convert due date string to Date object
  if (!dueDate) {
    return 1
  }
  const due = new Date(dueDate);
  // Get current date
  const now = new Date();

  // Calculate the difference in milliseconds
  const diff = due - now;

  // Convert milliseconds to days
  const diffHours = diff / (1000 * 60 * 60) - 5;
  //console.log(diffHours,diff,diff / (1000 * 60 * 60),now-due,dueDate,due,now)

  if (diffHours > 48) {
    return 1; // More than 48 hours till due date
  } else if (diffHours >= 0) {
    return 2; // Less than 48 hours till due date
  } else {
    return 3; // Overdue
  }
}


const KanbanCard = ({ text, id, listID, index, card }) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [cardDetails, setCardDetails] = useState(card);
  const [selectedOptions, setSelectedOptions] = useState(card.assignee);
  const [options, setOptions] = useState([]);
  const [projects, setProjects] = useState([]);
  const [selectedProject, setSelectedProject] = useState(card.project);
  const lists = useSelector(state => state.list);

  const [idToTitleMapping, setIdToTitleMapping] = useState({});


  const updateMapping = () => {
    const newMapping = {};
    lists.forEach(item => {
      newMapping[item._id] = item.title;
    });
    setIdToTitleMapping(newMapping);
  };

  useEffect(() => {
    updateMapping();
  }, [lists]); // Trigger updateMapping when data changes

  useEffect(() => {
    fetch(backendURL + '/list/access/' + listID)
      .then(response => response.json())
      .then(data => {
        setOptions(data.map(item => ({ value: item.id, label: item.name, picture: item.profilePicture })));
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }, []);

  useEffect(() => {
    fetch(backendURL + "/boards/" + cardDetails.boardId + "/projects")
      .then(response => response.json())
      .then(data => {
        setProjects(data.map(item => item));
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }, []);


  const handleChange = (event) => {
    const { value } = event.target;


    setSelectedOptions(value);
    dispatch(editCard({ id: id, listID: listID, card: { ...cardDetails, assignee: value } }));

  };
  useEffect(() => {
    setCardDetails({
      ...cardDetails,
      assignee: selectedOptions,
      project: selectedProject
    });
  }, [selectedOptions]);
  const dispatch = useDispatch();

  const handleDeleteCard = e => {
    dispatch(deleteCard({ id: id, listID: listID }));
  };
  const handleSaveCard = () => {
    // Run Redux action to edit card
    dispatch(editCard({ id: id, listID: listID, card: cardDetails }));
    setIsDialogOpen(false);
  };

  const handleCancel = () => {
    setIsDialogOpen(false);
    // Reset card details if cancel is clicked
    setCardDetails(card);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCardDetails({
      ...cardDetails,
      [name]: value
    });
    console.log("dispatching");
    dispatch(editCard({ id: id, listID: listID, card: { ...cardDetails, [name]: value } }));
  };
  const cardStateColor = checkCommentStatus(card.comments, card.assignee);
  const dueDateStatus = checkDueDate(card.dueDate)
  //console.log("COLOR", dueDateStatus,card.dueDate,new Date(),card.title)
  return (
    <>
      {isDialogOpen && (
        card.community ?
          <CommunitySidebar
            listID={listID}
            onClose={() => { setIsDialogOpen(false); window.location.reload() }}
            cardDetails={cardDetails}
            handleInputChange={handleInputChange}
            handleDeleteCard={handleDeleteCard}
            handleCancel={handleCancel}
            handleSaveCard={handleSaveCard}
            handleChange={handleChange}
            selectedOptions={selectedOptions}
            options={options}
            id={id}
            projects={projects}
            setProjects={setProjects}
            selectedProject={selectedProject}
            setSelectedProject={setSelectedProject}
            setCardDetails={setCardDetails}
            idToTitleMapping={idToTitleMapping}
          /> :
          <Sidebar
            listID={listID}
            onClose={() => { setIsDialogOpen(false); window.location.reload() }}
            cardDetails={cardDetails}
            handleInputChange={handleInputChange}
            handleDeleteCard={handleDeleteCard}
            handleCancel={handleCancel}
            handleSaveCard={handleSaveCard}
            handleChange={handleChange}
            selectedOptions={selectedOptions}
            options={options}
            id={id}
            projects={projects}
            setProjects={setProjects}
            selectedProject={selectedProject}
            setSelectedProject={setSelectedProject}
            setCardDetails={setCardDetails}
            idToTitleMapping={idToTitleMapping}
          />
      )}

      <Draggable draggableId={String(id)} index={index}>
        {provided => (
          <div className="card-container" ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
            <Card style={dueDateStatus === 1 ? styles.cardContainer : dueDateStatus === 2 ? styles.yellowcardContainer : dueDateStatus == 3 ? styles.redcardContainer : styles.cardContainer} onDoubleClick={() => setIsDialogOpen(true)}>
              <div className="assignee-container" >
                {options.length > 0 ? card.assignee.map(value => {
                  const matchedOption = options.find(option => option.value === value);
                  if (matchedOption) {
                    return (

                      <AssigneeImage imageUrl={matchedOption.picture} />

                    );
                  }
                  return null;
                })
                  : ''}
              </div>
              <CardContent style={{ boxSizing: 'border-box', width: idToTitleMapping[card.listId] === 'Doing' && cardStateColor === 1 ? '100%' : idToTitleMapping[card.listId] === 'Doing' && cardStateColor == 2 ? '100%' : cardStateColor === 3 && idToTitleMapping[card.listId] === 'Testing' ? '100%' : '96%', padding: idToTitleMapping[card.listId] === '25px 40px 25px 25px' && cardStateColor === 1 ? '25px 40px 25px 25px' : idToTitleMapping[card.listId] === 'Doing' && cardStateColor == 2 ? '25px 40px 25px 25px' : cardStateColor === 3 && idToTitleMapping[card.listId] === 'Testing' ? '25px 40px 25px 25px' : 25, backgroundColor: idToTitleMapping[card.listId] === 'Doing' && cardStateColor === 1 ? '#BC4B4B' : idToTitleMapping[card.listId] === 'Doing' && cardStateColor == 2 ? '#E3A357' : cardStateColor === 3 && idToTitleMapping[card.listId] === 'Testing' ? 'purple' : 'white' }}>
                <div className="text-row">
                  <TaskIcon></TaskIcon>
                  <div className="card-text name">{text}</div>
                </div>
                {card.project ?
                  <div className="text-row">
                    <GoalIcon></GoalIcon>
                    <div className="card-text">{card.project}</div>
                  </div> : null}
                {card.assignee.length > 0 ?
                  <div className="text-row">
                    <UserIcon></UserIcon>
                    <div className="card-text">
                      {options.length > 0 ? card.assignee.map(value => options.find(option => option.value === value).label).join(', ') : ''}
                    </div>
                  </div> : null}
                <div className="text-row">
                  <CommentIcon></CommentIcon>
                  <div className="card-text">
                    {card.comments.length}
                  </div>
                </div>
                {card.dueDate ?
                  <div className="text-row">
                    <CalendarIcon></CalendarIcon>
                    <div className="card-text">
                      {card.dueDate ? new Date(card.dueDate).toLocaleDateString('en-GB', { day: 'numeric', month: 'long', year: 'numeric' }) : "No due date"}
                    </div>
                  </div> : null}
                {/* <Typography>
                {card.label}
              </Typography> */}
                {/* <Typography>
                {'assignee:'}{options.length>0?card.assignee.map(value => options.find(option => option.value === value).label).join(', '):''}
              </Typography>   
              <Typography>
                {'comments:'}{card.comments.length}
              </Typography>
              <Typography>
                {card.dueDate?new Date(card.dueDate).toLocaleDateString('en-GB', { day: 'numeric', month: 'long', year: 'numeric' }):"No Due Date"}
              </Typography> */}



              </CardContent>

            </Card>
          </div>)}
      </Draggable>
    </>);

}


export default KanbanCard;