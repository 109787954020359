import React, { useState, useEffect } from 'react';
import { useParams , useLocation, useNavigate} from 'react-router-dom';
import axios from 'axios';
import './MeetingDetails.css';
import CircularCheckbox from '../Chat/Checkbox';
import '../Chat/Checkbox.css';
import { hushend} from '../../api/axios';


function MeetingDetails() {
    const location = useLocation();

  const { meetingId } = useParams();
  const [details, setDetails] = useState(null);
  const [checkedState, setCheckedState] = useState({});
console.log("location");
console.log(location);
const { title, datetime } = location.state || {};

  useEffect(() => {
    hushend.get(`meeting_details/${meetingId}`)
      .then(response => {
        setDetails(response.data);

        //Initialize checkboxes based on local storage
        const savedChecks = JSON.parse(localStorage.getItem('actionItemsChecks') || '{}');
        setCheckedState(savedChecks);
      })
      .catch(error => {
        console.error('Error fetching meeting details:', error);
      });
  }, [meetingId]);

  if (!details) {
    return <div>Loading...</div>;
  }


  const handleCheckboxChange = (index) => {
    const newState = { ...checkedState, [index]: !checkedState[index] };
    setCheckedState(newState);
    // Save the new state to local storage
    localStorage.setItem('actionItemsChecks', JSON.stringify(newState));
  };


    
  return (
    <div style={{ overflowY: 'auto', height: '100vh' }}>
        <div className="meeting-title">
          <h2>{title}</h2>
          <p>{datetime}</p>
        </div>
    <div className="meeting-details-container">
         {/* Meeting Title and Date/Time */}
      <div className="column left">
        <div className="box summary">
          <h4>Summary</h4>
          <p>{details.description}</p>
        </div>
        <div className="box transcription">
          <h4>Transcription</h4>
          {details.transcription.map((text, index) => (
            <div key={index} className="message-bubble">
              <span>Person:</span> {text}
            </div>
          ))}
        </div>
        <div style={{ height: '20px' }}></div> {/* Empty space */}

      </div>
      <div className="column right">
        <div className="box">
          <h4>Action Items</h4>
          <ul>
            {details.action_items.map((item, index) => (
              <li key={index}>
              <CircularCheckbox
                checked={!!checkedState[index]}// You might want to manage this state per item
                onChange={() => handleCheckboxChange(index)}
              />
              {item}
            </li>
            ))}
          </ul>
        </div>
        <div className="box">
          <h4>Keywords</h4>
          <div className="tags">
            {details.keywords.map((keyword, index) => (
              <span key={index} className="tag">{keyword}</span>
            ))}
          </div>
        </div>
        <div className="box">
          <h4>Topics</h4>
          <div className="tags">
            {details.topics.map((topic, index) => (
              <span key={index} className="tag">{topic}</span>
            ))}
          </div>
        </div>
        <div className="box">
          <h4>Additional Features</h4>
          <p>{details.additional_features}</p>
        </div>
        <div style={{ height: '20px' }}></div> {/* Empty space */}
      </div>
    </div>
    </div>
  );
}

export default MeetingDetails;
