import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import Paper from '@mui/material/Paper';
import Chip from '@mui/material/Chip';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import TaskIcon from "../../Icons/Task.tsx";
import GoalIcon from "../../Icons/Bullseye-pointer.tsx";
import { Stack } from '@mui/material';
import { useSelector } from 'react-redux';
import { backendURL } from "../../api/axios.js";
import StatusChip from '../ListView/StatusChip.jsx';
import AssigneeImage from '../../Icons/Assignee_Image.jsx';
import { Avatar } from '@mui/material';
export default function CustomizedTables({ text, id, listID, index, card, dateFilter, searchTerm, selectedProjectlist }) {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [cardDetails, setCardDetails] = useState(card || { boardId: null }); // Initialize with empty object if card is not provided

  const [selectedOptions, setSelectedOptions] = useState(card && card.assignee ? card.assignee : []);
  const [options, setOptions] = useState([]);
  const [projects, setProjects] = useState([]);
  const [selectedProject, setSelectedProject] = useState(card && card.project ? card.project : null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [cards, setCards] = useState([]);
  const lists = useSelector(state => state.list);
  const [idToTitleMapping, setIdToTitleMapping] = useState({});
  const getInitials = (name) => {
    const names = name.split(" ");
    const initials = names.map((n) => n[0]).join("");
    return initials.toUpperCase();
  };

  // Update ID to title mapping based on lists
  const updateMapping = () => {
    const newMapping = {};
    lists.forEach(item => {
      newMapping[item._id] = item.title;
    });
    setIdToTitleMapping(newMapping);
  };

  useEffect(() => {
    updateMapping();
  }, [lists]);

  useEffect(() => {
    console.log('Fetching listID:', listID);
    fetch(`${backendURL}/list/access/${listID}`)
      .then(response => response.json())
      .then(data => {
        setOptions(data.map(item => ({ value: item.id, label: item.name, picture: item.profilePicture })));
      })
      .catch(error => {
        console.error('Error fetching options:', error);
      });
  }, [listID]);
  useEffect(() => {
    if (cardDetails.boardId) {
      console.log("fetch board", cardDetails)
      fetch(backendURL + "/boards/" + cardDetails.boardId + "/projects")
        .then(response => response.json())
        .then(data => {
          setProjects(data.map(item => item));
        })
        .catch(error => {
          console.error('Error fetching projects:', error);
        });
    }
  }, [cardDetails.boardId]); // Only run when cardDetails.boardId is defined

  useEffect(() => {
    const fetchCards = async () => {
      try {
        console.log("listid", listID);
        console.log('Fetching cards:', card);
        const response = await fetch(`${backendURL}/cards/${listID}`);

        // Check if the response is okay
        if (!response.ok) {
          const errorText = await response.text(); // Get the response body
          console.error('Error details:', errorText); // Log the error details
          throw new Error('Network response was not ok');
        }

        // Parse the response data
        const data = await response.json();
        console.log("fetchcards", data); // Log the data received from the API

        // Set the data to the cards state
        setCards(data);
      } catch (error) {
        console.error('Error fetching cards:', error); // Log the error
        setError(error); // Set error state
      } finally {
        setLoading(false); // Stop loading state
      }
    };

    fetchCards();
  }, [listID]); // Fetch cards when listID changes


  useEffect(() => {
    setCardDetails({
      ...cardDetails,
      assignee: selectedOptions,
      project: selectedProject
    });
  }, [selectedOptions, selectedProject]);



  const groupedCards = cards.reduce((acc, card) => {
    const project = card.project || 'Unassigned Project'; // Use 'Unassigned' if no project is defined
    acc[project] = acc[project] || [];
    acc[project].push(card);
    return acc;
  }, {});


  return (
    <Box
      sx={{
        maxHeight: '500px',
        overflowY: 'auto',
        padding: '10px',
      }}
    >
      <Grid container spacing={1} justifyContent="center">
        {Object.keys(groupedCards).map((project, index) => (
          <Grid item xs={12} sm={10} md={8} lg={10} key={project}>
            <TableContainer
              component={Paper}
              sx={{
                p: 2,
                borderRadius: '35px',
                overflow: 'hidden',
                boxShadow: '0 4px 10px rgba(0,0,0,0.1)',
                marginBottom: '10px', // Add space between project tables
              }}
            >
              <Stack direction="row" alignItems="center" spacing={1} sx={{ ml: 2 }}>
                <GoalIcon />
                <Typography variant="h7" sx={{ fontWeight: 'bold' }}>
                  {project}
                </Typography>
              </Stack>

              <Table sx={{ minWidth: 500 }} aria-label="simple table" size="small">
                <TableHead>
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell sx={{ fontWeight: 'bold' }}>Task</TableCell>
                    <TableCell sx={{ fontWeight: 'bold' }} align="left">Status</TableCell>
                    <TableCell sx={{ fontWeight: 'bold' }} align="left">Due</TableCell>
                    <TableCell sx={{ fontWeight: 'bold' }} align="left">Assignee</TableCell>

                  </TableRow>
                </TableHead>
                <TableBody>
                  {groupedCards[project]
                    .filter((item) => {
                      // Check if `status` includes the search term or if any assignee includes the search term
                      return (
                        item.status.toLowerCase().includes(searchTerm.toLowerCase()) || item.assignees.some((assignee) => assignee.toLowerCase().includes(searchTerm.toLowerCase()))
                      );
                    })
                    .map((card, index) => (
                      <TableRow
                        key={index}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 }, height: '36px' }}
                      >
                        <TableCell component="th" scope="row" sx={{ gap: '8px' }}>
                          <TaskIcon />
                        </TableCell>
                        <TableCell align="left" sx={{
                          padding: 0,
                          maxWidth: '150px',
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                        }}>
                          {card.title}
                        </TableCell>

                        <TableCell align="left">
                          <StatusChip status={idToTitleMapping[card.listId]} />
                        </TableCell>
                        <TableCell align="left" style={{ padding: '4px 8px' }}>
                          {card.dueDate
                            ? new Date(card.dueDate).toLocaleDateString('en-GB', { day: 'numeric', month: 'long', year: 'numeric' })
                            : "No due date"}
                        </TableCell>
                        <TableCell align="left" style={{ padding: '4px 8px' }}>
                          {options.length > 0
                            ? card.assignee.map((value, index) => {
                              const matchedOption = options.find(option => option.value === value);

                              // Generate a random color for the avatar
                              const getRandomColor = () => {
                                const letters = '0123456789ABCDEF';
                                let color = '#';
                                for (let i = 0; i < 6; i++) {
                                  color += letters[Math.floor(Math.random() * 16)];
                                }
                                return color;
                              };

                              // If an image URL is available, show the image
                              if (matchedOption && matchedOption.picture) {
                                return <AssigneeImage key={index} imageUrl={matchedOption.picture} />;
                              }

                              // If no image is found, show the first initial in Avatar with random background color
                              if (matchedOption && matchedOption.label) {
                                const firstInitial = matchedOption.label.charAt(0).toUpperCase(); // Get first character of the name
                                const avatarColor = getRandomColor(); // Random color for the avatar background

                                return (
                                  <Avatar key={index} className="assignee-image" style={{ backgroundColor: avatarColor, marginRight: '4px' }}>
                                    {firstInitial}
                                  </Avatar>
                                );
                              }

                              return null;
                            })
                            : ''}
                        </TableCell>



                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        ))}
      </Grid>
    </Box>




  );
}
