// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.circle-image-column {
  display: flex;
  flex-direction: column;
  align-items: center; /* Align items in the center of the column */
  }
  
.circle-image-wrapper {
  width: 45px; /* Adjust according to your preference */
  height: 45px; /* Adjust according to your preference */
  border-radius: 50%; /* Make the wrapper circular */
  overflow: hidden; /* Hide overflow to make sure the image stays within the circle */
  /* margin-bottom: 20px; /* Adjust the margin between images */
  /* margin-left: 20px; */
  /* margin-right: 20px; */
}

.circle-image {
  width: 100%; /* Make the image take up the entire space of its parent */
  height: 100%; /* Make the image take up the entire space of its parent */
  object-fit: cover; /* Maintain aspect ratio and cover the entire area */
}
.status {
  font-size: 12px;
  margin-top: 5px;
  text-align: center;
}
.member-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}`, "",{"version":3,"sources":["webpack://./src/components/Community/CircleImageColumn.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB,EAAE,4CAA4C;EACjE;;AAEF;EACE,WAAW,EAAE,wCAAwC;EACrD,YAAY,EAAE,wCAAwC;EACtD,kBAAkB,EAAE,8BAA8B;EAClD,gBAAgB,EAAE,iEAAiE;EACnF,6DAA6D;EAC7D,uBAAuB;EACvB,wBAAwB;AAC1B;;AAEA;EACE,WAAW,EAAE,0DAA0D;EACvE,YAAY,EAAE,0DAA0D;EACxE,iBAAiB,EAAE,oDAAoD;AACzE;AACA;EACE,eAAe;EACf,eAAe;EACf,kBAAkB;AACpB;AACA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;AACrB","sourcesContent":[".circle-image-column {\n  display: flex;\n  flex-direction: column;\n  align-items: center; /* Align items in the center of the column */\n  }\n  \n.circle-image-wrapper {\n  width: 45px; /* Adjust according to your preference */\n  height: 45px; /* Adjust according to your preference */\n  border-radius: 50%; /* Make the wrapper circular */\n  overflow: hidden; /* Hide overflow to make sure the image stays within the circle */\n  /* margin-bottom: 20px; /* Adjust the margin between images */\n  /* margin-left: 20px; */\n  /* margin-right: 20px; */\n}\n\n.circle-image {\n  width: 100%; /* Make the image take up the entire space of its parent */\n  height: 100%; /* Make the image take up the entire space of its parent */\n  object-fit: cover; /* Maintain aspect ratio and cover the entire area */\n}\n.status {\n  font-size: 12px;\n  margin-top: 5px;\n  text-align: center;\n}\n.member-wrapper {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
