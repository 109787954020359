import React, { useState } from 'react';
import { Drawer, IconButton, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import LogoutIcon from '@mui/icons-material/ExitToApp';
import { NavLink } from 'react-router-dom';
import logo from '../Icons/inquidsitive-logo1.png';
import collapsedLogo from '../Icons/collapsedLogo.png';
import ChatIcon from '../Icons/Comments_Alt_Lines.tsx';
import ProjectIcon from '../Icons/Folder.tsx';
// import MindmapIcon from '../Icons/Cloud_Bolt.tsx';
// import SearchIcon from '../Icons/Search_5.tsx';
// import MeetingsIcon from '../Icons/Users_2';
import HomeIcon from '../Icons/Home_10.tsx';
import GroupIcon from '../Icons/Users_2';
import FactCheckOutlinedIcon from '@mui/icons-material/FactCheckOutlined';
import CommunityIcon from '../Icons/People_Group.tsx';
import './Sidebar.css';
const Sidebar = () => {
  const [isCollapsed, setIsCollapsed] = useState(false);

  const toggleDrawer = () => {
    setIsCollapsed(!isCollapsed);
  };
  return (
    <div>
      <Drawer
        variant="permanent"
        open={!isCollapsed}
        sx={{
          width: isCollapsed ? 74 : 180,  // Reduced width when collapsed and expanded
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: isCollapsed ? 74 : 180,  // Adjust the drawer paper width
            backgroundColor: '#1b7690',
            color: '#fff',
          },
        }}
      >
        <div style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between', // Space items evenly
          height: '100%', // Full height of the drawer
        }}>
          <div className="logo-container" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '60px', marginTop: '6vh', transition: 'all 0.3s ease' }}>
            <img
              src={isCollapsed ? collapsedLogo : logo} // Use collapsedLogo when collapsed, otherwise use the main logo
              alt="Logo"
              style={{
                width: isCollapsed ? '40px' : '100%', // Adjust logo size based on collapsed state
                height: 'auto',
                marginBottom: isCollapsed ? '0px' : '10px', // Adjust space between logo and list based on collapsed state
                transition: 'width 0.3s ease' // Smooth transition for width change
              }}
            />
          </div>
          <List style={{ flexGrow: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
           
          <NavLink
            to="/home"
            className={({ isActive }) => (isActive ? 'nav-link active' : 'nav-link')}
          >
            {({ isActive }) => (
              <ListItem
                button
                sx={{
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  height: '70px', // Set a fixed height for the ListItem
                  marginBottom: '24px', // Increased margin below the ListItem
                }}
              >
                <ListItemIcon sx={{ minWidth: 'auto' }}>
                  <HomeIcon
                    style={{
                      color: isActive ? '#e2be68' : '#fff', // Change color when active
                      fontSize: '30px', // Icon size
                    }}
                  />
                </ListItemIcon>
                {!isCollapsed && (
                  <ListItemText
                    primary="Home"
                    sx={{ textAlign: 'center', marginTop: '4px' }} // Center the text and add space between icon and text
                  />
                )}
              </ListItem>
            )}
          </NavLink>

          <NavLink
            to="/projects"
            className={({ isActive }) => (isActive ? 'nav-link active' : 'nav-link')}
          >
            {({ isActive }) => (
              <ListItem
                button
                sx={{
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  height: '70px', // Set a fixed height for the ListItem
                  marginBottom: '24px', // Increased margin below the ListItem
                }}
              >
                <ListItemIcon sx={{ minWidth: 'auto' }}>
                  <ProjectIcon
                    style={{
                      color: isActive ? '#e2be68' : '#fff', // Change color when active
                      fontSize: '30px', // Icon size
                    }}
                  />
                </ListItemIcon>
                {!isCollapsed && (
                  <ListItemText
                    primary="Projects"
                    sx={{ textAlign: 'center', marginTop: '4px' }} // Center the text and add space between icon and text
                  />
                )}
              </ListItem>
            )}
          </NavLink>


          <NavLink
            to="/tasks-LM"
            className={({ isActive }) => (isActive ? 'nav-link active' : 'nav-link')}
          >
            {({ isActive }) => (
              <ListItem
                button
                sx={{
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  height: '70px', // Set a fixed height for the ListItem
                  marginBottom: '24px', // Increased margin below the ListItem
                }}
              >
                <ListItemIcon sx={{ minWidth: 'auto' }}>
                  <FactCheckOutlinedIcon
                    style={{
                      color: isActive ? '#e2be68' : '#fff', // Change color when active
                      fontSize: '30px', // Icon size
                    }}
                  />
                </ListItemIcon>
                {!isCollapsed && (
                  <ListItemText
                    primary="Tasks"
                    sx={{ textAlign: 'center', marginTop: '4px' }} // Center the text and add space between icon and text
                  />
                )}
              </ListItem>
            )}
          </NavLink>
          
          <NavLink
            to="/hush-meetings"
            className={({ isActive }) => (isActive ? 'nav-link active' : 'nav-link')}
          >
            {({ isActive }) => (
              <ListItem
                button
                sx={{
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  height: '70px', // Set a fixed height for the ListItem
                  marginBottom: '24px', // Increased margin below the ListItem
                }}
              >
                <ListItemIcon sx={{ minWidth: 'auto' }}>
                  <GroupIcon
                    style={{
                      color: isActive ? '#e2be68' : '#fff', // Change color when active
                      fontSize: '30px', // Icon size
                    }}
                  />
                </ListItemIcon>
                {!isCollapsed && (
                  <ListItemText
                    primary="Meetings"
                    sx={{ textAlign: 'center', marginTop: '4px' }} // Center the text and add space between icon and text
                  />
                )}
              </ListItem>
            )}
          </NavLink>

          <NavLink
            to="/chat"
            className={({ isActive }) => (isActive ? 'nav-link active' : 'nav-link')}
          >
            {({ isActive }) => (
              <ListItem
                button
                sx={{
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  height: '70px', // Set a fixed height for the ListItem
                  marginBottom: '24px', // Increased margin below the ListItem
                }}
              >
                <ListItemIcon sx={{ minWidth: 'auto' }}>
                  <ChatIcon
                    style={{
                      color: isActive ? '#e2be68' : '#fff', // Change color when active
                      fontSize: '30px', // Icon size
                    }}
                  />
                </ListItemIcon>
                {!isCollapsed && (
                  <ListItemText
                    primary="Chat"
                    sx={{ textAlign: 'center', marginTop: '4px' }} // Center the text and add space between icon and text
                  />
                )}
              </ListItem>
            )}
          </NavLink>

          <NavLink
            to="/community"
            className={({ isActive }) => (isActive ? 'nav-link active' : 'nav-link')}
          >
            {({ isActive }) => (
              <ListItem
                button
                sx={{
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  height: '70px', // Set a fixed height for the ListItem
                  marginBottom: '24px', // Increased margin below the ListItem
                }}
              >
                <ListItemIcon sx={{ minWidth: 'auto' }}>
                  <CommunityIcon
                    style={{
                      color: isActive ? '#e2be68' : '#fff', // Change color when active
                      fontSize: '30px', // Icon size
                    }}
                  />
                </ListItemIcon>
                {!isCollapsed && (
                  <ListItemText
                    primary="Community"
                    sx={{ textAlign: 'center', marginTop: '4px' }} // Center the text and add space between icon and text
                  />
                )}
              </ListItem>
            )}
          </NavLink>


          </List>
          <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '10px',
            position: 'relative', // Changed to relative for proper positioning
            bottom: '15px',
          }}>
            <IconButton onClick={toggleDrawer} style={{ color: '#fff' }}>
              {isCollapsed ? (
                <KeyboardDoubleArrowRightIcon style={{ fontSize: '36px' }} />
              ) : (
                <KeyboardDoubleArrowLeftIcon style={{ fontSize: '36px' }} />
              )}
            </IconButton>
          </div>
        </div>
      </Drawer>
    </div>
  );
  // return (
  //   <div>
  //     <Drawer
  //       variant="permanent"
  //       open={!isCollapsed}
  //       sx={{
  //         width: isCollapsed ? 74 : 180,  // Reduced width when collapsed and expanded
  //         flexShrink: 0,
  //         '& .MuiDrawer-paper': {
  //           width: isCollapsed ? 74 : 180,  // Adjust the drawer paper width
  //           backgroundColor: '#1b7690',
  //           color: '#fff',
  //         },
  //       }}

  //     >
  //       <div className="logo-container" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '60px', marginTop: '6vh', transition: 'all 0.3s ease' }}>
  //         <img
  //           src={isCollapsed ? collapsedLogo : logo} // Use collapsedLogo when collapsed, otherwise use the main logo
  //           alt="Logo"
  //           style={{
  //             width: isCollapsed ? '40px' : '100%', // Adjust logo size based on collapsed state
  //             height: 'auto',
  //             marginBottom: isCollapsed ? '0px' : '10px', // Adjust space between logo and list based on collapsed state
  //             transition: 'width 0.3s ease' // Smooth transition for width change
  //           }}
  //         />
  //       </div>
  //       <List>
  //         <NavLink
  //           to="/home"
  //           className={({ isActive }) => (isActive ? 'nav-link active' : 'nav-link')}
  //         >
  //           {({ isActive }) => (
  //             <ListItem
  //               button
  //               sx={{
  //                 flexDirection: 'column',
  //                 alignItems: 'center',
  //                 justifyContent: 'center',
  //                 height: '70px', // Set a fixed height for the ListItem
  //                 marginBottom: '24px', // Increased margin below the ListItem
  //               }}
  //             >
  //               <ListItemIcon sx={{ minWidth: 'auto' }}>
  //                 <HomeIcon
  //                   style={{
  //                     color: isActive ? '#e2be68' : '#fff', // Change color when active
  //                     fontSize: '30px', // Icon size
  //                   }}
  //                 />
  //               </ListItemIcon>
  //               {!isCollapsed && (
  //                 <ListItemText
  //                   primary="Home"
  //                   sx={{ textAlign: 'center', marginTop: '4px' }} // Center the text and add space between icon and text
  //                 />
  //               )}
  //             </ListItem>
  //           )}
  //         </NavLink>

  //         <NavLink
  //           to="/projects"
  //           className={({ isActive }) => (isActive ? 'nav-link active' : 'nav-link')}
  //         >
  //           {({ isActive }) => (
  //             <ListItem
  //               button
  //               sx={{
  //                 flexDirection: 'column',
  //                 alignItems: 'center',
  //                 justifyContent: 'center',
  //                 height: '70px', // Set a fixed height for the ListItem
  //                 marginBottom: '24px', // Increased margin below the ListItem
  //               }}
  //             >
  //               <ListItemIcon sx={{ minWidth: 'auto' }}>
  //                 <ProjectIcon
  //                   style={{
  //                     color: isActive ? '#e2be68' : '#fff', // Change color when active
  //                     fontSize: '30px', // Icon size
  //                   }}
  //                 />
  //               </ListItemIcon>
  //               {!isCollapsed && (
  //                 <ListItemText
  //                   primary="Projects"
  //                   sx={{ textAlign: 'center', marginTop: '4px' }} // Center the text and add space between icon and text
  //                 />
  //               )}
  //             </ListItem>
  //           )}
  //         </NavLink>

  //         <NavLink
  //           to="/hush-meetings"
  //           className={({ isActive }) => (isActive ? 'nav-link active' : 'nav-link')}
  //         >
  //           {({ isActive }) => (
  //             <ListItem
  //               button
  //               sx={{
  //                 flexDirection: 'column',
  //                 alignItems: 'center',
  //                 justifyContent: 'center',
  //                 height: '70px', // Set a fixed height for the ListItem
  //                 marginBottom: '24px', // Increased margin below the ListItem
  //               }}
  //             >
  //               <ListItemIcon sx={{ minWidth: 'auto' }}>
  //                 <GroupIcon
  //                   style={{
  //                     color: isActive ? '#e2be68' : '#fff', // Change color when active
  //                     fontSize: '30px', // Icon size
  //                   }}
  //                 />
  //               </ListItemIcon>
  //               {!isCollapsed && (
  //                 <ListItemText
  //                   primary="Meetings"
  //                   sx={{ textAlign: 'center', marginTop: '4px' }} // Center the text and add space between icon and text
  //                 />
  //               )}
  //             </ListItem>
  //           )}
  //         </NavLink>

  //         <NavLink
  //           to="/chat"
  //           className={({ isActive }) => (isActive ? 'nav-link active' : 'nav-link')}
  //         >
  //           {({ isActive }) => (
  //             <ListItem
  //               button
  //               sx={{
  //                 flexDirection: 'column',
  //                 alignItems: 'center',
  //                 justifyContent: 'center',
  //                 height: '70px', // Set a fixed height for the ListItem
  //                 marginBottom: '24px', // Increased margin below the ListItem
  //               }}
  //             >
  //               <ListItemIcon sx={{ minWidth: 'auto' }}>
  //                 <ChatIcon
  //                   style={{
  //                     color: isActive ? '#e2be68' : '#fff', // Change color when active
  //                     fontSize: '30px', // Icon size
  //                   }}
  //                 />
  //               </ListItemIcon>
  //               {!isCollapsed && (
  //                 <ListItemText
  //                   primary="Chat"
  //                   sx={{ textAlign: 'center', marginTop: '4px' }} // Center the text and add space between icon and text
  //                 />
  //               )}
  //             </ListItem>
  //           )}
  //         </NavLink>

  //         <NavLink
  //           to="/community"
  //           className={({ isActive }) => (isActive ? 'nav-link active' : 'nav-link')}
  //         >
  //           {({ isActive }) => (
  //             <ListItem
  //               button
  //               sx={{
  //                 flexDirection: 'column',
  //                 alignItems: 'center',
  //                 justifyContent: 'center',
  //                 height: '70px', // Set a fixed height for the ListItem
  //                 marginBottom: '24px', // Increased margin below the ListItem
  //               }}
  //             >
  //               <ListItemIcon sx={{ minWidth: 'auto' }}>
  //                 <CommunityIcon
  //                   style={{
  //                     color: isActive ? '#e2be68' : '#fff', // Change color when active
  //                     fontSize: '30px', // Icon size
  //                   }}
  //                 />
  //               </ListItemIcon>
  //               {!isCollapsed && (
  //                 <ListItemText
  //                   primary="Community"
  //                   sx={{ textAlign: 'center', marginTop: '4px' }} // Center the text and add space between icon and text
  //                 />
  //               )}
  //             </ListItem>
  //           )}
  //         </NavLink>
  //       </List>


  //       <div style={{
  //         display: 'flex',
  //         justifyContent: 'center',
  //         alignItems: 'center',
  //         padding: '10px',
  //         position: 'absolute',
  //         bottom: '15px',
  //         left: '50%',
  //         transform: 'translateX(-50%)',
  //       }}>
  //         <IconButton onClick={toggleDrawer} style={{ color: '#fff' }}>
  //           {isCollapsed ? (
  //             <KeyboardDoubleArrowRightIcon style={{ fontSize: '36px' }} /> // Adjust size here
  //           ) : (
  //             <KeyboardDoubleArrowLeftIcon style={{ fontSize: '36px' }} /> // Adjust size here
  //           )}
  //         </IconButton>
  //       </div>

  //     </Drawer>
  //   </div>
  // );
};

export default Sidebar;
