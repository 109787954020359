import React from 'react';
import './ProgressBarComponent.css';

const ProgressBar = ({ progress, currentProcess }) => {
  return (
    <div className="progress-bar-container">
      <div className="progress-bar">
        <div className="progress-bar-fill" style={{ width: `${progress}%` }} />
      </div>
      <div className="progress-label">{currentProcess}</div>
    </div>
  );
};

export default ProgressBar;
