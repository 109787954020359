import React from 'react';
import './CircleImageColumn.css'; // Import CSS file for styling
import { Avatar } from '@mui/material'; // Import Avatar from Material-UI
import PersonIcon from '@mui/icons-material/Person'; 
const CircleImageColumn = ({ imageUrls }) => {
  return (
    <div className="circle-image-column">
      {imageUrls.map((user, index) => (
        <div className="member-wrapper" key={index}>
          <div className="circle-image-wrapper">
            {user.profilePicture ? (
              <img
                src={user.profilePicture}
                alt={`Image ${index + 1}`}
                className="circle-image"
              />
            ) : (
              <Avatar
                alt={`User ${index + 1}`}
                sx={{ width: 48, height: 48}} // Highlight main user with a different color
              >
                <PersonIcon sx={{fontSize:'35px', color: '#fff' }} /> {/* Show user icon instead of letters */}
              </Avatar>
            )}
          </div>
          <p className="status">{user.status}</p>
        </div>
      ))}
    </div>
  );
};

export default CircleImageColumn;
