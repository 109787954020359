// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.board-container {
    display: flex; 
    flex-direction: row;
    justify-content: center;
    cursor: pointer;
}

.board {
    width: 250px;
    height: fit-content;
    background-color: white;
    padding: 20px;
    margin: 0px 10px;
    border-radius: 40px;
}`, "",{"version":3,"sources":["webpack://./src/components/Kanban/Board.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,eAAe;AACnB;;AAEA;IACI,YAAY;IACZ,mBAAmB;IACnB,uBAAuB;IACvB,aAAa;IACb,gBAAgB;IAChB,mBAAmB;AACvB","sourcesContent":[".board-container {\n    display: flex; \n    flex-direction: row;\n    justify-content: center;\n    cursor: pointer;\n}\n\n.board {\n    width: 250px;\n    height: fit-content;\n    background-color: white;\n    padding: 20px;\n    margin: 0px 10px;\n    border-radius: 40px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
