import React, { useState, useEffect } from 'react';
import axios from 'axios';

import { backendURL, fetchSameOrgUsers } from "../../api/axios.js";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Select, MenuItem, Chip, FormControl, InputLabel, Grid, Box } from '@mui/material';
import DashboardCustomizeIcon from '@mui/icons-material/DashboardCustomize';

const AddBoard = () => {
  const [open, setOpen] = useState(false);
  const [confirmationOpen, setConfirmationOpen] = useState(false); // Manage confirmation dialog
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');

  const [options, setOptions] = useState([]);
  const userID = localStorage.getItem('user_id');
  const [selectedOptions, setSelectedOptions] = React.useState([]);


  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await fetchSameOrgUsers(userID);
        setOptions(data.map(item => ({ key: item._id, value: item.firstName })));
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [userID]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // const handleCreate = async () => {
  //   const payload = { title: title, members: selectedOptions, description: description };
  //   console.log("COMMENT", payload);

  //   try {
  //     const response = await fetch(backendURL + "/boards/with-members", {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //       body: JSON.stringify(payload),
  //     });

  //     if (!response.ok) { 
  //       throw new Error('Failed to create board');
  //     }

  //     // Reload window only after successful response
  //     window.location.reload();
  //   } catch (error) {
  //     // Handle errors if any
  //     console.error("Error:", error);
  //   }
  // };
  const handleCreate = async () => {
    const payload = { title: title, members: selectedOptions, description: description };
    console.log("COMMENT", payload);

    try {
      const response = await fetch(backendURL + "/boards/with-members", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        throw new Error('Failed to create board');
      }

      // Alert the user when board is created successfully
      alert("Board created successfully!");

      // Reload window after successful response
      window.location.reload();
    } catch (error) {
      // Handle errors if any
      console.error("Error:", error);
      alert("Failed to create the board, please try again."); // Alert in case of error
    }
  };
  const handleOptionChange = (event) => {
    const value = event.target.value;
    setSelectedOptions(value); // Ensure the value is an array of selected keys
  };
  // Confirmation Popup Handlers
  const openConfirmationDialog = () => {
    setConfirmationOpen(true);
  };

  const closeConfirmationDialog = () => {
    setConfirmationOpen(false);
  };

  const handleConfirmCreate = () => {
    handleCreate();
    closeConfirmationDialog(); // Close confirmation dialog after "Yes"
  };

  return (
    <Box sx={{ padding: '20px', display: 'flex', flexDirection: 'column' }}>
      {/* Grid Layout for Add Button and HR */}
      <Grid container spacing={0} justifyContent="flex-start">
        <Grid item sx={{ display: 'flex', alignItems: 'center', marginBottom: 1 }}>
          <DashboardCustomizeIcon sx={{ marginRight: 1, fontSize: '30px', color: '#E39700' }} />
          <button
            variant="contained"
            color="primary"
            onClick={handleOpen}
            sx={{
              position: 'relative',
              padding: '6px 16px',
              backgroundColor: '#1b7690',
              color: 'white',
              border: 'none',
              borderRadius: '4px',
              cursor: 'pointer',
              '&:hover': {
                backgroundColor: '#155a6f',
              },
            }}
          >
            Add Board
          </button>
        </Grid>

        <Grid item xs={12} sx={{ marginTop: 0, paddingTop: 0 }}>
          {/* Removes any margin or padding on the top of the <hr> */}
          <hr style={{ borderColor: '#ccc', borderWidth: '1px', marginTop: 10 }} />
        </Grid>
      </Grid>



      {/* Dialog for Creating Board */}
      <Dialog
        open={open}
        onClose={handleClose}
        sx={{
          '& .MuiDialogContainer-root': {
            borderRadius: '16px', // Apply the desired border radius
          },
          '& .MuiDialog-paper': {
            borderRadius: '16px', // Apply the border radius to the dialog paper
          }
        }}
      >
        <DialogTitle>Create New Board</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Title"
            fullWidth
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
          <TextField
            margin="dense"
            label="Description"
            fullWidth
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />

          {/* Select Members */}
          <FormControl fullWidth margin="dense">
            <InputLabel>Select Members</InputLabel>
            <Select
              multiple
              value={selectedOptions}
              onChange={handleOptionChange}
              label="Select Members"
              renderValue={(selected) => {
                const selectedNames = options
                  .filter(option => selected.includes(option.key)) // Match the selected key values to their corresponding names
                  .map(option => option.value); // Extract the names
                return selectedNames.join(', '); // Join the selected names by commas
              }}
            >
              {options.map((option) => (
                <MenuItem key={option.key} value={option.key}>
                  {option.value}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={openConfirmationDialog}>Create</Button>
        </DialogActions>
      </Dialog>


      {/* Confirmation Popup */}
      <Dialog open={confirmationOpen} onClose={closeConfirmationDialog}>
        <DialogTitle>Confirmation</DialogTitle>
        <DialogContent>
          <p>Are you sure you want to create this board?</p>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConfirmCreate} color="primary">Yes</Button>
          <Button onClick={closeConfirmationDialog} color="secondary">No</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default AddBoard;
