// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.progress-bar-container {
    display: flex;
    display: none;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    width: 100%;
}

.progress-bar {
    width: 80%;
    height: 20px;
    background-color: #e9edf0;
    border-radius: 10px;
    border-style: groove;
    position: relative; /* To make the .progress-text position relative to .progress-bar */
}

.progress-bar-fill {
    height: 100%;
    border-radius: 10px;
    background-color: #28a745; /* Change fill color */
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    transition: width 0.5s ease-out;
}

.progress-text {
    font-size: 12px; /* Adjust font size */
}

.progress-label {
    margin-top: 10px; /* Space between progress bar and label */
    font-size: 14px; /* Adjust font size */
}

`, "",{"version":3,"sources":["webpack://./src/components/FileUpload/ProgressBarComponent.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,uBAAuB;IACvB,gBAAgB;IAChB,WAAW;AACf;;AAEA;IACI,UAAU;IACV,YAAY;IACZ,yBAAyB;IACzB,mBAAmB;IACnB,oBAAoB;IACpB,kBAAkB,EAAE,kEAAkE;AAC1F;;AAEA;IACI,YAAY;IACZ,mBAAmB;IACnB,yBAAyB,EAAE,sBAAsB;IACjD,kBAAkB;IAClB,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,YAAY;IACZ,+BAA+B;AACnC;;AAEA;IACI,eAAe,EAAE,qBAAqB;AAC1C;;AAEA;IACI,gBAAgB,EAAE,yCAAyC;IAC3D,eAAe,EAAE,qBAAqB;AAC1C","sourcesContent":[".progress-bar-container {\n    display: flex;\n    display: none;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    margin-top: 20px;\n    width: 100%;\n}\n\n.progress-bar {\n    width: 80%;\n    height: 20px;\n    background-color: #e9edf0;\n    border-radius: 10px;\n    border-style: groove;\n    position: relative; /* To make the .progress-text position relative to .progress-bar */\n}\n\n.progress-bar-fill {\n    height: 100%;\n    border-radius: 10px;\n    background-color: #28a745; /* Change fill color */\n    position: absolute;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    color: white;\n    transition: width 0.5s ease-out;\n}\n\n.progress-text {\n    font-size: 12px; /* Adjust font size */\n}\n\n.progress-label {\n    margin-top: 10px; /* Space between progress bar and label */\n    font-size: 14px; /* Adjust font size */\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
