import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Grid, Box, Switch, Card, CardContent, Button, IconButton, Typography, Paper } from '@mui/material';
import { styled } from '@mui/material/styles';
import classNames from 'classnames';
import ChatPage from './ChatPage'; // Import the ChatPage component
import CircleImageColumn from './CircleImageColumn';
import CircleImageRow from './CircleImageRow';
import ViewOnlySidebar from './ViewOnlySidebar';
import { fetchCommunityBoard, fetchSameOrgUsers, fetchCommunityLists, moveCardBetweenLists, updatedStatus } from '../../api/axios';
import { editCard } from '../../reducers/listReducer';
import './Community.css'; // Import CSS file for styling
const styles = {
    card_wrapper: {
        height: '100px', // Fixed height for the card
        margin: '10px', // Margin around the card
        borderRadius: 30, // Rounded corners for the card
        boxShadow: 'none', // No shadow for a flat look
        backgroundColor: '#fafafa', // Light gray background color
        padding: '5px', // Internal padding
        display: 'flex', // Ensure the card wrapper is flex to align its contents
        flexDirection: 'column', // Arrange children vertically
    },
    card: {
        display: 'flex', // Flex to allow for alignment of items
        flexDirection: 'column', // Vertical alignment
        justifyContent: 'space-between', // Space between elements
        height: '100%', // Full height to fill the wrapper
        padding: '10px', // Padding inside the card
    },
    cardContent: {
        display: 'flex', // Keep as flex for vertical layout
        flexDirection: 'column', // Align children in a column
        position: 'relative', // Position relative for absolute children
        flexGrow: 1, // Ensure it grows to fill available space
        justifyContent: 'space-between', // Space elements evenly within the content area
    },
    cardTitle: {
        position: 'absolute', // Position title at top left
        // top: '12px', // Slightly increased top margin
        // left: '12px', // Slightly increased left margin
    },
    takeTaskButton: {
        position: 'absolute', // Position button at bottom right
        bottom: '10px', // Slightly increased bottom margin
        right: '10px', // Slightly increased right margin
        color: 'white', // White text color
        border: 'none', // No border

        padding: '5px 10px', // Padding for button
        cursor: 'pointer', // Pointer cursor on hover
    },
};


// Custom styled Paper component for scrollable content
const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    position: "relative",
    borderRadius: "35px",
    height: 350,
    overflow: "auto", // Allow scrolling
}));

const ScrollableContent = styled(Box)(({ theme }) => ({
    height: "100%",
    overflowY: "auto",
    overflowX: "hidden",
    padding: theme.spacing(0),
    borderRadius: "5px",

    "&::-webkit-scrollbar": {
        width: "10px",
        height: "10px",
        backgroundColor: "#f0f0f0",
        borderRadius: "5px",
    },
    "&::-webkit-scrollbar-track": {
        backgroundColor: "#e0e0e0",
        borderRadius: "10px",
    },
    "&::-webkit-scrollbar-thumb": {
        backgroundColor: "#888",
        borderRadius: "5px",
        border: "2px solid #e0e0e0",
    },
}));

const StickyHeader = styled("h3")(({ theme }) => ({
    position: "sticky",
    top: 0,
    backgroundColor: "#fff",
    zIndex: 1,

   
}));

const CommunitySection = () => {
    const [users, setUsers] = useState([]);
    const [lists, setLists] = useState([]);
    const userID = localStorage.getItem('user_id');
    const dispatch = useDispatch();
    const [isToggled, setIsToggled] = useState(false);
    const [expanded, setExpanded] = useState(false);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [selectedCard, setSelectedCard] = useState(null);
    const [members, setMembers] = useState([]);

    const handleCardClick = (card) => {
        setSelectedCard(card);
        setIsDialogOpen(true);
    };
    const handleCloseDialog = () => {
        setIsDialogOpen(false);
        setSelectedCard(null);
        window.location.reload();
    };

    const toggleExpand = (e) => {
        e.stopPropagation();
        setExpanded(!expanded);
    };

    const handleToggle = () => {
        updatedStatus(userID, !isToggled ? "Checked In" : "Checked out");
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const board = await fetchCommunityBoard(userID);
                const data = await fetchCommunityLists(board._id);
                setLists(data.map(item => item));
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, [userID]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await fetchSameOrgUsers(userID);
                setIsToggled(data.find(item => item._id === userID).status === "Checked In");
                setUsers(data.map(item => item));
                setMembers(data.map(item => ({
                    value: item._id,
                    label: `${item.firstName} ${item.lastName}`,
                    picture: item.profilePicture
                })));
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
        const intervalId = setInterval(fetchData, 1000);
        return () => clearInterval(intervalId);
    }, [userID]);

    const handleButtonClick = (card, cardId, index, listId) => {
        const updatedLists = lists.map(list => {
            if (list._id === listId) {
                return {
                    ...list,
                    cards: list.cards.filter(card => card._id !== cardId)
                };
            }
            return list;
        });
        setLists(updatedLists);
        moveCardBetweenLists(cardId, index, listId, "66fd39665e17f81de9da3864");
        dispatch(editCard({ id: cardId, listID: listId, card: { ...card, assignee: userID } }));
    };

    return (
        <>
            <Switch
                checked={isToggled}
                onChange={handleToggle}
                inputProps={{ 'aria-label': 'controlled' }}
            />

            <div className="parent-container">
                <div className={classNames('peoplediv', { expanded })}
                    onClick={toggleExpand}>
                    {!expanded ? <CircleImageColumn imageUrls={users} /> : <CircleImageRow imageUrls={users} />}
                </div>
            </div>

            <Box sx={{ width: "100%" }}>
                <Grid container rowSpacing={2} columnSpacing={{ xs: 2, sm: 3, md: 4 }}>
                    {/* Grid 1: Open Items */}
                    <Grid item xs={6}>
                        <Item>
                            <StickyHeader>Open Items</StickyHeader>
                            <ScrollableContent>
                                {lists.length > 0 ? lists[0].cards.map((card, index) => (
                                    <Card style={styles.card_wrapper} key={index} className="cardt">
                                        {isDialogOpen && selectedCard && selectedCard._id === card._id && (
                                            <ViewOnlySidebar
                                                members={members}
                                                onClose={handleCloseDialog}
                                                cardDetails={selectedCard}
                                                id={selectedCard._id}
                                            />
                                        )}
                                        <CardContent style={styles.card} onClick={() => handleCardClick(card)}>
                                            <div style={styles.cardContent}>
                                                <span className="community-task" style={styles.cardTitle}>
                                                    {card.title}
                                                </span>
                                                <button
                                                    style={styles.takeTaskButton}
                                                    onClick={(e) => { e.stopPropagation(); handleButtonClick(card, card._id, index, card.listId); }}
                                                >
                                                    Take Task
                                                </button>
                                            </div>
                                        </CardContent>
                                    </Card>
                                )) : null}
                            </ScrollableContent>
                        </Item>
                    </Grid>
                    {/* Grid 2: Completed */}
                    <Grid item xs={6}>
                        <Item>
                            <StickyHeader>Completed</StickyHeader>
                            <ScrollableContent>
                                {lists.length > 0 ? lists[1].cards.map((card, index) => (
                                    <Card style={styles.card_wrapper} key={index} className="cardt">
                                        {isDialogOpen && selectedCard && selectedCard._id === card._id && (
                                            <ViewOnlySidebar
                                                members={members}
                                                onClose={handleCloseDialog}
                                                cardDetails={selectedCard}
                                                id={selectedCard._id}
                                            />
                                        )}
                                        <CardContent style={styles.card} onClick={() => handleCardClick(card)}>
                                            <div style={styles.cardContent}>
                                                {/* Render circular image of the assignee
                                                {card.assignee && (
                                                    <CircularImage 
                                                        src={card.assignee.profilePicture} // Assuming profilePicture is the correct property
                                                        alt={card.assignee.firstName} // Use the first name as the alt text
                                                    />
                                                )} */}
                                                <span className='community-task' style={styles.cardTitle}>
                                                    {card.title}
                                                </span>
                                            </div>
                                        </CardContent>
                                    </Card>
                                )) : null}
                            </ScrollableContent>
                        </Item>
                    </Grid>
                    {/* Replace this grid with the ChatPage component */}
                    <ChatPage />
                </Grid>
            </Box>

        </>
    );
};

export default CommunitySection;
