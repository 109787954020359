
import React, { useState, useRef, useEffect } from "react";

import { connect, useSelector, useDispatch } from 'react-redux';
import { editCard, deleteCard } from '../../reducers/listReducer.js';
import TextareaAutosize from 'react-textarea-autosize';
import CommentsSection from '../Kanban/Comment.jsx';
import { backendURL } from "../../api/axios.js";
import { Icon, Checkbox, Select, MenuItem, InputLabel } from "@mui/material";

import '../Kanban/Card.css';
import TaskIcon from "../../Icons/Task.tsx";
import CalendarIcon from "../../Icons/Calendar_Check.tsx";
import GoalIcon from "../../Icons/Bullseye-pointer.tsx";
import UserIcon from "../../Icons/User.tsx";

import DescriptionIcon from "../../Icons/Hamburger.tsx";
import AddIcon from "../../Icons/Circle_Add.tsx";
import RichTextEditor from "../Kanban/RichTextEditor.jsx";

import CrossIcon from "../../Icons/Cross.tsx";
import ActivityIcon from "../../Icons/Arrow-trend-up.tsx";
import WatchIcon from "../../Icons/Stop Watch.tsx";

const styles = {
  dropDown: {
    backgroundColor: 'unset',
    height: 'max-content',
    outline: '1px solid #d0d1d280',
    borderRadius: 30,
    color: 'black',
    margin: 0,
    padding: '5px 20px',
    fontFamily: "Raleway",
    boxShadow: 'none'
  }
}
const ViewOnlySidebar = ({ onClose, members, cardDetails, id,
}) => {


  const modalRef = useRef();
  console.log("members",members,cardDetails.assignee)
  return (
    <div className="backdrop">
      <div className="card-details" ref={modalRef}>

        {/* TASK TITLE */}
        <div className="detail-row head">
          <TaskIcon></TaskIcon>
          <div style={{ marginLeft: 15 }}>

            <h2 className="h2">{cardDetails.title}</h2>

          </div>
        </div>

        <div className="row">
          <div className="column">

            {/* PROJECT */}
            <div className="detail-row">
              <GoalIcon></GoalIcon>
              <span className="description-label">Project</span>
              <div className="detail-text">

                {cardDetails.project}
              </div>
            </div>
          </div>

          <div className="column">

            {/* ASSIGNEE */}
            <div className="detail-row">
              <UserIcon></UserIcon>
              <span className="description-label">Assignee</span>
              <div className="detail-text">
              {members.length > 0 ? cardDetails.assignee.length>0? members.find(option => option.value === cardDetails.assignee[0]).label : 'No Assignee': 'No Assignee'}
              </div>
            </div>

          </div>
        </div>
        <div className="row">
          <div className="column">

            {/* DUE DATE */}
            <div className="detail-row">
              <CalendarIcon></CalendarIcon>
              <span className="description-label">Due Date</span>
              <div className="detail-text">
                {cardDetails.dueDate ? cardDetails.dueDate.slice(0, 16) : ''}

              </div>
            </div>

          </div>
          <div className="column">
            <div className="detail-row">
              <UserIcon></UserIcon>
              <span className="description-label">XP</span>
              <div className="detail-text">
                {cardDetails.xp}
              </div>
            </div>
          </div>

        </div>
        <hr></hr>

        {/* DESCRIPTION */}
        <div className="description-subtasks-header">
          <DescriptionIcon></DescriptionIcon>
          <span className="description-label">Description</span>
        </div>
        <div className="detail-text marginRight">
          {/*{isDescriptionEditing ? (
            <input
              type="text"
              value={desc}
              onChange={(e) => setDesc(e.target.value)}

              autoFocus // autofocus on input field when it appears
            />
          ) : (
            <div onClick={handleDescriptionDoubleClick} style={{ cursor: 'pointer' }}>
              {desc}
            </div>
          )}*/}
          <RichTextEditor cardDetails={cardDetails} editable={false} />
        </div>
        {/*isDescriptionEditing ? <button onClick={handlesaveDesc}>Save</button> : <div></div>*/}
        <hr></hr>

        {/* SUB TASKS */}
        <div className="description-subtasks-header">
          <DescriptionIcon></DescriptionIcon>
          <span className="description-label">Sub-tasks</span>
        </div>
        <div className="detail-text marginRight">
          <form className="new-subtask-form">
            <table>
              <thead>
                <tr>
                  <th style={{ width: 40 }}></th>
                  <th style={{ width: '40%' }}>Description</th>
                  <th>Assignee</th>
                  <th>Status</th>
                  <th>Due</th>
                  <th></th>
                </tr>
              </thead>

              <tbody>
                {cardDetails.child.map((child, index) =>
                  <tr key={index}>
                    <td><TaskIcon></TaskIcon></td>
                    <td>{child.description}</td>
                    <td>{members.length > 0 ? (value => members.find(option => option.value === value).label) : 'No Assignee'}</td>
                    <td>
                      {child.tag}
                    </td>
                    <td>{new Date(child.dueDate).toLocaleDateString('en-GB', { day: 'numeric', month: 'long', year: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true })}</td>
                    <td></td>
                  </tr>)}

              </tbody>

            </table>
          </form>


        </div>
        <hr></hr>

        {/* COMMENTS */}
        <div className="description-subtasks-header">
          <ActivityIcon />
          <span className="description-label">Activity</span>
        </div>
        {cardDetails.checklist.map((checklist) => <Checkbox />)}
        <CommentsSection comment={cardDetails.comments} id={id} users={members} editable={false} />
        <hr></hr>


        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '50px' }}>
          <div>
            <button onClick={onClose}>Close</button>
            {/* <button onClick={handleSaveCard}>Save</button> */}
          </div>
        </div>
      </div>

    </div>
  );
};
export default ViewOnlySidebar;