import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import './DashboardSwitch.css';

const DashboardButtons = ({ onButtonClick }) => {
  const [activeButtonIndex, setActiveButtonIndex] = useState(null); // State to track active button
  const navigate = useNavigate(); // Hook to navigate
  const location = useLocation(); // Hook to get the current location

  const dashboards = [
    { color: '#1B7690', route: '/home' }, // Teal
    { color: '#A6D785', route: '/dashboard1' }, // Light Green
    // { color: '#FFCC80', route: '/dashboard2' }, // Peach
    // { color: '#CE93D8', route: '/dashboard3' }, // Purple
  ];

  // Set the active button based on the current route
  useEffect(() => {
    const activeIndex = dashboards.findIndex(btn => btn.route === location.pathname);
    setActiveButtonIndex(activeIndex !== -1 ? activeIndex : null);
  }, [location.pathname]); // Dependency on location.pathname to update when route changes

  const handleClick = (route, index) => {
    setActiveButtonIndex(index); // Update active button index
    
    navigate(route); // Navigate here after setting active index
  };

  return (
    <div className="dashboard-buttons">
      {dashboards.map((btn, index) => (
        <div
          key={index}
          className={`button ${activeButtonIndex === index ? 'active' : ''}`} // Apply active class
          style={{ backgroundColor: btn.color }}
          onClick={() => handleClick(btn.route, index)} // Handle button clicks
        />
      ))}
    </div>
  );
};

export default DashboardButtons;
