// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.message__container {
width: 100%;
height: 20vh;
background-color: #ffffff;
padding: 20px;
overflow-y: scroll;
}
.chat__footer {
padding: 10px;
background-color: #f9f5eb;
height: 10vh;
}
.form {
width: 100%;
height: 100%;
display: flex;
align-items: center;
justify-content: space-between;
}
.message {
width: 80%;
height: 100%;
border-radius: 10px;
border: 1px solid #ddd;
outline: none;
padding: 15px;
}
.sendBtn {
width: 150px;
background-color: green;
padding: 10px;
border: none;
outline: none;
color: #eae3d2;
cursor: pointer;
}
.sendBtn:hover {
background-color: rgb(129, 201, 129);
}
.message__recipient {
background-color: #f5ccc2;
width: 300px;
padding: 10px;
border-radius: 10px;
font-size: 15px;
}
.message__sender {
background-color: rgb(194, 243, 194);
max-width: 300px;
padding: 10px;
border-radius: 10px;
margin-left: auto;
font-size: 15px;
}
.message__chats > p {
font-size: 13px;
}
.sender__name {
text-align: right;
}
.message__status {
position: fixed;
bottom: 50px;
font-size: 13px;
font-style: italic;
}`, "",{"version":3,"sources":["webpack://./src/components/Community/ChatSection.css"],"names":[],"mappings":";AACA;AACA,WAAW;AACX,YAAY;AACZ,yBAAyB;AACzB,aAAa;AACb,kBAAkB;AAClB;AACA;AACA,aAAa;AACb,yBAAyB;AACzB,YAAY;AACZ;AACA;AACA,WAAW;AACX,YAAY;AACZ,aAAa;AACb,mBAAmB;AACnB,8BAA8B;AAC9B;AACA;AACA,UAAU;AACV,YAAY;AACZ,mBAAmB;AACnB,sBAAsB;AACtB,aAAa;AACb,aAAa;AACb;AACA;AACA,YAAY;AACZ,uBAAuB;AACvB,aAAa;AACb,YAAY;AACZ,aAAa;AACb,cAAc;AACd,eAAe;AACf;AACA;AACA,oCAAoC;AACpC;AACA;AACA,yBAAyB;AACzB,YAAY;AACZ,aAAa;AACb,mBAAmB;AACnB,eAAe;AACf;AACA;AACA,oCAAoC;AACpC,gBAAgB;AAChB,aAAa;AACb,mBAAmB;AACnB,iBAAiB;AACjB,eAAe;AACf;AACA;AACA,eAAe;AACf;AACA;AACA,iBAAiB;AACjB;AACA;AACA,eAAe;AACf,YAAY;AACZ,eAAe;AACf,kBAAkB;AAClB","sourcesContent":["\n.message__container {\nwidth: 100%;\nheight: 20vh;\nbackground-color: #ffffff;\npadding: 20px;\noverflow-y: scroll;\n}\n.chat__footer {\npadding: 10px;\nbackground-color: #f9f5eb;\nheight: 10vh;\n}\n.form {\nwidth: 100%;\nheight: 100%;\ndisplay: flex;\nalign-items: center;\njustify-content: space-between;\n}\n.message {\nwidth: 80%;\nheight: 100%;\nborder-radius: 10px;\nborder: 1px solid #ddd;\noutline: none;\npadding: 15px;\n}\n.sendBtn {\nwidth: 150px;\nbackground-color: green;\npadding: 10px;\nborder: none;\noutline: none;\ncolor: #eae3d2;\ncursor: pointer;\n}\n.sendBtn:hover {\nbackground-color: rgb(129, 201, 129);\n}\n.message__recipient {\nbackground-color: #f5ccc2;\nwidth: 300px;\npadding: 10px;\nborder-radius: 10px;\nfont-size: 15px;\n}\n.message__sender {\nbackground-color: rgb(194, 243, 194);\nmax-width: 300px;\npadding: 10px;\nborder-radius: 10px;\nmargin-left: auto;\nfont-size: 15px;\n}\n.message__chats > p {\nfont-size: 13px;\n}\n.sender__name {\ntext-align: right;\n}\n.message__status {\nposition: fixed;\nbottom: 50px;\nfont-size: 13px;\nfont-style: italic;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
