// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dashboard-buttons {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 20px;
  gap: 6px;
}

.button {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  cursor: pointer;
  transition: transform 0.3s, border 0.3s; /* Added border transition */
}

.button:hover {
  transform: scale(1.1);
}

/* Active button style */
.button.active {
  border: 3px solid; /* Default border style */
}

/* Add even lighter border colors based on button color */
.button.active:nth-child(1) {
  border-color: #4FD5C3; /* Lighter shade of teal */
}

.button.active:nth-child(2) {
  border-color: #C6E8B8; /* Lighter shade of light green */
}

.button.active:nth-child(3) {
  border-color: #FFE4B5; /* Lighter shade of peach */
}

.button.active:nth-child(4) {
  border-color: #E1B2E4; /* Lighter shade of purple */
}


/* Optional: add this to ensure hover effect works even when active */
.button.active:hover {
  transform: scale(1.1); /* Maintain hover effect */
}
`, "",{"version":3,"sources":["webpack://./src/components/Dashboard/DashboardSwitch.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,6BAA6B;EAC7B,mBAAmB;EACnB,aAAa;EACb,QAAQ;AACV;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,eAAe;EACf,uCAAuC,EAAE,4BAA4B;AACvE;;AAEA;EACE,qBAAqB;AACvB;;AAEA,wBAAwB;AACxB;EACE,iBAAiB,EAAE,yBAAyB;AAC9C;;AAEA,yDAAyD;AACzD;EACE,qBAAqB,EAAE,0BAA0B;AACnD;;AAEA;EACE,qBAAqB,EAAE,iCAAiC;AAC1D;;AAEA;EACE,qBAAqB,EAAE,2BAA2B;AACpD;;AAEA;EACE,qBAAqB,EAAE,4BAA4B;AACrD;;;AAGA,qEAAqE;AACrE;EACE,qBAAqB,EAAE,0BAA0B;AACnD","sourcesContent":[".dashboard-buttons {\n  display: flex;\n  justify-content: space-around;\n  align-items: center;\n  padding: 20px;\n  gap: 6px;\n}\n\n.button {\n  width: 35px;\n  height: 35px;\n  border-radius: 50%;\n  cursor: pointer;\n  transition: transform 0.3s, border 0.3s; /* Added border transition */\n}\n\n.button:hover {\n  transform: scale(1.1);\n}\n\n/* Active button style */\n.button.active {\n  border: 3px solid; /* Default border style */\n}\n\n/* Add even lighter border colors based on button color */\n.button.active:nth-child(1) {\n  border-color: #4FD5C3; /* Lighter shade of teal */\n}\n\n.button.active:nth-child(2) {\n  border-color: #C6E8B8; /* Lighter shade of light green */\n}\n\n.button.active:nth-child(3) {\n  border-color: #FFE4B5; /* Lighter shade of peach */\n}\n\n.button.active:nth-child(4) {\n  border-color: #E1B2E4; /* Lighter shade of purple */\n}\n\n\n/* Optional: add this to ensure hover effect works even when active */\n.button.active:hover {\n  transform: scale(1.1); /* Maintain hover effect */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
