
export default (node, linkMode, sourceNodeRef, targetNodeRef) => {
  const isLinkingSource = linkMode && sourceNodeRef.current === node;
  const isLinkingTarget = linkMode && targetNodeRef.current === node;
    return `
    <div class="node-body ${isLinkingSource ? 'link-source' : ''} ${isLinkingTarget ? 'link-target' : ''}">
      <div class="options">
        <div class="option add-item"><i class="fas fa-plus"></i></div>
        <div class="option edit-item"><i class="fa fa-regular fa-pen-to-square"></i></div>
        <div class="option remove-item"><i class="fa fa-regular fa-trash-can"></i></div>
        <div class="option link-item"><i class="fa-solid fa-neuter"></i></div>
      </div>
      <a id="node-${node.id}" class="node-text">${node.topic || ''}</a>
    </div>`;
  }