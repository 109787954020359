import React, { useState } from "react";
import { useDispatch } from 'react-redux';
import KanbanCard from './Card'
import "./Kanban.css"
import KanbanActionButton from "./KanbanActionButton";
import { Droppable, Draggable } from 'react-beautiful-dnd';
import { editListTitle } from '../../reducers/listReducer.js';
import styled from "styled-components";
import './List.css';
import { jwtDecode } from "jwt-decode";
import Cookies from 'js-cookie';
const StyledInput = styled.input`
    width: 100%;
    border: none;
    outline-color: blue;
    border-radius: 20px;
    margin-bottom: 3px;
    padding: 5px;
  `;
const List = ({ title, cards, listID, index, boardId, selectedmembers, selectedProject }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [listTitle, setListTitle] = useState(title);
  const dispatch = useDispatch();
  const token = Cookies.get('token');
  const decoded = jwtDecode(token);

  const renderEditInput = () => {
    return (
      <form onSubmit={handleFinishEditing}>
        <input
          type="text"
          className="list-title-edit"
          value={listTitle}
          onChange={handleChange}
          autoFocus
          onFocus={handleFocus}
          onBlur={handleFinishEditing}
        />
      </form>
    );
  };

  const handleFocus = e => {
    console.log("hi");

    e.target.select();
  };

  const handleChange = e => {
    e.preventDefault();
    setListTitle(e.target.value);
  };

  const handleFinishEditing = e => {
    setIsEditing(false);
    dispatch(editListTitle({ listID: listID, listTitle: listTitle }));
  };
  return (
    <Draggable draggableId={String(listID)} index={index}>
      {provided => (
        <div {...provided.dragableProps} ref={provided.innerRef} style={styles.container} {...provided.dragHandleProps}>
          <Droppable droppableId={listID}>
            {provided => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {isEditing ? (
                  renderEditInput()
                ) : (
                  <div className="list-title">
                    <h3 className="list_title" onClick={() => setIsEditing(true)}>{title}</h3>
                    <div className="list-length"></div>
                  </div>
                )}
                {cards.map((card, index) => {
                  // Check if any selected member is present in the card's assignee list
                  const hasSelectedMember = (selectedmembers.length === 0 || selectedmembers.some(member => card.assignee.includes(member))) && (selectedProject?selectedProject===card.project:true) ;
                  //  console.log("selection",hasSelectedMember,selectedProject,card.project)
                  // Render the card only if it matches the selected members
                  if (hasSelectedMember) {
                    return (
                      <KanbanCard
                        index={index}
                        listID={listID}
                        key={card._id}
                        text={card.title}
                        id={card._id}
                        card={card}
                      />
                    );
                  } else {
                    // Return null if the card doesn't match the selected members
                    return null;
                  }
                })}
                {decoded.role==='reporter'?<KanbanActionButton listID={listID} boardId={boardId} />:null}
                {provided.placeholder}
              </div>
            )}

          </Droppable>
        </div>)}
    </Draggable>
  )
}
const styles = {
  container: {
    // backgroundColor: "#eee",
    borderRadius: 40,
    width: 290,
    height: "100%",
    marginRight: 25
  }
}
export default List;