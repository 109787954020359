import { v4 as uuidv4 } from 'uuid';
import { connect, useSelector, useDispatch } from 'react-redux';
import ReactMarkdown from 'react-markdown';
import React, { useState, useRef, useEffect } from 'react';
import SendIcon from '../../Icons/Send_2.tsx';
import './Kanban.css';
import Checkbox from './Checkbox';
import './Checkbox.css';
import SelectionBar from '../SelectionBar.jsx';
import useAuth from '../../hooks/useAuth.jsx';
import List from './List.jsx';
import KanbanActionButton from './KanbanActionButton.jsx';
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { fetchBoard, DragHappened, moveCard } from '../../reducers/listReducer.js';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { backendURL } from "../../api/axios.js";
import { Icon, InputLabel } from "@mui/material";
import ProjectIcon from '../../Icons/Folder.tsx';
import BoardIcon from '../../Icons/Dashboard.tsx';
import FilterIcon from '../../Icons/Sort.tsx';
import { jwtDecode } from "jwt-decode";
import Cookies from 'js-cookie';
import GoalIcon from "../../Icons/Bullseye-pointer.tsx";
import AssigneesIcon from '../../Icons/Assignees.tsx';
import { Box, TextField, Select, MenuItem, IconButton } from '@mui/material';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import PropTypes from 'prop-types';
import CustomizedTables from './listView.jsx';
import SearchIcon from '@mui/icons-material/Search';
import DateRangeIcon from '@mui/icons-material/DateRange';


const styles = {
  listContainer: {
    display: "flex",
    overflow: "scroll",
    paddingTop: "20px"
  },
  dropDown: {
    backgroundColor: 'unset',
    height: 'max-content',
    outline: '1px solid #d0d1d280',
    borderRadius: 30,
    color: 'black',
    margin: '0px 10px',
    padding: '5px 20px',
    fontFamily: "Raleway",
    boxShadow: 'none'
  }
}
const KanbanComponent = ({ }) => {

  const token = Cookies.get('token');
  const decoded = jwtDecode(token);

  const { user } = useAuth();
  const { boardId } = useParams();
  const [selectedOptions, setSelectedOptions] = useState([]);

  const [projects, setProjects] = useState([]);
  const [options, setOptions] = useState([]);
  const [tabValue, setValue] = useState(0); // Track active tab
  const [selectedProject, setSelectedProject] = useState('');
  const [selectedProjectlsit, setSelectedProjectlist] = useState("All Projects");
  const [searchVisible, setSearchVisible] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [dateFilter, setDateFilter] = useState('All Dates');
  const [customDateRange, setCustomDateRange] = useState([null, null]);
  const dispatch = useDispatch();
  const lists = useSelector(state => state.list);
  const [data, setData] = useState(null); // Initialize data state

  const handleDateFilterChange = (e) => {
    setDateFilter(e.target.value);
    // Reset custom date range if changing to a non-custom filter
    if (e.target.value !== 'Custom Date') {
      setCustomDateRange([null, null]);
    }
  };


  useEffect(() => {
    const storedOptions = localStorage.getItem('selectedMembers' + boardId);
    if (storedOptions) {
      setSelectedOptions(JSON.parse(storedOptions));
    }
    const storedProject = localStorage.getItem('selectedProject' + boardId);
    if (storedOptions) {
      setSelectedProject(storedProject);
    }
  }, []);

  useEffect(() => {
    fetch(backendURL + "/boards/" + boardId + "/projects")
      .then(response => response.json())
      .then(data => {
        setProjects(data.map(item => item));
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }, []);

  useEffect(() => {
    dispatch(fetchBoard(boardId));
  }, [dispatch]);
  const onDragEnd = result => {
    console.log('drag result', result)
    const { destination, source, draggableId, type } = result;

    if (!destination) {
      return;
    }
    console.log("before dispatch",
      source.droppableId,
      destination.droppableId,
      source.index,
      destination.index,
      draggableId,
      type
    );

    dispatch(
      DragHappened({
        droppableIdStart: source.droppableId,
        droppableIdEnd: destination.droppableId,
        droppableIndexStart: source.index,
        droppableIndexEnd: destination.index,
        draggableId: draggableId,
        type: type
      }
      )
    );
    dispatch(
      moveCard({
        droppableIdStart: source.droppableId,
        droppableIdEnd: destination.droppableId,
        droppableIndexStart: source.index,
        droppableIndexEnd: destination.index,
        draggableId: draggableId,
        type: type
      }
      )
    );
  }

  useEffect(() => {
    fetch(backendURL + '/boards/access/' + boardId)
      .then(response => response.json())
      .then(data => {
        setOptions(data.map(item => ({ value: item.id, label: item.name, picture: item.profilePicture })));
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }, []);
  const handleChange = (event, newValue) => {
    const { name, value } = event.target;
    setValue(newValue); // Update active tab

    if (name === "assignee") {
      localStorage.setItem('selectedMembers' + boardId, JSON.stringify(value));
      setSelectedOptions(value);
    } else {
      localStorage.setItem('selectedProject' + boardId, value);
      setSelectedProject(value);
    }
  };

  function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
      </div>
    );
  }

  CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };
  const groupedData = (data || []).reduce((acc, item) => {
    acc[item.project] = acc[item.project] || [];
    acc[item.project].push(item);
    return acc;
  }, {}); // Using {} directly as the initial value


  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  return (
    <div className='kanban-wrapper'>
      <Box
        sx={{
          borderBottom: 1,
          borderColor: 'divider',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
      >
        {/* Tabs Section */}
        <Tabs
          value={tabValue}
          onChange={(event, newValue) => setValue(newValue)}
          sx={{
            '& .MuiTab-root': { color: 'gray', fontFamily: 'Raleway, sans-serif' },
            '& .MuiTab-root.Mui-selected': { color: 'black', fontFamily: 'Raleway, sans-serif' },
            '& .MuiTabs-indicator': { backgroundColor: 'black' },
          }}
        >
          <Tab icon={<GoalIcon />} iconPosition="start" label="Projects" {...a11yProps(0)} />
          <Tab icon={<GoalIcon />} iconPosition="start" label="Boards" {...a11yProps(1)} />
        </Tabs>

        {/* Filters Section */}
        {tabValue === 1 && (
          <div className='filters' style={{ display: 'flex', alignItems: 'center' }}>
            <AssigneesIcon />
            <Select
              style={styles.dropDown}
              labelId="multi-select-label"
              id="multi-select"
              name="assignee"
              multiple
              value={selectedOptions}
              onChange={handleChange}
              label="Assignees"
              renderValue={(selected) =>
                options.length > 0
                  ? selected.map(value => {
                    const option = options.find(option => option.value === value);
                    return option ? option.label : 'Unknown';
                  }).join(', ')
                  : ''
              }
            >
              {options.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>

            <Select
              style={styles.dropDown}
              labelId="multi-select-label"
              id="multi-select"
              name="project"
              value={selectedProject}
              label="Project"
              onChange={handleChange}
              displayEmpty
            >
              <MenuItem value="">Project</MenuItem>
              {projects.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          </div>
        )}

        {tabValue === 0 && (
          <div className='filters' style={{ display: 'flex', alignItems: 'center' }}>
            {/* Different filters for tabValue === 1 */}
            <Box sx={{ display: 'flex', alignItems: 'center', marginRight: '10px' }}> {/* Added gap between elements */}
              <DateRangeIcon />

              <Select
                labelId="date-filter-label"
                value={dateFilter}
                onChange={handleDateFilterChange}
                label="Date Filter"
                sx={{
                  borderRadius: '20px',
                  height: '36px',
                  minWidth: '120px', // Optional: Ensure the select has a minimum width
                  marginRight: '10px'
                }}
              >
                <MenuItem value="All Dates">All Dates</MenuItem>
                <MenuItem value="Today">Today</MenuItem>
                <MenuItem value="This Week">This Week</MenuItem>
                <MenuItem value="This Month">This Month</MenuItem>
                <MenuItem value="Custom Date">Custom Date</MenuItem>
              </Select>

              {dateFilter === 'Custom Date' && (
                <div style={{ display: 'flex', gap: '10px', alignItems: 'center', marginRight: '10px' }}>
                  <input
                    type="date"
                    value={customDateRange[0] || ''}
                    onChange={(e) => setCustomDateRange([e.target.value, customDateRange[1]])}
                    style={{
                      background: '#DBC389',
                      padding: '4px',
                      borderRadius: '20px',
                      height: '36px',
                      border: '1px solid #ccc',
                      flex: '1',
                    }}
                  />
                  <input
                    type="date"
                    value={customDateRange[1] || ''}
                    onChange={(e) => setCustomDateRange([customDateRange[0], e.target.value])}
                    style={{
                      background: '#DBC389',
                      padding: '4px',
                      borderRadius: '20px',
                      height: '36px',
                      border: '1px solid #ccc',
                      flex: '1',
                    }}
                  />
                </div>
              )}
            </Box>

            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <GoalIcon sx={{ marginRight: '8px' }} />
              <Select
                value={selectedProject}
                onChange={(e) => setSelectedProject(e.target.value)}
                displayEmpty
                sx={{ marginRight: '10px', fontSize: '0.875rem', height: '36px', borderRadius: '20px', '& .MuiSelect-select': { padding: '0 10px' } }}
              >
                <MenuItem value="All Projects">All Projects</MenuItem>
                {Object.keys(groupedData).map((project) => (
                  <MenuItem key={project} value={project}>{project}</MenuItem>
                ))}
              </Select>
            </Box>
            <IconButton onClick={() => setSearchVisible(!searchVisible)}>
              <SearchIcon />
            </IconButton>
            {searchVisible && (
              <TextField
                placeholder="Search by status or assignee"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                variant="outlined"
                size="small"
                sx={{ width: '30%', marginLeft: '5px', '& .MuiOutlinedInput-root': { borderRadius: '20px' } }}
              />
            )}
          </div>
        )}

      </Box>

      <CustomTabPanel value={tabValue} index={0}>
        {lists.map((list) => (
          <CustomizedTables
            selectedProjectlist={selectedProjectlsit}

            searchTerm={searchTerm}
            dateFilter={dateFilter}
            key={list._id}
            card={list.cards}
            listID={list._id}
          />
        ))}
      </CustomTabPanel>

      <CustomTabPanel value={tabValue} index={1}>
        <div className='list-wrapper'>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="test" direction="horizontal" type="list">
              {provided => (
                <div style={styles.listContainer} {...provided.droppableProps} ref={provided.innerRef}>
                  {lists.map((list, index) => (
                    <List
                      listID={list._id}
                      key={list._id}
                      title={list.title}
                      cards={list.cards}
                      index={index}
                      boardId={boardId}
                      selectedmembers={selectedOptions}
                      selectedProject={selectedProject}
                    />
                  ))}
                  {provided.placeholder}
                  <KanbanActionButton list boardId={boardId} />
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div>
      </CustomTabPanel>
    </div>
  );
};

export default KanbanComponent;
