import React, { useEffect, useRef, useState } from 'react';
import * as d3 from 'd3';
import { mlend } from '../../api/axios';
import './Mindmaps.css';
import { getDimensions } from './dimensions';
import { d3Connections, d3Nodes, onTick, d3Drag, d3PanZoom, d3NodeClick } from './d3';
import nodeToHTML from './nodeToHTML';
import { v4 as uuidv4 } from 'uuid';
import Sidebar from './SideBar_content';
import useAuth from '../../hooks/useAuth';

const MindMapComponent = ({ data }) => {
  const svgRef = useRef(null);
  const {user} = useAuth();
  const [mindmap, setMindmap] = useState(null);
  const [selectedNode, setSelectedNode] = useState(null);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [projectName,setProjectName] = useState('');

  const saveMindmap = async () => {
    try {
      const nodesData = mindmap.nodes.map(node => ({
        id: node.id,
        x: node.x,
        y: node.y,
        fx: node.fx,
        fy: node.fy,
        topic:node.topic,
        content:node.content,
        height: node.height,
        width:node.width
      }));
  
      const linksData = mindmap.connections.map(link => ({
        source: link.source.id, 
        target: link.target.id,
        // ... any other essential link properties
      }));
  
      const mindmapData = {
        nodes: nodesData,
        links: linksData,
        user_id: user
      };
  
      await mlend.post('update-user-mindmap', mindmapData);
      console.log('Mindmap successfully saved');
    } catch (error) {
      console.error('Error saving mindmap:', error);
    }
  };

  
  const handleSaveNodeChanges = (editedNodeData) => {
    mindmap.updateSingleNodeContent(editedNodeData);

    const updatedNodes = mindmap.nodes.map(node => node.id === editedNodeData.id ? editedNodeData : node);

    console.log("Updated Nodes: ",updatedNodes);

    mindmap.nodes = mindmap.nodes.map(node => {
      const updatedNode = updatedNodes.find(uNode => uNode.id === node.id);
      return updatedNode || node;
    });

    // mindmap.renderMap();
    const linksData = mindmap.connections.map(link => ({
      source: link.source.id, 
      target: link.target.id,
      // Add other essential link properties here
    }));

    const mindmapData = {
      nodes: updatedNodes,
      links: linksData,
      user_id:user
    };
    // Send an Axios POST request to update the user's mindmap
    mlend.post('update-user-mindmap', mindmapData)
      .then(response => {
        console.log('Mindmap data updated:', response.data);
      })
      .catch(error => {
        console.error('Error updating mindmap data:', error);
      });
    
    mindmap.connections = linksData;
    mindmap.renderMap()
  };

  useEffect(() => {
    const svgEl = svgRef.current;

    const fetchData = async () => {
      try {
        console.log("Going to fetch mindmap from database, try 1",user);
        const response = await mlend.get('get-user-mindmap', {
          params: { 'user_id': user }
        });
        const data = response.data['mindmap'];
        const project_name = response.data['project_name'];
        console.log("Project: ",project_name);
        setProjectName(project_name)

        const mindmap = new MindMap(svgEl, data, setSelectedNode, setIsSidebarOpen);
        setMindmap(mindmap);
        
        mindmap.renderMap();
        
      } catch (error) {
        console.error("Error fetching mindmap data:", error);
      }
    };

    fetchData();
  }, []);


  return (
    <div style={{width: 100 + '%'}}>
      
      <div className='mindmap-project'>
        <h3 className='mindmap-project-name'>{projectName}</h3>
        <button className="mindmap-project-save" onClick={saveMindmap}>Save</button>
      </div>
      <div className='mindmap-container'>
        <svg ref={svgRef} className='mindmap-svg' onClick={e => e.stopPropagation()}></svg>
        <Sidebar isOpen={isSidebarOpen} selectedNode={selectedNode} setIsOpen={setIsSidebarOpen} onSaveNodeChanges={handleSaveNodeChanges} />
      </div>
    </div>
  );
};

class MindMap {
  constructor(svgEl, data, setSelectedNode, setIsSidebarOpen) {
    this.nodes = data.nodes;
    this.connections = data.links;
    this.svg = svgEl;
    this.simulation = null;
    this.setSelectedNode = setSelectedNode;
    this.setIsSidebarOpen = setIsSidebarOpen;

    this.linkMode = false;
    this.sourceNode = null; // The source node for link creation
    this.targetNode = null; // The target node for link creation
    this.selectedLink = null; // for selected deletion
    this.pressTimer = null; //for longpressed deletion
    this.pressedLink = null;//for longpressed deletion

    this.minimumNodeHeight = 100;
    this.minimumNodeWidth = 340;

    // Create refs for source and target nodes
    this.sourceNodeRef = React.createRef();
    this.targetNodeRef = React.createRef();
  }

  prepareNodes() {
    const render = node => {
      node.html = nodeToHTML(node,this.linkNode,this.sourceNodeRef,this.targetNodeRef);
      
      const dimensions = getDimensions(node.html, {}, "mindmap-node");
      if (node.width && node.height){}
      else{
      // Enforce the minimum dimensions
    node.width = Math.min(dimensions.width, this.minimumNodeWidth);
    node.height = Math.max(dimensions.height, this.minimumNodeHeight);
      }
    };

    this.nodes.forEach(node => render(node));
  }

  async updateMindmapData() {
    try {
      const nodesData = this.nodes.map(node => ({
        id: node.id,
        x: node.x,
        y: node.y,
        fx: node.fx,
        fy: node.fy,
        topic:node.topic,
        content:node.content,
        height: node.height,
        width:node.width
        // Add other essential node properties here
      }));

      const linksData = this.connections.map(link => ({
        source: link.source.id, 
        target: link.target.id,
        // Add other essential link properties here
      }));

      const user = localStorage.getItem('user_id')
      const mindmapData = {
        nodes: nodesData,
        links: linksData,
        user_id:user
      };

      console.log("Mindmap data for update: ",mindmapData);
      const response = await mlend.post('update-user-mindmap', mindmapData, {
        headers: {
          'accept': 'application/json' ,
        },
      });

      console.log('Mindmap data updated:', response.data);
    } catch (error) {
      console.error('Error updating mindmap data:', error);
    }
  }


  prepareEditor(svg, conns, nodes) {
    nodes
      .attr("class", "mindmap-node mindmap-node--editable")
      .attr("id", d => d.id)
      .on("dblclick", node => {
        node.fx = null;
        node.fy = null;
      });

    svg.selectAll(".mindmap-connection")
    // .on('click',(event,link)=>{
    //   this.deleteLink(event,link);
    // })
    .on('dblclick', (event,link) => {
      this.deleteLink(event,link);
      event.preventDefault();
    })
  

    nodes.call(d3Drag(this.simulation, svg, nodes));
    nodes.on("click", (d, i) => {
      this.nodeClickEvent(d3NodeClick(d, i), i);
    });

    for (let i = 0; i < 100; i += 1) {
      this.simulation.tick();
    }

    setTimeout(() => {
      this.simulation.alphaTarget(0.5).on("tick", () => onTick(conns, nodes));
    }, 200);
  }

  renderMap() {
    this.simulation = d3.forceSimulation()
      .force("link", d3.forceLink().id(node => node.id))
      .force("charge", d3.forceManyBody())

    const svg = d3.select(this.svg);
    svg.selectAll("*").remove();
    svg.append("g").attr("id", "mindmap-subnodes");

    this.prepareNodes();

    const connections = d3Connections(svg, this.connections);
    connections.on("click", (event, link) => {
      console.log("Selected link:", link);
  });
    
    const { nodes } = d3Nodes(svg, this.nodes);

    this.simulation
      .nodes(this.nodes)
      .force("link")
      .links(this.connections, link => link.source.id, link => link.target.id);

    this.prepareEditor(svg, connections, nodes);

    for (let i = 0; i < 100; i += 1) {
      this.simulation.tick();
    }

    onTick(connections, nodes);

    svg
      .style("width", "100%") // Set SVG width to 100% of its container
      .style("height", "100%")
      .call(d3PanZoom(svg))
      .on("dblclick.zoom", null);

  document.addEventListener('mouseup', this.handleMouseUp);

        // Check if there are no nodes and links
  if (this.nodes.length === 0 && this.connections.length === 0) {
    console.log("No nodes and links");
    // Create a plus sign circular button in the middle of the screen
    const plusButton = svg
      .append("g")
      .attr("class", "plus-button")
      .attr("transform", `translate(${this.svg.clientWidth / 2}, ${this.svg.clientHeight / 2})`)
      .on("click", () => this.addEmptyNode());

    plusButton
      .append("circle")
      .attr("r", 20)
      .attr("fill", "white")
      .attr("cursor", "pointer");

    plusButton
      .append("text")
      .text("+")
      .attr("x", -4.5)
      .attr("y", 9)
      .attr("font-size", "33px")
      .attr("cursor", "pointer");

    // Hover text
    plusButton
    .append("text")
    .text("Add Node")
    .attr("x", 30) // Adjust the position as needed
    .attr("y", 5)
    .attr("font-size", "16px")
    .attr("font-weight", "normal") // Reset to normal weight
    .style("opacity", 0) // Initially hide the text
    .attr("cursor", "pointer");

    // Hover behavior
    plusButton
    .on("mouseover", function () {
      // Show the hover text on mouseover
      d3.select(this).select("text:last-child").style("opacity", 1);
    })
    .on("mouseout", function () {
      // Hide the hover text on mouseout
      d3.select(this).select("text:last-child").style("opacity", 0);
    });

    
  }
  }


  nodeClickEvent(event, node) {
    switch (event) {
      case "add":
        this.addNewNode(node);
        break;
      case "edit":
        this.editNode(node);
        break;
      case "remove":
        this.removeNode(node);
        break;
      case "click":
        this.clickNode(node);
        break;
      case "link":
        this.linkNode(event,node);
        break;
      default:
        break;
    }
  }

  updateSingleNodeContent(editedNodeData) {
    // Select the node by its ID
    const nodeSelection = d3.select(this.svg).select(`#node-${editedNodeData.id}`);
  
    // Update the node's topic
    nodeSelection.select('a.node-text').text(editedNodeData.topic);
  
    // If you want to update the node's content in the visualization too (assuming it's displayed),
    // add a similar line here. Adjust the selector to point to the correct element that displays the content.
    // Example:
    // nodeSelection.select('someSelectorForContent').text(editedNodeData.content);
  
    // If there are other visual attributes you want to update (like width, height, etc.), update them here as well.
  }
  

  async linkNode(event,d){
    console.log("node: ",d);
    console.log("event: ",event);
    
    
    if (!this.linkMode){
      this.sourceNode=d;
      this.sourceNodeRef.current = d; 
      console.log("Link Mode: ",this.linkMode);
      console.log("Source Node: ",this.sourceNode);
      console.log("this.sourceNodeRef.current", this.sourceNodeRef.current);
      this.linkMode = true;
    }
    else if(this.linkMode && this.sourceNode){
      console.log("Link Mode: ",this.linkMode);
      this.targetNode = d;
      this.targetNodeRef.current = d;
      
      const newLink = {
        source:this.sourceNode.id,
        target:this.targetNode.id
      };
      
      console.log("New Link: ",newLink);
      this.connections.push(newLink);
      
      console.log("Target Node: ",this.targetNode);
      console.log("this.targetNodeRef.current", this.targetNodeRef.current);

         // Reset link-related state variables
         this.sourceNode = null;
         this.targetNode = null;
         this.sourceNodeRef.current = null;
         this.targetNodeRef.current = null;
   
         // Exit link creation mode
         this.linkMode = false;
         
         this.renderMap();
       await this.updateMindmapData();
    }
    else if(this.linkMode && !this.sourceNode){
      this.linkMode = false;
      console.log("Exiting the link Mode");
    }

  }

  enforceMinimumDimensions(node) {
    const dimensions = getDimensions(node.html, {}, "mindmap-node");
    node.width = Math.max(dimensions.width, this.minimumNodeWidth);
    node.height = Math.max(dimensions.height, this.minimumNodeHeight);
  }
  

  clickNode(d) {
    this.setSelectedNode(d);
    this.setIsSidebarOpen(true);
  }

  deleteLink(event,link) {
    console.log("In the deletelink function: ",link);
    // Filter out the link to be deleted
    this.connections = this.connections.filter(conn => conn !== link);
    this.renderMap();
    this.updateMindmapData(); // Update the data in your database
  }
  
  async addNewNode(target) {
    const nodeId = uuidv4();
    this.nodes.push({
      id: nodeId,
      topic: "new-node",
      content: "nothing",
      fx: target.fx,
      fy: target.fy + 200
    });
    this.connections.push({
      source: target.id,
      target: nodeId
    });
    this.renderMap();
    await this.updateMindmapData();
  }

  async removeNode(d) {
    this.nodes = this.nodes.filter(node => node.id !== d.id);
    this.connections = this.connections.filter(conn => conn.source.id !== d.id && conn.target.id !== d.id);
    this.renderMap();
    await this.updateMindmapData();
  }

  async editNode(d) {
    this.setSelectedNode(d);
    this.setIsSidebarOpen(true);
  }

  async addEmptyNode() {
  const nodeId = uuidv4();
  this.nodes.push({
    id: nodeId,
    topic: "new-node",
    content: "nothing",
    fx: this.svg.clientWidth / 2, // X-coordinate in the center
    fy: this.svg.clientHeight / 2 // Y-coordinate in the center

  });
  this.renderMap();
  await this.updateMindmapData();
}
}


export default MindMapComponent;
