import React, { useState } from 'react';
import { Grid, Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useNavigate } from 'react-router-dom';
import { updatedStatus, fetchSameOrgUsers, backendURL } from '../../api/axios.js'; // Import API functions
import Cookies from 'js-cookie';
const StyledBox = styled(Box)(({ theme }) => ({
    backgroundColor: '#fff',
    borderRadius: '35px',
    padding: theme.spacing(2),
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)', // Optional: Add shadow for aesthetics
    height: '80vh', // Adjust height to 80% of the viewport height
    display: 'flex', // Enable flexbox layout
    flexDirection: 'column', // Stack children vertically
    justifyContent: 'space-between', // Space between children, pushing the button to the bottom

}));

const TaskCheckInUpdate = () => {
    const [value, setValue] = useState('');
    const userID = localStorage.getItem('user_id'); // Get user ID from local storage
    const navigate = useNavigate(); // Initialize the navigate function

    const handleTaskUpdate = async () => {
        const token = Cookies.get('token');
        const taskData = { taskUpdateText: value }; // Set taskUpdateText with ReactQuill content

        try {
            const response = await fetch(`${backendURL}/userSession/task-update`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`, // Add token to headers
                },
                body: JSON.stringify(taskData), // Send taskData in the body
            });

            if (response.ok) {
                console.log("Task updated successfully!");
                navigate('/home');
                const { userId, sessionId } = await response.json(); // Assume backend returns sessionId of the check-in

                const logData = {
                    userId,
                    sessionId: sessionId,
                    taskUpdateText: value, // Add the task update text
                };

                // Now call the Google Sheets service to append the task update
                const sheetResponse = await fetch(`${backendURL}/userActivity/log`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    },
                    body: JSON.stringify(logData),
                });

                if (!sheetResponse.ok) {
                    console.error("Failed to update task in Google Sheets.");
                }
                
            } else {
                alert("Failed to update task.");
            }
        } catch (error) {
            console.error("Error updating task:", error);
        }
    };
    
    return (
        <Grid container justifyContent="center" alignItems="center" style={{ minHeight: '90vh' }}>
            <Grid item xs={10} sm={8} md={8}>
                <StyledBox>
                    <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                        {/* Header Section */}
                        <Box
                            sx={{
                                width: '100%',
                                fontFamily: 'Montserrat',
                                textAlign: 'center',
                                fontSize: '32px',
                                fontWeight: 'bold',
                                borderBottom: '1px solid #ccc',
                                marginBottom: '10px',
                            }}
                        >
                            Check-In Update
                        </Box>

                        {/* ReactQuill Editor Section */}
                        <div style={{ flexGrow: 1, overflow: 'hidden' }}>
                            <ReactQuill
                                theme="snow"
                                value={value}
                                onChange={setValue}
                                style={{
                                    height: '59vh', // Set height for the editor
                                    marginTop: '10px',
                                }}
                                modules={{
                                    toolbar: [
                                        [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
                                        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                                        ['bold', 'italic', 'underline'],
                                        ['link', 'image'],
                                        ['clean'] // Remove formatting button
                                    ],
                                }}

                            />
                        </div>
                    </div>

                    {/* Submit Button Section */}
                    <button
                        onClick={handleTaskUpdate}
                        
                        style={{
                            width: '100%', // Full width for the button
                            padding: '10px',
                            fontFamily: 'Raleway',
                            fontSize: '16px',
                            textAlign: 'center',
                            margin: '20px 0 0', // Center the button and add margin on top
                            border: 'none', // Remove default border
                            backgroundColor: '#1b7690', // Set background color for visibility
                            color: '#fff', // Set text color
                            cursor: 'pointer', // Change cursor on hover
                            borderRadius: '15px', // Add border radius for aesthetics
                        }}
                    >
                        Submit
                    </button>
                </StyledBox>
            </Grid>
        </Grid>

    );
};

export default TaskCheckInUpdate;
