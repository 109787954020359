// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.project-details {
    height: 30px;
    /* border-bottom: 1px solid #d0d1d280; */
    /* margin-bottom: 20px; */
}

.project-details h2 {
    padding-bottom: unset;
    border-bottom: unset;
    margin-top: unset;
    margin-left: 5px;
}

.project-heading {
    display: flex;
}

.back-icon {
    height: 30px;
    width: 30px;
    margin: auto;
}

.back-icon svg {
    margin: auto;
    color: #fff;
    position: relative;
    margin: 3px;
}

.project-files-list {
    list-style: none;
    padding: 0;
    font-size: 14px;
}

.project-files-list li {
    display: flex;
    justify-content: space-between;
}

.filename {
    display: flex;
    align-items: center;
}

.filename svg { margin-right: 15px;}`, "",{"version":3,"sources":["webpack://./src/components/Projects/ProjectDetailPage/ProjectDetailPage.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,wCAAwC;IACxC,yBAAyB;AAC7B;;AAEA;IACI,qBAAqB;IACrB,oBAAoB;IACpB,iBAAiB;IACjB,gBAAgB;AACpB;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,YAAY;IACZ,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,YAAY;IACZ,WAAW;IACX,kBAAkB;IAClB,WAAW;AACf;;AAEA;IACI,gBAAgB;IAChB,UAAU;IACV,eAAe;AACnB;;AAEA;IACI,aAAa;IACb,8BAA8B;AAClC;;AAEA;IACI,aAAa;IACb,mBAAmB;AACvB;;AAEA,gBAAgB,kBAAkB,CAAC","sourcesContent":[".project-details {\n    height: 30px;\n    /* border-bottom: 1px solid #d0d1d280; */\n    /* margin-bottom: 20px; */\n}\n\n.project-details h2 {\n    padding-bottom: unset;\n    border-bottom: unset;\n    margin-top: unset;\n    margin-left: 5px;\n}\n\n.project-heading {\n    display: flex;\n}\n\n.back-icon {\n    height: 30px;\n    width: 30px;\n    margin: auto;\n}\n\n.back-icon svg {\n    margin: auto;\n    color: #fff;\n    position: relative;\n    margin: 3px;\n}\n\n.project-files-list {\n    list-style: none;\n    padding: 0;\n    font-size: 14px;\n}\n\n.project-files-list li {\n    display: flex;\n    justify-content: space-between;\n}\n\n.filename {\n    display: flex;\n    align-items: center;\n}\n\n.filename svg { margin-right: 15px;}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
