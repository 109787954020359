import React, { useState } from 'react';
import useAuth  from '../../hooks/useAuth';
import { useNavigate } from 'react-router-dom';
import { backend } from '../../api/axios';

import './Auth.css';
import girl from '../../Icons/girl.svg';
import map from '../../Icons/map.svg';
import logo from '../../Icons/cropped-logo.png';

const AuthPage = () => {
  const { user, login, logout } = useAuth();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    username: '',
    password: '',
  });

  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
        const response = await backend.post('/auth/login', formData);
        if (response.data.token){
          console.log("Response: ",response.data);
          console.log("R2",user,login,logout);
          await login(formData.username,response.data.token,response.data.refreshToken);
          navigate('/home'); //ROUTING
        }
        else {
          setSuccessMessage('');

          if (!response){
            setErrorMessage('No Server Response');}
          else if (response.data.status === 400){
            setErrorMessage(response.data.message);}
          else if (response.data.status === 401){
            setErrorMessage('Unauthorized');}
          else if (response.data.status === 201){
            setErrorMessage(response.data.message);}
        }

      }catch (error) {
      console.error('Authentication failed:', error);
    }
  };

  

  // const handleLogout = () => {
  //   logout();
  //   navigate('/auth');
  // };
 
  // const toggleForm = () => {
  //   // Toggle between login and signup forms
  //   setIsLogin(!isLogin);
  //   setFormData({ username: '', password: '' }); // Clear form fields
  //   setErrorMessage('');
  //   setSuccessMessage(''); 
  // };

  return (
    <div className='login-page'>
      <div className='graphic-container'>
        <img className='map' src={map} alt="map"></img>
      </div>
      
      <div className='login-form-container'>
      <img className='girl' src={girl} alt="girl"></img>
        <img className="logo-login" src={logo} alt="logo"></img>
        <h2 className='login-text'>{'Login'}</h2>
        {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>} {/* Display error message */}
        {successMessage && <p style={{ color: 'green' }}>{successMessage}</p>} {/* Display success message */}
        <form onSubmit={handleSubmit}>
          <div>
            {/* <label>Username:</label> */}
            <input style={{color: 'white'}} type="text" placeholder='Username' name="username" value={formData.username} onChange={handleInputChange} required />
          </div>
          <div>
            {/* <label>Password:</label> */}
            <input style={{color: 'white'}} type="password" placeholder='Password' name="password" value={formData.password} onChange={handleInputChange} required />
          </div>
          <button className='upload-button' type="submit">{'Login'}</button>
        </form>
        {/* {user && <button onClick={handleLogout}>Logout</button>} */}
        <p style={{ 'font-size': 13 +'px' }}>
          
        </p>
      </div>
    </div>
  );
};
export default AuthPage

