// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Custom checkbox styling */
.checkbox-container {
  padding: 0;
  display: flex;
  cursor: pointer;
  align-items: center;
  align-self: center;
  position: relative;
  justify-content: center;
  margin-right: 20px;
}

.checkbox-container input {
  display: none; /* Hide the input element */
}

.checkbox-container input:checked + svg{
  stroke: #fff; /* Change the color when checked */
  fill:#EDB22F;
}

.checkbox-container svg {
  fill: none;
  stroke: #d0d1d280;
  stroke-width: 1;
  width: 40px; /* Set the width and height to the desired size */
  height: 40px; /* Set the width and height to the desired size */
  transition: stroke 0.3s ease-in-out;
}
`, "",{"version":3,"sources":["webpack://./src/components/Chat/Checkbox.css"],"names":[],"mappings":"AAAA,4BAA4B;AAC5B;EACE,UAAU;EACV,aAAa;EACb,eAAe;EACf,mBAAmB;EACnB,kBAAkB;EAClB,kBAAkB;EAClB,uBAAuB;EACvB,kBAAkB;AACpB;;AAEA;EACE,aAAa,EAAE,2BAA2B;AAC5C;;AAEA;EACE,YAAY,EAAE,kCAAkC;EAChD,YAAY;AACd;;AAEA;EACE,UAAU;EACV,iBAAiB;EACjB,eAAe;EACf,WAAW,EAAE,iDAAiD;EAC9D,YAAY,EAAE,iDAAiD;EAC/D,mCAAmC;AACrC","sourcesContent":["/* Custom checkbox styling */\n.checkbox-container {\n  padding: 0;\n  display: flex;\n  cursor: pointer;\n  align-items: center;\n  align-self: center;\n  position: relative;\n  justify-content: center;\n  margin-right: 20px;\n}\n\n.checkbox-container input {\n  display: none; /* Hide the input element */\n}\n\n.checkbox-container input:checked + svg{\n  stroke: #fff; /* Change the color when checked */\n  fill:#EDB22F;\n}\n\n.checkbox-container svg {\n  fill: none;\n  stroke: #d0d1d280;\n  stroke-width: 1;\n  width: 40px; /* Set the width and height to the desired size */\n  height: 40px; /* Set the width and height to the desired size */\n  transition: stroke 0.3s ease-in-out;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
