import React, { useState } from 'react';
import './Comment.css'; // Import CSS file for styling
import { connect, useSelector, useDispatch } from 'react-redux';
import { addComment } from '../../reducers/listReducer';
import UserIcon from '../../Icons/User.tsx';
import SendIcon from '../../Icons/Send_2.tsx';
import { EditorState, convertToRaw, convertFromRaw, ContentState, ContentBlock, CharacterMetadata} from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';

function Comment({ comment, user, timestamp }) {
  let contentState;
  try {
    contentState = convertFromRaw(JSON.parse(comment));
  }
  catch {

    contentState = ContentState.createFromText(comment);
  }
  const editorState = EditorState.createWithContent(contentState);

  return (
    <div className="comment">
      <div className="comment-header">
        <p className="comment-author"><UserIcon></UserIcon>{user}</p>
        <p className="comment-time">{new Date(timestamp).toLocaleDateString('en-GB', { day: 'numeric', month: 'long', year: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true })}</p>
      </div>
      <p className="comment-text">
        <Editor toolbar={
            {
              options: [],
              inline: {
                options: [],
              }
            } }
            editorState={editorState} readOnly={true} /></p>
    </div>
  );
}
function CommentForm({ onCommentSubmit, userId, id, users }) {
  const [text, setText] = useState('');
  const [author, setAuthor] = useState(userId);

  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const dispatch = useDispatch();
  
  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   console.log("sub", text, author, users);
    
  //   const contentState = editorState.getCurrentContent();
  //   if (!contentState.hasText() || !author.trim()) return;
  //   const rawContentState = convertToRaw(contentState);
    
  //   onCommentSubmit({ comment: JSON.stringify(rawContentState), userId: author, id: id })//{ comment:text, user:users.find(option => option.value === author).label, timestamp: new Date()});
  //   dispatch(addComment({ comment: JSON.stringify(rawContentState), userId: author, id: id }))
  //   setText('');
  //   setEditorState(EditorState.createEmpty())
  // };

  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   console.log("sub", text, author, users);
  
  //   const contentState = editorState.getCurrentContent();
  //   if (!contentState.hasText() || !author.trim()) return;
  
  //   const rawContentState = convertToRaw(contentState);
  //   const timestamp = new Date().toISOString(); // Create a valid ISO string
  
  //   onCommentSubmit({
  //     comment: JSON.stringify(rawContentState),
  //     userId: author,
  //     id: id,
  //     timestamp: timestamp // Include timestamp if needed
  //   });
  
  //   dispatch(addComment({
  //     comment: JSON.stringify(rawContentState),
  //     userId: author,
  //     id: id,
  //     timestamp: timestamp // Include timestamp if needed
  //   }));
  
  //   setText('');
  //   setEditorState(EditorState.createEmpty());
  // };
  


  const handleSubmit = async (e) => {
      e.preventDefault();
      // For storing comments
      const contentState = editorState.getCurrentContent();
      if (contentState.hasText() && author.trim()) {
          const rawContentState = convertToRaw(contentState);
          const timestamp = new Date().toISOString(); // Create a valid ISO string

          onCommentSubmit({
              comment: JSON.stringify(rawContentState),
              userId: author,
              id: id,
              timestamp: timestamp, // Include timestamp if needed
          });

          dispatch(
              addComment({
                  comment: JSON.stringify(rawContentState),
                  userId: author,
                  id: id,
                  timestamp: timestamp, // Include timestamp if needed
              })
          );

          setText('');
          setEditorState(EditorState.createEmpty());
      }
  };


  {/*end of handle submit*/}
  const onEditorStateChange = (newEditorState) => {
    setEditorState(newEditorState);
  };

  return (
    <div className='comment-input-wrapper'>
      <Editor
        editorState={editorState}
        onEditorStateChange={onEditorStateChange}
        placeholder="Write a comment..."
        toolbar={{
          options: ['inline', 'list', 'image'],
          inline: {
            options: ['bold', 'italic', 'underline'],
          },
          image: {
            previewImage: true,
            uploadCallback: (file) => {
              return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onloadend = () => {
                  resolve({
                    data: {
                      url: reader.result,
                    },
                  });
                };

                reader.onerror = (reason) => reject(reason);

                reader.readAsDataURL(file);
              });
            },
          },
        }}
        style={{ marginLeft: '8px' }} // Add left margin for spacing
      />
      <button className="comment-send-button" onClick={handleSubmit}>
        <SendIcon />
      </button>
    </div>

  );
}

function CommentsSection({ comment, id, users,editable=true }) {
  const [comments, setComments] = useState(comment);
  const userId = localStorage.getItem('user_id');
  console.log(userId);
  const handleCommentSubmit = (comment) => {
    console.log("cpmment test", comment)
    setComments([...comments, comment]);
  };
  const [showAllComments, setShowAllComments] = useState(false);

  const handleViewAllClick = () => {
    setShowAllComments(true);
  };

  const commentsToDisplay = showAllComments ? comments.slice(0).reverse() : comments.slice(-2).reverse();

  return (
    <div>
      {editable?<CommentForm onCommentSubmit={handleCommentSubmit} userId={userId} id={id} users={users} />:null}
      {comments.length > 0 ? (
        commentsToDisplay.map((comment, index) => (
          <Comment
            key={index}
            comment={comment.comment}
            user={users.length > 0 ? users.find(option => option.value === comment.userId).label : ''}
            timestamp={comment.timestamp}
          />
        ))
      ) : (
        ''
      )}
      {!showAllComments && comments.length > 2 && (
        <button onClick={handleViewAllClick}>View All Comments</button>
      )}
    </div>
  );
}
export default CommentsSection;