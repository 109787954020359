import React, { useState } from 'react';
import { connect, useSelector, useDispatch } from 'react-redux';
import { EditorState, convertToRaw, convertFromRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { editCard, deleteCard } from '../../reducers/listReducer.js';

const RichTextEditor = ({ cardDetails, setCardDetails,editable= true}) => {


  // Convert rawContentState to ContentState
  let contentState;
  try {
    const rawContentState = JSON.parse(cardDetails.description);
    contentState = convertFromRaw(rawContentState);
  }
  catch {
    contentState = null;
  }
  //const [editorState, setEditorState] = useState(EditorState.createWithContent(ContentState.createFromText(cardDetails.description)));
  /*try{
  const [editorState, setEditorState] = useState(EditorState.createWithContent(convertFromRaw(cardDetails.description === '' ? 'Add description...' : cardDetails.description)));
  }
  catch{
    const [editorState, setEditorState] = useState(EditorState.createEmpty());
  }*/

  const [editorState, setEditorState] = useState(contentState === null ? EditorState.createEmpty() : EditorState.createWithContent(contentState));
  const [isEditorActive, setIsEditorActive] = useState(false);
  const dispatch = useDispatch();

  const onEditorStateChange = (newEditorState) => {
    setEditorState(newEditorState);
    setIsEditorActive(true);
  };


  const handlesaveDesc = (event) => {
    const contentState = editorState.getCurrentContent();
    const rawContentState = convertToRaw(contentState);
    setCardDetails({
      ...cardDetails,
      description: JSON.stringify(rawContentState)
    });
    dispatch(editCard({ id: cardDetails._id, listID: cardDetails.listId, card: { ...cardDetails, description: JSON.stringify(rawContentState) } }));
    setIsEditorActive(false);
  };

  return (
    <div>
      <Editor
        readOnly={!editable}
        editorState={editorState}
        onEditorStateChange={editable?onEditorStateChange:()=>null}
        toolbar={
          {
            options: ['inline', 'list', 'image'],
            inline: {
              options: ['bold', 'italic', 'underline'],
            },
            image: {
              previewImage: true,
              uploadCallback: (file) => {
                return new Promise((resolve, reject) => {
                  const reader = new FileReader();
                  reader.onloadend = () => {
                    resolve({
                      data: {
                        url: reader.result,
                      },
                    });
                  };

                  reader.onerror = (reason) => reject(reason);

                  reader.readAsDataURL(file);
                });
              },
            }
          }
        }
      />
      {isEditorActive &&  (
        <button onClick={handlesaveDesc}>Save</button>
      )}
    </div>
  );
};

export default RichTextEditor;