import React, { useState } from "react";
import { connect,useSelector,useDispatch } from 'react-redux';
import { Icon, Card,Button, Input } from "@mui/material";
import TextareaAutosize from 'react-textarea-autosize';
import { addNewList,addNewCard} from '../../reducers/listReducer'
import { faBold, faBorderNone } from "@fortawesome/free-solid-svg-icons";
import AddIcon from "../../Icons/Circle_Add";
import CrossIcon from "../../Icons/Cross";
import useAuth from '../../hooks/useAuth.jsx';
const KanbanActionButton = (props) => {
    const { user } = useAuth();
    const [formOpen, setFormOpen] = useState(false);
    const [formtext, setFormText] = useState('');
    const dispatch = useDispatch();
    console.log("UU",user);
    

    const openForm = () => {
        console.log("Open");
        setFormOpen(true); // Update formOpen state to true when clicked
    };
    const closeForm = () => {
        console.log("Close");
        setFormOpen(false); // Update formOpen state to true when clicked
    };
    const handleInputChange = e => {
        setFormText(e.target.value)
    }
    const handleAddList = () => {
        if (formtext) {
            setFormText("");
            // dispatch(addList(formtext)); // Dispatch addList action
            
            dispatch(addNewList({text:formtext,boardId:props.boardId}));
        }
    };
    const handleAddCard = () => {
        if (formtext) {
            console.log("handle AddCard")
            setFormText("");
            dispatch(addNewCard({reporter:user,listID:props.listID,text:formtext,boardId:props.boardId}));
        }
    };
    const list = props.list;
    console.log(list);
    const renderAddButton = () => {
        //const list = useSelector(state => state.lists);
        const buttonText = list ? "New list" : "New card"
        const buttonTextOpacity = 1;
        const buttonTextColor = "black"
        const buttonTextBG = "inherit"
        return (
            <div onClick={openForm} style={{ ...styles.openForButtonGroup, opacity: buttonTextOpacity, color: buttonTextColor, background: buttonTextBG }}>
                <AddIcon></AddIcon>
                {buttonText}
            </div>
        )
    }
    const renderForm = () => {
        const placeholder = list ? "Enter List title" : "Enter card title";
        const buttonTitle = list ? "Add list" : "Add card";
        return (
            <div>
                <Card style={{
                    
                    minHeight:40,
                    minWidth:240,
                    padding: 25,
                    borderRadius: 30,
                    boxShadow: "none",
                    fontFamily: 'Raleway',
                    fontWeight: 300
                }}>
                    <input type="text" placeholder={placeholder} autoFocus onBlur={closeForm} value={formtext} onChange={handleInputChange} 
                     />
                    <div style={styles.formButtonGroup}>
                        <button onMouseDown={list?handleAddList:handleAddCard} variant="contained">{buttonTitle}{""}</button>
                        <CrossIcon></CrossIcon>
                    </div>
                </Card>
            </div>
        )
    }
    return formOpen ? renderForm() : renderAddButton();
}

const styles = {
    openForButtonGroup: {
        display: "flex",
        flex: 'none',
        alignItems: "center",
        cursor: "pointer",
        fontWeight: 500,
        borderRadius: 20,
        height: 26,
        width: 290,
        paddingLeft: 25
    },
    formButtonGroup: {
        marginTop:8,
        display:"flex",
        alignItems:"center"
    }
}
export default KanbanActionButton;