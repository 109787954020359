import React from "react";

function UserIcon(props: any) {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M14.75 7.75C14.75 9.26878 13.5188 10.5 12 10.5C10.4812 10.5 9.25 9.26878 9.25 7.75C9.25 6.23122 10.4812 5 12 5C13.5188 5 14.75 6.23122 14.75 7.75Z" 
            stroke="currentColor" {...props} 
            stroke-width="1.5" 
            stroke-linecap="round" 
            stroke-linejoin="round"/>
            <path d="M7.24967 15.7338C7.23738 15.3197 6.89179 14.994 6.47775 15.0063C6.06372 15.0186 5.73804 15.3642 5.75033 15.7782L7.24967 15.7338ZM9.885 18.95V18.2C9.8779 18.2 9.87081 18.2001 9.86372 18.2003L9.885 18.95ZM14.115 18.95L14.1363 18.2003C14.1292 18.2001 14.1221 18.2 14.115 18.2V18.95ZM18.2497 15.7782C18.262 15.3642 17.9363 15.0186 17.5222 15.0063C17.1082 14.994 16.7626 15.3197 16.7503 15.7338L18.2497 15.7782ZM16.7503 15.6332C16.7626 16.0473 17.1082 16.373 17.5222 16.3607C17.9363 16.3484 18.262 16.0028 18.2497 15.5888L16.7503 15.6332ZM14.115 12.417V13.167C14.1221 13.167 14.1292 13.1669 14.1363 13.1667L14.115 12.417ZM9.885 12.417L9.86372 13.1667C9.87081 13.1669 9.8779 13.167 9.885 13.167V12.417ZM5.75033 15.5888C5.73804 16.0028 6.06372 16.3484 6.47775 16.3607C6.89179 16.373 7.23738 16.0473 7.24967 15.6332L5.75033 15.5888ZM5.75033 15.7782C5.8165 18.0081 7.67632 19.763 9.90628 19.6997L9.86372 18.2003C8.4611 18.2401 7.29129 17.1363 7.24967 15.7338L5.75033 15.7782ZM9.885 19.7H14.115V18.2H9.885V19.7ZM14.0937 19.6997C16.3237 19.763 18.1835 18.0081 18.2497 15.7782L16.7503 15.7338C16.7087 17.1363 15.5389 18.2401 14.1363 18.2003L14.0937 19.6997ZM18.2497 15.5888C18.1835 13.3589 16.3237 11.604 14.0937 11.6673L14.1363 13.1667C15.5389 13.1269 16.7087 14.2307 16.7503 15.6332L18.2497 15.5888ZM14.115 11.667H9.885V13.167H14.115V11.667ZM9.90628 11.6673C7.67632 11.604 5.8165 13.3589 5.75033 15.5888L7.24967 15.6332C7.29129 14.2307 8.4611 13.1269 9.86372 13.1667L9.90628 11.6673Z" 
            fill="currentColor" {...props}/>
        </svg>
    );
}

export default UserIcon;