// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ` 
/* Project Name Container */
.projectname-container{
    display: flex;
    color: #fff;
    flex-direction: row;
    align-items: center;
    width: 100%;
    max-width: 600px;
    padding: 20px;
    border-radius: 30px;
}

.spinner {
    border: 8px solid rgba(255, 255, 255, 0.3);
    border-radius: 50%;
    border-top: 8px solid black;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

  
.buttons-container{
width: 100%;
display: flex;
flex-direction: row-reverse;
max-width: 600px;
margin: 20px 0px;
/* padding: 20px; */
border-radius: 8px;
/* background-color: #fff; */
}

.upload-button {
    margin: unset;
    margin-top: 15px;
    width: 100%;
}

/* Input labels */
.input-label {
  font-size: 14px;
  /* font-weight: bold; */
  /* color: #333; */
  width: 100px;
}

.form-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
`, "",{"version":3,"sources":["webpack://./src/components/FileUpload/FileUpload.css"],"names":[],"mappings":";AACA,2BAA2B;AAC3B;IACI,aAAa;IACb,WAAW;IACX,mBAAmB;IACnB,mBAAmB;IACnB,WAAW;IACX,gBAAgB;IAChB,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,0CAA0C;IAC1C,kBAAkB;IAClB,2BAA2B;IAC3B,WAAW;IACX,YAAY;IACZ,kCAAkC;AACtC;;AAEA;IACI,KAAK,uBAAuB,EAAE;IAC9B,OAAO,yBAAyB,EAAE;AACtC;;;AAGA;AACA,WAAW;AACX,aAAa;AACb,2BAA2B;AAC3B,gBAAgB;AAChB,gBAAgB;AAChB,mBAAmB;AACnB,kBAAkB;AAClB,4BAA4B;AAC5B;;AAEA;IACI,aAAa;IACb,gBAAgB;IAChB,WAAW;AACf;;AAEA,iBAAiB;AACjB;EACE,eAAe;EACf,uBAAuB;EACvB,iBAAiB;EACjB,YAAY;AACd;;AAEA;IACI,WAAW;IACX,aAAa;IACb,sBAAsB;IACtB,mBAAmB;AACvB","sourcesContent":[" \n/* Project Name Container */\n.projectname-container{\n    display: flex;\n    color: #fff;\n    flex-direction: row;\n    align-items: center;\n    width: 100%;\n    max-width: 600px;\n    padding: 20px;\n    border-radius: 30px;\n}\n\n.spinner {\n    border: 8px solid rgba(255, 255, 255, 0.3);\n    border-radius: 50%;\n    border-top: 8px solid black;\n    width: 40px;\n    height: 40px;\n    animation: spin 1s linear infinite;\n}\n\n@keyframes spin {\n    0% { transform: rotate(0deg); }\n    100% { transform: rotate(360deg); }\n}\n\n  \n.buttons-container{\nwidth: 100%;\ndisplay: flex;\nflex-direction: row-reverse;\nmax-width: 600px;\nmargin: 20px 0px;\n/* padding: 20px; */\nborder-radius: 8px;\n/* background-color: #fff; */\n}\n\n.upload-button {\n    margin: unset;\n    margin-top: 15px;\n    width: 100%;\n}\n\n/* Input labels */\n.input-label {\n  font-size: 14px;\n  /* font-weight: bold; */\n  /* color: #333; */\n  width: 100px;\n}\n\n.form-content {\n    width: 100%;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
