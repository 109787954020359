import { mlend } from '../../api/axios.js';
import React, { useState } from 'react';
import SearchIcon from '../../Icons/Search_5.tsx';
import useAuth from '../../hooks/useAuth.jsx';

const SearchBar = ({selectedDocument, selectedProjectName, setSearchResults }) => {
  const [searchQuery, setSearchQuery] = useState('');
  const {user} = useAuth();
 
  const handleSearch = async () => {
    if (selectedDocument && selectedProjectName && selectedProjectName!=="None")
    {
    try {
      const requestData = {
        user_id: user,
        search_query: searchQuery,
        documents: [selectedDocument],
        project_name: selectedProjectName,
      }
      const response = await mlend.post('semantic-search', requestData, {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (response.data) {
        // Handle the response here
        const data = await response.data;
        setSearchResults(data); // Update search results in the parent component
      } else {
        // Handle errors here
        console.error('Search request failed');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };
}

  return (
    <div  style={{'margin-top': 10+'px'}} className='input-container'>
      <input
        className='chat-input'
        type="text"
        placeholder="Type your search query"
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
      />
      <button className="chat-button" onClick={handleSearch}><SearchIcon className="white"></SearchIcon></button>
    </div>
  );
};

export default SearchBar;
