import React, { useState, useEffect } from 'react';
import './Chat.css';
import { backend,mlend } from '../api/axios';
import useAuth from '../hooks/useAuth';


const SelectionBar = ({ onSelectionChange }) => {
  const { user } = useAuth(); 
  console.log("SELECTION BAR: ", user);


  const [showSelection, setShowSelection] = useState(false);

  const [projects, setProjects] = useState([]);
  const [selectedProject, setSelectedProject] = useState('');
  const [selectedProjectID, setSelectedProjectID] = useState('');
  
  const [documents, setDocuments] = useState([]);
  const [selectedDocument, setSelectedDocument] = useState('');

  const [isAskAboutDocumentChecked, setIsAskAboutDocumentChecked] = useState(false);

  const handleAskAboutDocumentChange = async () => {
    // Toggle checkbox state
    setIsAskAboutDocumentChecked(!isAskAboutDocumentChecked);
    console.log("Checkbox selected: ",isAskAboutDocumentChecked);
  };


const updateAskFromDocument = async () => {
  console.log("Here trying to update")
  try {
    const data = {
      askAboutDocument: isAskAboutDocumentChecked,
      user_id: user,
      selectedProject: selectedProject,
      selectedDocument: selectedDocument
    };
    console.log("data we are going to use: ",data);
    const response = await mlend.post('ask-from-document', data);
    console.log('Ask from Document Updated in Redis',response.data);
  } catch (error) {
    console.error('Error updating the ask from document field:', error);
  }
};


  const handleContinue = async (event) => {
    event.preventDefault();

    setShowSelection(false);
    onSelectionChange(selectedProject, selectedDocument,selectedProjectID);
    await updateAskFromDocument()
  };
  const handleChangeSelection = () => {
    setShowSelection(true);
  };

  useEffect(()=>{
    console.log("Here: ",user);
    backend.get(`user/${user}/projects`)
      .then(response => {
        const projectNames = response.data.map(project => project.projectName);
        setProjects(['None', ...projectNames]);

        if (selectedProject === '') {
          setSelectedProject('None');
          setSelectedProjectID('None');
        }
        else if (selectedProject!=='None' && selectedProject!==''){
          const projectID = response.data.find((project) => project.projectName === selectedProject).projectId;
          setSelectedProjectID(projectID);
        }
        else if(selectedProject==='None'){
          setSelectedProjectID('None');
        }
      })
  },[user,selectedProject]);

  useEffect(()=>{
    if (selectedProject==='None'){
      setDocuments(['None']);
      setSelectedDocument('None');
    }
    else if (selectedProject && selectedProjectID!=='None'){
      console.log("In docs: ",selectedProjectID);
      backend.get(`project/${selectedProjectID}`)
      .then(response => {
        console.log("Response",response.data);
        const files = response.data.files;
        const fileNames = files.map(file => file.name);
        setDocuments(fileNames);
      })
    }
  },[selectedProject,selectedProjectID])

  useEffect(()=>{
    if (documents && documents.length > 0){
      if (selectedDocument && documents.includes(selectedDocument)){
        setSelectedDocument(selectedDocument);
      }
      else {setSelectedDocument(documents[0]);}
    }
  },[documents,selectedDocument])
  

 
  // console.log('Selected Project: ',selectedProject);
  // console.log('Selected ProjectID: ',selectedProjectID);
  // console.log('Selected Document: ',selectedDocument);

  useEffect(()=>{
    mlend.get('store-selected-projectname',{params:{'user_id':user,'project_name':selectedProject},
    headers: { 'accept': 'application/json' }})
    .then (response=> {console.log('Stored Selected Project: ',response.data)})
    .catch (error=> {console.log('Error saving selected project name: ',error)})
    
  },[user,selectedProject]);

  return (
    <div className="projects-container">
      {showSelection ? (
        <>
          <div className='selection-form'>
            <div className="select-container">
              <label>Projects</label>
              <select
                className="select-list"
                value={selectedProject}
                onChange={(e) => setSelectedProject(e.target.value)}
              >
                {projects.map((project) => (
                  <option key={project} value={project}>
                    {project}
                  </option>
                ))}
              </select>
            </div>
            <div className="select-container">
              <label>Files</label>
              <select
                className="select-list"
                value={selectedDocument}
                onChange={(e) => setSelectedDocument(e.target.value)}
              >
                {documents.map((document) => (
                  <option key={document} value={document}>
                    {document}
                  </option>
                ))}
              </select>
            </div>
          </div>
          {/* Checkbox with the text "Ask about Document" */}
          <div className="checkbox-label" style={{ marginTop: '10px' }}>
  <input
    type="checkbox"
    className="checkbox-input"
    checked={isAskAboutDocumentChecked}
    onChange={handleAskAboutDocumentChange}
  />
  <span>Ask about Document</span>
</div>
          <button type="button" onClick={(event) => handleContinue(event)} style={{ 'margin-right': 0 }}>
            Continue
          </button>
        </>
      ) : (
        <>
          {selectedProject !== 'None' && selectedProject!=='' ? (
            <div className="selected-info">
              Your selected project is <b>{selectedProject}</b>, and{' '}
              <b>{selectedDocument}</b> is under discussion.
            </div>
          ) : (
            <div className="selected-info">
              You have not selected any project.
            </div>
          )}
          <button onClick={handleChangeSelection} className="text-link">
            Change selection
          </button>
        </>
      )}
    </div>
  );
};



export default SelectionBar;
