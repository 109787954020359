import React from "react";

function DescriptionIcon(props: any) {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5 7.75C4.58579 7.75 4.25 8.08579 4.25 8.5C4.25 8.91421 4.58579 9.25 5 9.25V7.75ZM19 9.25C19.4142 9.25 19.75 8.91421 19.75 8.5C19.75 8.08579 19.4142 7.75 19 7.75V9.25ZM5 11.75C4.58579 11.75 4.25 12.0858 4.25 12.5C4.25 12.9142 4.58579 13.25 5 13.25V11.75ZM17 13.25C17.4142 13.25 17.75 12.9142 17.75 12.5C17.75 12.0858 17.4142 11.75 17 11.75V13.25ZM5 15.75C4.58579 15.75 4.25 16.0858 4.25 16.5C4.25 16.9142 4.58579 17.25 5 17.25V15.75ZM12 17.25C12.4142 17.25 12.75 16.9142 12.75 16.5C12.75 16.0858 12.4142 15.75 12 15.75V17.25ZM5 9.25H19V7.75H5V9.25ZM5 13.25H17V11.75H5V13.25ZM5 17.25H12V15.75H5V17.25Z" 
            fill="currentColor" {...props} />
        </svg>
    );
}

export default DescriptionIcon;
