import React from "react";

function AddIcon(props: any) {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M5 12.0002C5.00024 8.66068 7.35944 5.78639 10.6348 5.1351C13.9102 4.48382 17.1895 6.23693 18.4673 9.32231C19.7451 12.4077 18.6655 15.966 15.8887 17.8212C13.1119 19.6764 9.41127 19.3117 7.05 16.9502C5.73728 15.6373 4.99987 13.8568 5 12.0002Z" 
            stroke="currentColor" {...props} 
            stroke-width="1.5" 
            strokeLinecap="round" 
            strokeLinejoin="round"/>
            <path d="M9.375 11.2502C8.96079 11.2502 8.625 11.586 8.625 12.0002C8.625 12.4145 8.96079 12.7502 9.375 12.7502V11.2502ZM12 12.7502C12.4142 12.7502 12.75 12.4145 12.75 12.0002C12.75 11.586 12.4142 11.2502 12 11.2502V12.7502ZM12 11.2502C11.5858 11.2502 11.25 11.586 11.25 12.0002C11.25 12.4145 11.5858 12.7502 12 12.7502V11.2502ZM14.625 12.7502C15.0392 12.7502 15.375 12.4145 15.375 12.0002C15.375 11.586 15.0392 11.2502 14.625 11.2502V12.7502ZM12.75 12.0002C12.75 11.586 12.4142 11.2502 12 11.2502C11.5858 11.2502 11.25 11.586 11.25 12.0002H12.75ZM11.25 14.6252C11.25 15.0395 11.5858 15.3752 12 15.3752C12.4142 15.3752 12.75 15.0395 12.75 14.6252H11.25ZM11.25 12.0002C11.25 12.4145 11.5858 12.7502 12 12.7502C12.4142 12.7502 12.75 12.4145 12.75 12.0002H11.25ZM12.75 9.37524C12.75 8.96103 12.4142 8.62524 12 8.62524C11.5858 8.62524 11.25 8.96103 11.25 9.37524H12.75ZM9.375 12.7502H12V11.2502H9.375V12.7502ZM12 12.7502H14.625V11.2502H12V12.7502ZM11.25 12.0002V14.6252H12.75V12.0002H11.25ZM12.75 12.0002V9.37524H11.25V12.0002H12.75Z" 
            fill="currentColor" {...props}/>
        </svg>
    );
}

export default AddIcon;