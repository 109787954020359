// UserMenu.js
import React, { useState } from 'react';
import { Avatar, IconButton, Menu, MenuItem } from '@mui/material';
import useAuth from '../../hooks/useAuth';
import { useNavigate } from 'react-router-dom';
import {  fetchSameOrgUsers } from '../../api/axios.js'; // Import API functions
const UserAvatar = ({ userImage}) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const { user, logout } = useAuth();
    const navigate = useNavigate();
   
    // checkout function to checkout board
    const handleCheckoutClick = (route) => {
        if (typeof route === 'string') {
            navigate(route); // Navigate only if the route is a valid string
        } else {
            console.error('Invalid route:', route); // Log if the route is not a string
        }
    };
    // Logout function to clear session and redirect
    const handleLogout = () => {
      logout();
      // Redirect to login page or home
      navigate('/auth');
    };

    const handleAvatarClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleAvatarClose = () => {
        setAnchorEl(null);
    };

    return (
        <div>
            <IconButton
                onClick={handleAvatarClick}
                sx={{
                    '&:hover': {
                        backgroundColor: 'transparent', // Disables hover background color
                    },
                }}
            >
                <Avatar
                    alt="User"
                    src={userImage}
                    sx={{ width: 48, height: 48 }} // Set the Avatar size (adjust as needed)
                />
            </IconButton>

            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleAvatarClose}
                anchorOrigin={{
                    vertical: 'bottom', // Position the menu below the Avatar
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top', // Aligns the top of the menu with the bottom of the Avatar
                    horizontal: 'right',
                }}
                sx={{
                    mt: 1, // Adds margin-top for additional spacing
                    borderRadius: '25px', // Rounded corners for the menu
                    '& .MuiPaper-root': {
                        padding: '10px', // Add padding to the menu for a larger size
                        borderRadius: '25px', // Ensure the paper has the same border radius
                        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)', // Subtle shadow for depth
                    },
                }}
            >
                <MenuItem
                    onClick={handleAvatarClose}
                    sx={{
                        transition: 'background-color 0.3s', // Smooth transition for hover effect
                        '&:hover': {
                            backgroundColor: '#e0f7fa', // Change background on hover
                        },
                        '&:active': {
                            backgroundColor: '#2196f3', // Blue background on click
                            color: '#fff', // Change text color to white on click
                        },
                    }}
                >
                    Account
                </MenuItem>

                <MenuItem
                    onClick={() => handleCheckoutClick('/checkout')}
                    sx={{
                        transition: 'background-color 0.3s',
                        '&:hover': {
                            backgroundColor: '#e0f7fa', // Change background on hover
                        },
                        '&:active': {
                            backgroundColor: '#2196f3', // Blue background on click
                            color: '#fff', // Change text color to white on click
                        },
                    }}
                >
                    Check Out
                </MenuItem>

                <MenuItem
                    onClick={handleLogout}
                    sx={{
                        transition: 'background-color 0.3s',
                        '&:hover': {
                            backgroundColor: '#e0f7fa', // Change background on hover
                        },
                        '&:active': {
                            backgroundColor: '#2196f3', // Blue background on click
                            color: '#fff', // Change text color to white on click
                        },
                    }}
                >
                    Logout
                </MenuItem>
            </Menu>
        </div>
    );
};

export default UserAvatar;
