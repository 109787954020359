import React from 'react'
// import router from '..';
import { useLocation } from 'react-router-dom';

import './Header.css';
import logo from '../Icons/cropped-logo.png';

const Header = () => {
  const location = useLocation();
  
  // console.log(router);
  return (
    <div className="header">
      <div className="logo">
        <img className="logo" src={logo} alt="logo"></img>
      </div>
      <div className='circle'></div>
      { location.pathname === '/chat' && <div className='title'>Chat</div>  }
      { location.pathname === '/projects' && <div className='title'>Projects</div>  }
      { location.pathname === '/mindmaps' && <div className='title'>Mindmaps</div>  }
      { location.pathname === '/semantic-search' && <div className='title'>Semantic Search</div>  }
      { location.pathname === '/hush-meetings' && <div className='title'>Meetings</div>  }

    </div>
  )
}

export default Header