// ProjectDetailPage.js
import { backend } from '../../../api/axios.js';
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

import BackIcon from '../../../Icons/Arrow_Left.tsx';
import './ProjectDetailPage.css';
import DeleteIcon from '../../../Icons/Trash.tsx';
import FileIcon from '../../../Icons/Notes-lines-alt.tsx';

import useAuth from '../../../hooks/useAuth.jsx';
function ProjectDetailPage() {
  const {user} = useAuth();
  console.log("PROJECT DETAILS PAGE: ",user);
  const { projectName } = useParams(); // Use useParams to access URL parameters
  const [documents, setDocuments] = useState([]);
  const [projectID,setProjectID] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    if (projectName) {
      backend.get(`user/${user}/projects`)
      .then(response => {
        const project_id = response.data.find((project) => project.projectName === projectName).projectId;
        console.log(project_id);

      if (project_id){
        setProjectID(project_id)
          backend
        .get(`project/${project_id}`)
        .then(response => {
          const files = response.data.files;
          const documentNames = files.map((file) => file.name);
          setDocuments(documentNames);
        }).catch((error) => {
          console.error('Error fetching documents:', error);
        });
        }
      })

    } else {
      setDocuments([]);
    }
  }, [projectName]);

  const handleDeleteProject = () => {
    // Make an Axios POST request to delete the project
    console.log("user: ",user);
    backend
      .delete(`user/${user}/project/${projectID}`)
      .then((response) => {
        console.log('Project deleted:', response.data);
        navigate('/projects');

        // You can handle success or update the UI as needed
      })
      .catch((error) => {
        console.error('Error deleting project:', error);
        // Handle errors or show error messages
      });
  };

  const handleDeleteDocument = (documentName) => {
    // // Make an Axios POST request to delete the document
    // backend
    //   .delete('http://localhost:8080/delete-document', {
    //     project_name: projectName,
    //     document_name: documentName,
    //   })
    //   .then((response) => {
    //     console.log('Document deleted:', response.data);
    //     // You can handle success or update the UI as needed
    //     setDeletedDocument(documentName);
    //   })
    //   .catch((error) => {
    //     console.error('Error deleting document:', error);
    //     // Handle errors or show error messages
    //   });
  };

  return (
    <div className='main-page-container'>
      <div className='top-bar-container project-details'>
        <div className='project-heading'>
        <Link className='back-icon' to="/projects"><BackIcon className="white"></BackIcon></Link> {/* Add Go Back button or link */}
        <h2>{projectName}</h2>     
        </div>
        <button className='button-outline button-right-aligned' onClick={handleDeleteProject}>Delete Project</button>
      </div>
      <hr></hr>
      <h4>Files</h4>
      <ul className='project-files-list'>
        {documents.map((documentName) => (
          <li key={documentName}>
            <span className='filename'><FileIcon className="white"></FileIcon>{documentName}</span>
            {/* <button onClick={() => handleDeleteDocument(documentName)}>Delete</button> */}
            <a href={`/projects/${projectName}`} onClick={() => handleDeleteDocument(documentName)}><DeleteIcon></DeleteIcon></a>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default ProjectDetailPage;
