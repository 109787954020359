import axios from 'axios';
import Cookies from 'js-cookie';

const backendURL= 'https://quids.app/api';
const mlendURL='https://quids.app/ml';

// const backendURL= 'http://localhost:5000';
// const mlendURL='http://localhost:8080';
// const backendURL = process.env.REACT_APP_BACKEND_URL || 'http://localhost:5000';
// const mlendURL = process.env.REACT_APP_MLEND_URL || 'http://localhost:8080';
const hushURL = 'https://quids.app/hush_dev';


// const hushURL = 'http://34.123.25.222:9000';



console.log("Backend: ",backendURL);
console.log("ML: ",mlendURL);
console.log("Hush: ",hushURL);


const backend = axios.create({
  baseURL: backendURL,
});

const mlend = axios.create({
  baseURL: mlendURL, // or whatever base URL you need
});

const hushend = axios.create({
  baseURL: hushURL, // or whatever base URL you need
});

backend.interceptors.request.use(
  async (config) => {
    const token = Cookies.get('token');
    const refreshToken = Cookies.get('refreshToken');
    const tokenExpiration = new Date(Cookies.get('tokenExpiration'));
    const refreshTokenExpiration = new Date(Cookies.get('refreshTokenExpiration'));
    console.log("--token",token,tokenExpiration)
    function setToken(token) {
      const tokenExpiration = new Date();
      tokenExpiration.setMinutes(tokenExpiration.getMinutes() + 15);
      Cookies.set('token', token)
      //Cookies.set('token', token, { expires: tokenExpiration });
      Cookies.set('tokenExpiration', tokenExpiration);
    }

    function setRefreshToken(refreshToken) {
      const refreshTokenExpiration = new Date();
      refreshTokenExpiration.setDate(refreshTokenExpiration.getDate() + 7);
      Cookies.set('refreshToken', refreshToken, { expires: refreshTokenExpiration });
      Cookies.set('refreshTokenExpiration', refreshTokenExpiration);
    }

    if (token && tokenExpiration > new Date()) {
      // Token is still valid, attach it to the request headers
      config.headers.Authorization = `Bearer ${token}`;
    } else if (refreshToken && refreshTokenExpiration > new Date()) {
      // Token has expired, but refresh token is still valid
      const response = await axios.post(`${backendURL}/auth/refresh-token`, { refreshToken });
      console.log("--valid")
      if (response.data.token) {
        // Successfully refreshed the token, set the new token and its expiration
        setToken(response.data.token);
        setRefreshToken(response.data.refreshToken);
        config.headers.Authorization = `Bearer ${response.data.token}`;
      } 
      else {
        // Logout the user if token refresh failed
        localStorage.removeItem('username');
        localStorage.removeItem('user_id');
        Cookies.remove('token');
        Cookies.remove('tokenExpiration');
        Cookies.remove('refreshToken');
        Cookies.remove('refreshTokenExpiration');
        // window.location.href = '/auth'; // Redirect to the authentication page
      }
    } else {
      console.log("--logout")
      // Logout the user if the refresh token has also expired
      localStorage.removeItem('username');
      localStorage.removeItem('user_id');
      Cookies.remove('token');
      Cookies.remove('tokenExpiration');
      Cookies.remove('refreshToken');
      Cookies.remove('refreshTokenExpiration');
      // window.location.href = '/auth'; // Redirect to the authentication page
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
const fetchSameOrgUsers = async (userID) => {
  try {
    const response = await backend.get(`/user/${userID}/same-org`);
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

const fetchCommunityBoard = async (userID) => {
  try {
    const response = await backend.get(`boards/community/${userID}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

const fetchCommunityLists = async (boardId) => {
  try {
    const response = await backend.get(`list/${boardId}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};
const moveCardBetweenLists = async (cardId,index,fromListId,toListId) => {
  try {
    const response = await backend.post(`cards/move-card/${cardId}`,{fromListId:fromListId,toListId:toListId,droppableIndexEnd:-1,droppableIndexStart:index});
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};
const updatedStatus = async (userId,status) => {
  try {
    const response = await backend.put(`user/${userId}/status`,{status:status});
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};
const fetchBlockers = async (type,_id) => {
  try {
    const response = await backend.get(`blockers`,type==='cardId'?{cardId:_id}:{id:_id});
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};
const addBlocker = async (payload) => {
  try {
    const response = await backend.post(`blockers`,payload);
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};
const deleteBlocker = async (id) => {
  try {
    console.log("ID",{id:id})
    const response = await backend.delete(`blockers/${id}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};
const updateBlockerStatus = async (id,status) => {
  try {
    const response = await backend.patch(`blockers/${id}/status`,{status:status});
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};
// fetching all cards details task name alogn users

//end here 
export { backend, mlend,hushend,backendURL,fetchCommunityBoard,fetchSameOrgUsers,fetchCommunityLists,moveCardBetweenLists,updatedStatus,fetchBlockers,addBlocker,deleteBlocker,updateBlockerStatus  };
