import React, { useState, useEffect } from 'react';
import './Mindmaps.css';
import { getDimensions } from './dimensions';
import ReactMarkdown from 'react-markdown';


function Sidebar({ isOpen, selectedNode, setIsOpen ,minNodeWidth,minNodeHeight,onSaveNodeChanges}) {
  const [isEditing, setIsEditing] = useState(false);
  const [editedTopic, setEditedTopic] = useState('');
  const [editedContent, setEditedContent] = useState('');

  useEffect(() => {
    if (selectedNode) {
      setEditedTopic(selectedNode.topic);
      setEditedContent(selectedNode.content);
    }
  }, [selectedNode]);

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleSaveClick = () => {
    console.log('Updated Topic:', editedTopic);
    console.log('Updated Content:', editedContent);
    const dimensions = getDimensions(selectedNode.html, {}, "mindmap-node");
    const width = Math.min(dimensions.width, 340);
    const height = Math.max(dimensions.height, 100);
      
    onSaveNodeChanges({
      id: selectedNode.id, // Pass the ID of the edited node
      topic: editedTopic,
      content: editedContent,
      x: selectedNode.x,
      y: selectedNode.y,
      fx: selectedNode.fx,
      fy: selectedNode.fy,
      height: height,
      width:width
      });
    

    setIsEditing(false);
  };

  const handleSidebarClick = (event) => {
    // Check if the click occurred outside of the text input fields and the "Edit" button
    if (
      !event.target.classList.contains('editable-field-content') &&
      !event.target.classList.contains('editable-field-topic') &&
      !event.target.classList.contains('edit-button')
    ) {
      handleSaveClick(); // Save changes when clicking outside
      setIsOpen(false); // Close the sidebar
    }
  };

  return (
    <div className={`sidebar_content ${isOpen ? 'open' : ''}`} onClick={handleSidebarClick}>
      {/* Close button (visible when sidebar is open) */}
      {/* <span className="closebtn" onClick={() => setIsOpen(false)}>
        &times;
      </span> */}
      {isOpen && selectedNode && (
        <div>
          {isEditing ? (
            <div>
              <input
                type="text"
                className="editable-field-topic"
                value={editedTopic}
                onChange={(e) => setEditedTopic(e.target.value)}
              />
              <button onClick={handleSaveClick}>Save</button>
              <textarea
                className="editable-field-content"
                value={editedContent}
                onChange={(e) => setEditedContent(e.target.value)}
              />
            </div>
          ) : (
            <div>
              <h2>{editedTopic}</h2>
              <button className="edit-button" onClick={handleEditClick}>
                Edit
              </button>
              <ReactMarkdown className="linebreaks-included">{editedContent}</ReactMarkdown>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default Sidebar;

