// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Search.css */
.result-separator {
    margin: 20px 0; /* Adjust the margin as needed */
    border: 1px solid #d0d1d280; /* Customize the border style and color */
  }

  .search-results {
    height: 65vh;
    color: white;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }

  .results-container {
    height: 90%;
    overflow-y: auto;
  }
  .results-container::-webkit-scrollbar {
    display: none;
}

  .search-content {
    font-weight: 300;
    font-size: 14px;
    margin-bottom: 10px;
    line-height: 18px;
  }`, "",{"version":3,"sources":["webpack://./src/components/SemanticSearch/Search.css"],"names":[],"mappings":"AAAA,eAAe;AACf;IACI,cAAc,EAAE,gCAAgC;IAChD,2BAA2B,EAAE,yCAAyC;EACxE;;EAEA;IACE,YAAY;IACZ,YAAY;IACZ,aAAa;IACb,sBAAsB;IACtB,gBAAgB;EAClB;;EAEA;IACE,WAAW;IACX,gBAAgB;EAClB;EACA;IACE,aAAa;AACjB;;EAEE;IACE,gBAAgB;IAChB,eAAe;IACf,mBAAmB;IACnB,iBAAiB;EACnB","sourcesContent":["/* Search.css */\n.result-separator {\n    margin: 20px 0; /* Adjust the margin as needed */\n    border: 1px solid #d0d1d280; /* Customize the border style and color */\n  }\n\n  .search-results {\n    height: 65vh;\n    color: white;\n    display: flex;\n    flex-direction: column;\n    overflow: hidden;\n  }\n\n  .results-container {\n    height: 90%;\n    overflow-y: auto;\n  }\n  .results-container::-webkit-scrollbar {\n    display: none;\n}\n\n  .search-content {\n    font-weight: 300;\n    font-size: 14px;\n    margin-bottom: 10px;\n    line-height: 18px;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
