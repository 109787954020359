import React, { useState, useEffect } from 'react';
import './Meetings.css';
import { hushend } from '../../api/axios.js';
import axios from 'axios';
import { Link , useNavigate} from 'react-router-dom';


function Meetings() {
    const [meetingUrl, setMeetingUrl] = useState('');
    const [meetings, setMeetings] = useState([]);
    const [joinStatus, setJoinStatus] = useState('');
    const navigate = useNavigate();


    const handleJoinMeeting = () => {
        hushend.post(`join_meeting/?link=${meetingUrl}`)
            .then(response => {
                // Handle successful join
                console.log('Meeting joined successfully', response);
                setJoinStatus('Meeting joined successfully!');
            })
            .catch(error => {
                // Handle join error
                console.error('Error joining meeting:', error);
                setJoinStatus('Failed to join the meeting.');
            });
    };

    useEffect(() => {
        hushend.get('meetings/')
            .then(response => {
                // Handle successful fetch
                console.log('Meetings fetched successfully', response);
                setMeetings(response.data);
            })
            .catch(error => {
                // Handle fetch error
                console.error('Error fetching meetings:', error);
            });
    }, []);

    const handleMeetingClick = (meeting) => {
        navigate(`/meeting_details/${meeting.id}`, { state: { title: meeting.title, datetime: meeting.datetime } });
      };
      

    return (
        <div className="meetings-container">

            <div className="join-meeting">
                <h3>Invite Hush to Google Meet</h3>
                <input
                    type="text"
                    placeholder="Enter Google Meet URL"
                    value={meetingUrl}
                    onChange={(e) => setMeetingUrl(e.target.value)}
                />
                <button onClick={handleJoinMeeting}>Join</button>
            </div>
            {joinStatus && <p>{joinStatus}</p>}
            <div style={{ height: '20px' }}></div> {/* Empty space */}

            <hr />
            <div style={{ height: '100px' }}></div> {/* Empty space */}

            <div className="meetings-table-wrapper">
                <table className="meetings-table">
                    <thead>
                        <tr>
                            <th>Available Insights</th>
                            <th>Date and Time</th>
                        </tr>
                    </thead>
                    <tbody>
                        {meetings.map(meeting => (
                            <tr key={meeting.id} onClick={() => handleMeetingClick(meeting)} style={{ cursor: 'pointer' }}>
                                <td>{meeting.title}</td>
                                <td>{meeting.datetime}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default Meetings;
