import { createStore, applyMiddleware } from "redux"
import rootReducer from '../reducers'
import {thunk} from 'redux-thunk';
import { configureStore } from "@reduxjs/toolkit";
import listsReducer from "../reducers/listReducer";

export const store = configureStore({
  reducer: {
    list: listsReducer,
  },
  devTools: true,
});
export default store;