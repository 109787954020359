import React from 'react';
import './Assignee_Image.css'; // Import CSS file for styling
import { Avatar } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
const AssigneeImage = ({ imageUrl }) => {
  return (
    <div className="assignee-image-container">
    {imageUrl ? (
      <img className="assignee-image" src={imageUrl} alt="Assignee" />
    ) : (
      <Avatar className="assignee-image">
        <PersonIcon />
      </Avatar>
    )}
  </div>
  );
};

export default AssigneeImage;
