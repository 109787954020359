import React, { useState } from 'react';
import { Grid, Box, Button, Typography } from '@mui/material';
import ChatFooter from './ChatFooter'; // Import the ChatFooter component
import './ChatSection.css';

const ChatPage = () => {
  const [messages, setMessages] = useState([]);
  const [expanded, setExpanded] = useState(false); // Expanded state for toggling

  const handleToggleExpand = () => {
    setExpanded((prev) => !prev);
  };

  return (
    <Grid item xs={12}>
      <Box
        sx={{
          height: expanded ? 'auto' : 200, // Expanded or collapsed height
          maxHeight: expanded ? '80vh' : 200, // Max height when expanded to prevent overflow
          position: 'relative',
          transition: 'height 0.3s ease-in-out, max-height 0.3s ease-in-out', // Smooth transition
          overflow: 'hidden', // Hide overflow when not expanded
          border: '1px solid #ccc', // Add some border to distinguish the box
          borderRadius: '35px',
          padding: '16px',
          backgroundColor: '#fff',
          
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            textAlign: 'left',
            paddingLeft: 2,
            paddingBottom: 0,
          }}
        >
          <h4 style={{ marginTop: 15 }}>
            Ask Me Anything about Community Resources
          </h4>

          {/* Message Container */}
          <Box sx={{ p: 2 }}> {/* Container for padding around the messages */}
            {messages.length > 0 ? (
              messages.map((message, index) => (
                <Grid
                  container
                  key={index}
                  spacing={2}
                  sx={{
                    mb: 4, // Increased margin bottom between messages
                    justifyContent: message.name === 'user' ? 'flex-end' : 'flex-start', // Align user's messages to the right and others to the left
                  }}
                >
                  <Grid
                    item
                    xs={10}
                    sm={8}
                    md={6}
                    sx={{
                      backgroundColor: message.name === 'user' ? '#e0f7fa' : '#f1f1f1', // Different background color for user and recipient
                      borderRadius: '12px',
                      p: 2, // Padding inside the message box
                      boxShadow: 2, // Apply subtle shadow to message boxes
                    }}
                  >
                    <Typography
                      variant="caption"
                      sx={{ fontWeight: 'bold', color: message.name === 'user' ? '#00796b' : '#424242' }}
                    >
                      {message.name === 'user' ? 'You' : message.name}
                    </Typography>
                    <Typography
                      variant="body1"
                      sx={{ wordWrap: 'break-word' }}
                    >
                      {message.text}
                    </Typography>
                  </Grid>
                </Grid>
              ))
            ) : (
              <Typography>No messages yet.</Typography>
            )}
          </Box>
        </Box>

        {/* ChatFooter Component */}
        <ChatFooter setMessages={setMessages} messages={messages} />

        {/* Expand/Collapse Button */}
        <Button
          onClick={handleToggleExpand}
          sx={{ position: 'absolute', top: 8, right: 8 }}
          size="small"
        >
          {expanded ? (
            <i className="material-icons" style={{ color: 'black' }}>expand_less</i>
          ) : (
            <i className="material-icons" style={{ color: 'black' }}>expand_more</i>
          )}
        </Button>
      </Box>
    </Grid>
  );
};

export default ChatPage;
