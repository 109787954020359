import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUsers } from '@fortawesome/free-solid-svg-icons'

function AssigneesIcon(props: any) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="currentColor" {...props}>
      <FontAwesomeIcon icon={faUsers} />
    </svg>
  );
}

export default AssigneesIcon;