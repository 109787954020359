// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.login-page {
    width: 100%;
    display: flex;
    position: relative;
}

.graphic-container {
    height: 100%;
    flex-grow: 1;
    background-color: #fff;
    overflow: hidden;
}

.girl {
    height: 200px;
    position: absolute;
    top: 160px;
    left: -134px;
    z-index: 1;
}

.logo-login {
    width: 200px;
    margin: 40px auto;
    margin-bottom: 16vh;
}

.map {
    height: 100%;
    opacity: 30%;
}

.login-form-container {
    display: flex;
    flex-direction: column;
    padding: 50px;
    background-color: #282e31;
    align-items: center;
    position: absolute;
    right: 0;
    height: 100%;
    width: 45%;
    margin: auto;
}

.login-form-container form {
    width: 300px;
}

.login-text {
    border: unset;
    text-align: center;
}

.login-form-container input {
    margin: 10px 0px;
}`, "",{"version":3,"sources":["webpack://./src/components/Auth/Auth.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,aAAa;IACb,kBAAkB;AACtB;;AAEA;IACI,YAAY;IACZ,YAAY;IACZ,sBAAsB;IACtB,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,kBAAkB;IAClB,UAAU;IACV,YAAY;IACZ,UAAU;AACd;;AAEA;IACI,YAAY;IACZ,iBAAiB;IACjB,mBAAmB;AACvB;;AAEA;IACI,YAAY;IACZ,YAAY;AAChB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,aAAa;IACb,yBAAyB;IACzB,mBAAmB;IACnB,kBAAkB;IAClB,QAAQ;IACR,YAAY;IACZ,UAAU;IACV,YAAY;AAChB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,aAAa;IACb,kBAAkB;AACtB;;AAEA;IACI,gBAAgB;AACpB","sourcesContent":[".login-page {\n    width: 100%;\n    display: flex;\n    position: relative;\n}\n\n.graphic-container {\n    height: 100%;\n    flex-grow: 1;\n    background-color: #fff;\n    overflow: hidden;\n}\n\n.girl {\n    height: 200px;\n    position: absolute;\n    top: 160px;\n    left: -134px;\n    z-index: 1;\n}\n\n.logo-login {\n    width: 200px;\n    margin: 40px auto;\n    margin-bottom: 16vh;\n}\n\n.map {\n    height: 100%;\n    opacity: 30%;\n}\n\n.login-form-container {\n    display: flex;\n    flex-direction: column;\n    padding: 50px;\n    background-color: #282e31;\n    align-items: center;\n    position: absolute;\n    right: 0;\n    height: 100%;\n    width: 45%;\n    margin: auto;\n}\n\n.login-form-container form {\n    width: 300px;\n}\n\n.login-text {\n    border: unset;\n    text-align: center;\n}\n\n.login-form-container input {\n    margin: 10px 0px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
