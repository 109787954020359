import React, { useContext, useEffect, useState } from 'react';
import useAuth from '../../hooks/useAuth';
import { Navigate } from 'react-router-dom';
import Cookies from 'js-cookie';

const RequireAuth = ({ children }) => {
  const { user, loading } = useAuth(); // Access loading state from AuthContext
  const token = Cookies.get('token');
  const refreshToken = Cookies.get('refreshToken')
  const user_id = localStorage.getItem('user_id');
  const tokenExpiration = new Date(Cookies.get('tokenExpiration'));
  const refreshTokenExpiration = new Date(Cookies.get('refreshTokenExpiration'));


  console.log("THIS IS THE USER ID FROM CONTEXT AND STORAGE: ",user,user_id);

  console.log("User ID is: ",user_id);
  console.log("Tokens: ",token);
  console.log("Refresh Token:",refreshToken);
  console.log("Exp Token:",tokenExpiration);
  console.log("Exp Refresh Token:",refreshTokenExpiration);

// If it's loading, show a loading indicator (or null)
if (loading) {
  return <div>Loading...</div>; // or whatever loading component/element you'd like
}

// Check if no user or no token
if (!user || !token) {
  console.log('to auth')
  return <Navigate to="/auth" />;
}

// Check if the token has expired
if (new Date() > tokenExpiration) {
  // If the refresh token is also expired, navigate to auth page
  if (new Date() > refreshTokenExpiration) {
      console.log("In ref exp");
      return <Navigate to="/auth" />;
  }
  
  // At this point, the access token has expired but the refresh token is valid.
  // Typically, you'd make an API call to refresh the access token using the refresh token.
  // Since this logic is beyond the scope of the current component, I'll return the children 
  // but in a real-world scenario, you'd want to handle this token refresh process.
  return children;
}

return children;
};

export default RequireAuth;

