// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.meetings-container {
    margin: 20px;
  }
  
  .join-meeting {
    display: flex;
    gap: 100px;
    width: 100%; /* Ensure it takes up the full width */
    align-items: center; /* Align items vertically in the center */
  }
  
  .join-meeting input {
    flex-grow: 1;
    height: 5vh; /* Ensures the input has the same height as the button */
    width: 20vh;
  }
  .join-meeting h3 {
    border-bottom: none; /* Removes bottom border */
    box-shadow: none; /* Removes box shadow */
  }
  
  .join-meeting button {
    height: 5vh;
    width: 20vh; /* Adjust the width as needed */
    text-align: center;
  }
  
  
  .meetings-container {
    margin: 30px;
    width: calc(100% - 40px); /* Adjust based on margin to ensure full width utilization */
  }
  
  .meetings-table-wrapper {
    max-height: 300px;
    overflow-y: auto;
    width: 100%; /* Ensure the table wrapper takes up the full width */
  }
  
  .meetings-table {
    width: 100%; /* Ensure the table itself also takes up the full width */
  }
`, "",{"version":3,"sources":["webpack://./src/components/Meetings/Meetings.css"],"names":[],"mappings":"AAAA;IACI,YAAY;EACd;;EAEA;IACE,aAAa;IACb,UAAU;IACV,WAAW,EAAE,sCAAsC;IACnD,mBAAmB,EAAE,yCAAyC;EAChE;;EAEA;IACE,YAAY;IACZ,WAAW,EAAE,wDAAwD;IACrE,WAAW;EACb;EACA;IACE,mBAAmB,EAAE,0BAA0B;IAC/C,gBAAgB,EAAE,uBAAuB;EAC3C;;EAEA;IACE,WAAW;IACX,WAAW,EAAE,+BAA+B;IAC5C,kBAAkB;EACpB;;;EAGA;IACE,YAAY;IACZ,wBAAwB,EAAE,4DAA4D;EACxF;;EAEA;IACE,iBAAiB;IACjB,gBAAgB;IAChB,WAAW,EAAE,qDAAqD;EACpE;;EAEA;IACE,WAAW,EAAE,yDAAyD;EACxE","sourcesContent":[".meetings-container {\n    margin: 20px;\n  }\n  \n  .join-meeting {\n    display: flex;\n    gap: 100px;\n    width: 100%; /* Ensure it takes up the full width */\n    align-items: center; /* Align items vertically in the center */\n  }\n  \n  .join-meeting input {\n    flex-grow: 1;\n    height: 5vh; /* Ensures the input has the same height as the button */\n    width: 20vh;\n  }\n  .join-meeting h3 {\n    border-bottom: none; /* Removes bottom border */\n    box-shadow: none; /* Removes box shadow */\n  }\n  \n  .join-meeting button {\n    height: 5vh;\n    width: 20vh; /* Adjust the width as needed */\n    text-align: center;\n  }\n  \n  \n  .meetings-container {\n    margin: 30px;\n    width: calc(100% - 40px); /* Adjust based on margin to ensure full width utilization */\n  }\n  \n  .meetings-table-wrapper {\n    max-height: 300px;\n    overflow-y: auto;\n    width: 100%; /* Ensure the table wrapper takes up the full width */\n  }\n  \n  .meetings-table {\n    width: 100%; /* Ensure the table itself also takes up the full width */\n  }\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
