import { AccordionButton } from "react-bootstrap";
import { CONSTANTS } from "../actions";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { backendURL } from '../api/axios';
import { act } from "react-dom/test-utils";


export const fetchBoard = createAsyncThunk("fetchTodos", async (boardId) => {
  const response = await fetch(backendURL + "/list/" + boardId);
  return response.json();
});

export const addNewList = createAsyncThunk("addNewList", async (listData) => {
  const payload = { title: listData.text, boardId: listData.boardId }
  console.log("payload", payload)
  const response = await fetch(backendURL + "/list", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  });
  return response.json();
});


export const addNewCard = createAsyncThunk("addNewCard", async (cardData) => {
  const payload = { reporter:cardData.reporter,title: cardData.text, description: "", boardId: cardData.boardId }
  console.log("payload", payload)
  const response = await fetch(backendURL + "/cards/" + cardData.listID + "/addCard", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  });
  return response.json();
});
export const moveCard = createAsyncThunk("moveCard", async (data, { getState }) => {
  console.log("F");
  const state = getState();
  console.log(state);
  const list = state.list.find(list => data.droppableIdEnd === String(list._id));
  console.log("E",list);
  const card = list.cards.find(card => data.draggableId === String(card._id));
  console.log("G",card);
  if (data.droppableIdEnd === "66387048135bee17b63715d7" && card.community) {

    data.droppableIdEnd = "664f29c218df2f5268bbe9c3";
    data.droppableIndexEnd = 0;
  }
  const payload = { fromListId: data.droppableIdStart, toListId: data.droppableIdEnd, droppableIndexEnd: data.droppableIndexEnd, droppableIndexStart: data.droppableIndexStart }
  console.log("payload", payload)
  const response = await fetch(backendURL + "/cards/move-card/" + data.draggableId, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  });
  return data;
});
export const editCard = createAsyncThunk("editCard", async (data) => {
  const payload = data.card
  console.log("payload", payload, data)
  const response = await fetch(backendURL + "/cards/" + data.id, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  });
  return response.json();
});

export const editListTitle = createAsyncThunk("editListTitle", async (data) => {
  const response = await fetch(backendURL + "/list/" + data.listID + "/title", {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ title: data.listTitle }),
  });
  return data;
});
export const deleteCard = createAsyncThunk("deleteCard", async (data) => {
  const response = await fetch(backendURL + "/list/" + data.listID + "/cards/" + data.id, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
    },
  });
  return data;
});
export const addComment = createAsyncThunk("addComment", async (data) => {
  console.log(data)
  const payload = { userId: String(data.userId), comment: data.comment }
  console.log("COMMENT", payload)
  const response = await fetch(backendURL + "/cards/" + data.id + "/comments", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  });
  return response.json();
});

const listSlice = createSlice({
  name: "Lists",
  initialState: [],
  reducers: {
    DragHappened: (state, action) => {
      console.log("drag", action)
      const {
        droppableIdStart,
        droppableIdEnd,
        droppableIndexEnd,
        droppableIndexStart,
        draggableId,
        type
      } = action.payload;
      console.log(droppableIdStart,
        droppableIdEnd,
        droppableIndexEnd,
        droppableIndexStart,
        draggableId)
      const newState1 = state;
      if (type === "list") {
        const list = state.splice(droppableIndexStart, 1);
        newState1.splice(droppableIndexEnd, 0, ...list);
        return newState1;
      }
      // in the same list
      if (droppableIdStart === droppableIdEnd) {
        const list = state.find(list => droppableIdStart === String(list._id));
        console.log('ls', list)
        const card = list.cards.splice(droppableIndexStart, 1);
        list.cards.splice(droppableIndexEnd, 0, ...card);
        console.log('ls1', list)
      }
      if (droppableIdStart !== droppableIdEnd) {
        const listStart = state.find(list => droppableIdStart === String(list._id));
        // pull out the card from this list
        const card = listStart.cards.splice(droppableIndexStart, 1);
        // find the list where the drag ended
        const listEnd = state.find(list => droppableIdEnd === String(list._id));
        // put the card in the new list
        listEnd.cards.splice(droppableIndexEnd, 0, ...card);
        console.log("list changed")
      }
      return state;
    },
    editCard: (state, action) => {

    }

  },
  extraReducers: (builder) => {
    builder.addCase(fetchBoard.fulfilled, (state, action) => {
      console.log("feteched", action.payload);
      return action.payload; // Set the state to the payload of fetchTodos
    });
    builder.addCase(addNewList.fulfilled, (state, action) => {
      console.log("ADD_List", action, state);
      return [...state, action.payload];
    });
    builder.addCase(moveCard.fulfilled, (state, action) => {
      /*const {
        droppableIdStart,
        droppableIdEnd,
        droppableIndexEnd,
        droppableIndexStart,
        draggableId,
        type
      } = action.payload;
      console.log(droppableIdStart,
        droppableIdEnd,
        droppableIndexEnd,
        droppableIndexStart,
        draggableId)
      const newState1 = state;
      if (type === "list") {
        const list = state.splice(droppableIndexStart, 1);
        newState1.splice(droppableIndexEnd, 0, ...list);
        return newState1;
      }
      // in the same list
      if (droppableIdStart === droppableIdEnd) {
        console.log("ss",JSON.stringify(state),action);
        const list = state.find(list => droppableIdStart === String(list._id));
        console.log('ls', list)
        const card = list.cards.splice(droppableIndexStart, 1);
        list.cards.splice(droppableIndexEnd, 0, ...card);
        console.log('ls1', list)
      }
      if (droppableIdStart !== droppableIdEnd) {
        const listStart = state.find(list => droppableIdStart === String(list._id));
        // pull out the card from this list
        const card = listStart.cards.splice(droppableIndexStart, 1);
        // find the list where the drag ended
        const listEnd = state.find(list => droppableIdEnd === String(list._id));
        // put the card in the new list
        listEnd.cards.splice(droppableIndexEnd, 0, ...card);
        console.log("list changed")
      }*/
      return state;

    });
    builder.addCase(editCard.fulfilled, (state, action) => {
      const id = action.payload._id;
      const listId = action.payload.listId;
      const cardText = action.payload.title;
      console.log("edit Card", id, listId, cardText, action.payload)

      return state.map(list => {
        if (list._id === listId) {
          console.log("list found")
          return {
            ...list,
            cards: list.cards.map(card => {
              if (card._id === id) {
                console.log("card found")
                return { ...card, title: cardText }; // Return a new card object with updated text
              }
              return card;
            })
          };
        }
        return list;
      });
    });
    builder.addCase(addNewCard.fulfilled, (state, action) => {

      const newCard = action.payload;
      const newState = state.map(list => {
        if (list._id === action.payload.listId) {
          return { ...list, cards: [...list.cards, newCard] }
        }
        else {
          return list;
        }
      }
      )
      console.log("NEW_STATE")
      console.log(newState);
      return newState;
    });
    builder.addCase(editListTitle.fulfilled, (state, action) => {
      console.log("EDIT_TITLE")
      console.log(action)
      const { listID, listTitle } = action.payload;
      console.log(listID, listTitle);
      return state.map(list => {
        if (list._id === listID) {
          console.log(listID, listTitle);
          return { ...list, title: listTitle };
        } else {
          return list;
        }
      });
    });
    builder.addCase(deleteCard.fulfilled, (state, action) => {
      const { id, listID } = action.payload;
      console.log("DELETE_CARD", id, listID, action.payload);
      return state.map(list => {
        if (list._id === listID) {
          const newCards = list.cards.filter(card => card._id !== id);
          return { ...list, cards: newCards };
        } else {
          return list;
        }
      });
    });
    builder.addCase(addComment.fulfilled, (state, action) => {
      const id = action.payload._id;
      const listId = action.payload.listId;
      const comments = action.payload.comments;
      console.log("add comment Card", id, listId, action.payload)

      return state.map(list => {
        if (list._id === listId) {
          console.log("list found")
          return {
            ...list,
            cards: list.cards.map(card => {
              if (card._id === id) {
                console.log("card found")
                return { ...card, comments: comments }; // Return a new card object with updated text
              }
              return card;
            })
          };
        }
        return list;
      });
    });



  },
});
export const {
  DragHappened
} = listSlice.actions;
export default listSlice.reducer;