 import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { backend } from '../../api/axios.js';
import { fetchBoard } from '../../reducers/listReducer.js';
import { useSelector, useDispatch } from 'react-redux';
import Cookies from 'js-cookie';
import AddBoard from './AddBoard.jsx';
import './Board.css';
import { experimentalStyled as styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { Typography } from '@mui/material';

const Board = () => {
  const [boards, setBoards] = useState([]);
  const dispatch = useDispatch();
  const userID = localStorage.getItem('user_id');

  const navigate = useNavigate();
  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: '#fff',
    borderRadius: '20px',
    boxShadow: 3, // Adds a Material-UI shadow effect (level 3 is moderate)
    '&:hover': {
      backgroundColor: '#f0f0f0',
      boxShadow: 4, // Increase shadow level on hover for a more prominent effect
    },
    ...theme.typography.body2,
    padding: theme.spacing(2),

    color: theme.palette.text.secondary,
    ...theme.applyStyles('dark', {
      backgroundColor: '#1A2027',
    }),
    cursor: 'pointer',
  }));

  const handleBoardClick = (board) => {
    console.log("B", board)
    navigate(`/projects/${board._id}`, { state: { title: board.title } });
  };

  useEffect(() => {
    backend.get(`boards/get-boards/${userID}`)
      .then(response => {
        setBoards(response.data);

      })
      .catch(error => {
        console.error('Error fetching boards:', error);
      });
  }, [userID]);
  return (
    <Box sx={{ width: '100%', height: '100vh' }}> {/* Full width and height container */}
      <AddBoard />
      {/* Grid Container for Boards */}
      <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
        {boards.map((board) => (
          <Grid item xs={4} sm={3} md={3} key={board._id}>
            <Item>
              <Box onClick={() => handleBoardClick(board)}>
                <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                  {board.title}
                </Typography>
              </Box>
            </Item>
          </Grid>
        ))}
      </Grid>
    </Box>

  );
};

export default Board;