import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { IconButton } from '@mui/material';
import SendIcon from '../../Icons/Send_2.tsx';

import { LinearProgress, Typography } from '@mui/material';
import { updatedStatus, fetchSameOrgUsers } from '../../api/axios.js'; // Import API functions
import DashboardButtons from './DashboardSwitch.jsx'; // Adjust path as per your structure

const Item = styled(Paper)(({ theme }) => ({
    borderRadius: '35px',
    ...theme.typography.body2,
    color: theme.palette.text.secondary,
}));
const Dashboard3 = (progress) => {
    const [isDashboardEnabled, setIsDashboardEnabled] = useState(false); // Track dashboard state
    const [isCheckedIn, setIsCheckedIn] = useState(false); // Track check-in status
    const [showTransition, setShowTransition] = useState(false); // Control transition on first check-in
    const [userImage, setUserImage] = useState([]); // State for user image
    const userID = localStorage.getItem('user_id'); // Get user ID from local storage
    const navigate = useNavigate();

    const handleButtonClick = (route) => {
        navigate(route); // Navigate on button click
    };

    // Check if user is checked in when the component mounts
    useEffect(() => {
        const fetchUserStatus = async () => {
            if (userID) {
                try {
                    const data = await fetchSameOrgUsers(userID); // Fetch user data
                    const user = data.find(item => item._id === userID); // Get user object
                    const userStatus = user.status;
                    const userImageUrl = user.profilePicture; // Assuming the image URL is in the `image` field

                    setUserImage(userImageUrl); // Set user image
                    setIsCheckedIn(userStatus === "Checked In");
                    setIsDashboardEnabled(userStatus === "Checked In");

                    // If the user was already checked in, no transition
                    const alreadyCheckedIn = localStorage.getItem('isCheckedIn') === 'true'; // Get persisted check-in status
                    if (alreadyCheckedIn) {
                        setShowTransition(false);
                    } else {
                        setTimeout(() => {
                            setShowTransition(true); // Enable transition on the first check-in
                        }, 100);
                    }
                } catch (error) {
                    console.error('Error fetching user data:', error);
                }
            }
        };

        fetchUserStatus();
    }, [userID]);
    // Handle the check-in process
    const handleCheckIn = async () => {
        try {
            await updatedStatus(userID, "Checked In"); // Update status in the DB
            setIsCheckedIn(true); // Update local state
            setIsDashboardEnabled(true); // Enable dashboard after check-in
            localStorage.setItem('isCheckedIn', 'true'); // Persist the check-in status
            setShowTransition(true); // Show the transition on first check-in
        } catch (error) {
            console.error("Error updating status:", error);
        }
    };
    const getColor = (status) => {
        switch (status) {
            case "started":
                return "#C3E0E5"; // Light blue for started tasks
            case "timeExceeded":
                return "#F8D7DA"; // Light red for time exceeded tasks
            case "pending":
                return "#E2E3E5"; // Light gray for pending tasks
            default:
                return "#FFFFFF"; // Default color
        }
    };
    const TaskItem = ({ task }) => {
        return (
            <Box
                sx={{
                    backgroundColor: getColor(task.status),
                    borderRadius: '20px',
                    padding: '15px',
                    margin: '20px 0',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    // Match border color with respective status
                    border: `2px solid ${task.status === "timeExceeded" ? '#BC4B4B' : // Color for time exceeded
                        task.status === "started" ? '#81C1D3' : // Color for started
                            '#D9D9D9' // Default color for other statuses
                        }`,
                    position: 'relative',
                }}
            >
                <Box>
                    <Typography
                        variant="body1"
                        sx={{ fontWeight: '500', color: '#333', textAlign: 'left' }} // Align text to the left
                    >
                        {task.title}
                    </Typography>
                    <Typography
                        variant="body2"
                        sx={{ color: '#666', textAlign: 'left' }} // Align text to the left
                    >
                        Estimated time: <span style={{ color: 'black', fontWeight: 'bold' }}>{task.estimatedTime}</span>
                    </Typography>
                </Box>
                {/* Status Badge timeExceeded */}
                {task.status === "timeExceeded" && (
                    <Box
                        sx={{
                            backgroundColor: '#BC4B4B',
                            color: '#fff',
                            borderRadius: '20px',
                            padding: '5px 15px', // Adjusted padding for better width
                            position: 'absolute',
                            top: '0',
                            right: '0',
                            marginRight: '20px', // Right margin
                            transform: 'translateY(-50%)', // Adjust the position
                            fontSize: '12px',
                            fontWeight: 'bold',
                            minWidth: '100px', // Minimum width for the badge
                            textAlign: 'center', // Center align text
                            border: '2px solid #BC4B4B', // Optional: add a border for better visibility
                            boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)', // Optional: add shadow for depth
                        }}
                    >
                        Time exceeded
                    </Box>
                )}
                {/* Status Badge started */}
                {task.status === "started" && (
                    <Box
                        sx={{
                            backgroundColor: '#81C1D3',
                            color: '#fff',
                            borderRadius: '20px',
                            padding: '5px 15px', // Adjusted padding for better width
                            position: 'absolute',
                            top: '0',
                            right: '0',
                            marginRight: '20px', // Right margin
                            transform: 'translateY(-50%)', // Adjust the position
                            fontSize: '12px',
                            fontWeight: 'bold',
                            minWidth: '100px', // Minimum width for the badge
                            textAlign: 'center', // Center align text
                            border: '2px solid #81C1D3', // Optional: add a border for better visibility
                            boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)', // Optional: add shadow for depth
                        }}
                    >
                        Started
                    </Box>
                )}
                {/* Status Badge started */}
                {task.status === "pending" && (
                    <Box
                        sx={{
                            backgroundColor: '#81D673', // Red color for 'Time Exceeded'
                            color: '#fff',
                            borderRadius: '0 20px 20px 0', // Rounded only on the right side
                            padding: '5px 10px', // Reduced padding to make the badge narrower
                            position: 'absolute',
                            right: '0', // Align the badge to the right
                            top: '0', // Start from the top of the container
                            bottom: '0', // Stretch to the bottom of the container
                            display: 'flex', // Flexbox for vertical alignment
                            alignItems: 'center', // Center the text vertically
                            fontSize: '12px',
                            fontWeight: 'bold',
                            width: 'auto', // Remove fixed width
                            textAlign: 'center', // Center align the text horizontally
                            border: 'none', // Remove borders
                            boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)', // Optional: subtle shadow for depth
                            zIndex: 1, // Ensure it stays within parent boundaries
                        }}
                    />
                )}
            </Box>
        );
    };
    const tasks = [
        {
            title: "Design new Inquisitive UI",
            estimatedTime: "2 hours",
            status: "started",
        },
        {
            title: "Get update on keychains",
            estimatedTime: "30 minutes",
            status: "timeExceeded",
        },
        {
            title: "Add videos to Mamaar store",
            estimatedTime: "1 hour",
            status: "pending",
        },
        {
            title: "Add product descriptions to store",
            estimatedTime: "1 hour",
            status: "pending",
        },
        {
            title: "Add product descriptions to store",
            estimatedTime: "1 hour",
            status: "pending",
        },
    ];
    return (
        <Box sx={{ width: '100%', height: '100vh', padding: { xs: 1, sm: 3, md: 5 }, overflow: 'hidden' }}>
            <Grid
                container
                spacing={3}
                sx={{
                    mb: { xs: 1, sm: 2, md: 3 }, // Existing bottom margin
                    marginTop: { xs: '0px', sm: '-30px', md: '-60px' }, // Responsive top margin
                }}
            >
                <Grid
                    item
                    xs={12}
                    md={12}
                    lg={12}
                    sx={{
                        opacity: isCheckedIn ? 1 : 0.3, // Semi-transparent when not checked in
                        filter: isCheckedIn ? 'none' : 'blur(8px)', // Blur effect when not checked in
                        transition: showTransition ? 'opacity 0.5s ease, filter 0.5s ease' : 'none', // Disable transition after first check-in
                        padding: 1, // Optional: Add some padding if needed
                        marginTop: '-20px', // Apply negative margin to push upwards
                    }}
                >
                    {/* Dashboard Buttons Section */}
                    <Box
                        sx={{
                            display: 'flex', // Enables flexbox for layout
                            alignItems: 'center', // Vertically centers content
                            justifyContent: 'flex-start', // Aligns content to the left
                            width: '100%', // Ensures full width for the inner box
                        }}
                    >
                        <DashboardButtons onButtonClick={handleButtonClick} />
                    </Box>

                    <Item
                        sx={{
                            background: '#FFCC80', // Background color
                            marginTop: '-12px',
                            height: { xs: '100px', sm: '120px', md: '140px' }, // Responsive heights
                            display: 'flex', // Enables flexbox for layout
                            alignItems: 'center', // Vertically centers content
                            justifyContent: 'center', // Horizontally centers content
                            boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)', // Subtle shadow effect
                            padding: { xs: '8px', sm: '12px', md: '16px' }, // Responsive padding
                        }}
                    >

                        {/* Icon on the left */}
                        <Box
                            sx={{
                                width: '100px',
                                height: '100px',
                                backgroundColor: '#F7C24C',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                borderRadius: '20px',
                                marginRight: '32px', // Space between icon and progress bar
                            }}
                        >
                            <img
                                src={userImage} // User's image URL
                                alt="User"
                                style={{ width: '45px', height: '45px' }}
                            />
                        </Box>

                        {/* Progress bar and label */}
                        <Box sx={{ display: 'flex', alignItems: 'center', width: '80%', background: '#fff', padding: 2, borderRadius: '35px' }}>
                            <Typography
                                variant="h6"
                                sx={{
                                    color: 'black',
                                    marginRight: '8px', // Space between text and progress bar
                                    fontWeight: 'bold',
                                }}
                            >
                                Today's Progress
                            </Typography>
                            <LinearProgress
                                variant="determinate"
                                value={progress} // Replace with actual progress value
                                sx={{
                                    height: '16px',
                                    borderRadius: '8px',
                                    backgroundColor: '#E0E0E0',
                                    flexGrow: 1, // Make the progress bar take the remaining space
                                    '& .MuiLinearProgress-bar': {
                                        backgroundColor: '#4CAF50', // Progress bar color
                                    },
                                }}
                            />
                        </Box>

                    </Item>
                </Grid>
                <Grid
                    item
                    xs={7}
                    sx={{
                        opacity: isCheckedIn ? 1 : 0.3,
                        filter: isCheckedIn ? 'none' : 'blur(8px)',
                        transition: showTransition ? 'opacity 0.5s ease, filter 0.5s ease' : 'none',
                        position: 'relative', // Set relative position for proper positioning of child elements
                        height: {
                            xs: 'auto',          // Automatic height for extra-small screens
                            sm: '30vh',          // 40% of the viewport height for small screens
                            md: '40vh',          // 50% of the viewport height for medium screens
                            lg: '50vh'           // 60% of the viewport height for large screens
                        },
                        paddingTop: 0, // No top padding on the Grid item
                    }}
                >
                    <Item
                        sx={{
                            height: '100%', // Ensure the Item takes full height of the Grid
                            background: '#DBC389',
                            display: 'flex',
                            flexDirection: 'column', // Stack children vertically
                            justifyContent: 'flex-start',
                            padding: { xs: 0, sm: 4 }, // No padding at the top, keep the bottom padding
                            textAlign: 'center',
                            position: 'relative',
                        }}
                    >

                        {/* Heading for the Item */}
                        <Box
                            sx={{
                                width: '100%',
                                paddingBottom: '5px', // Reduced padding below heading
                                marginTop: '-10px', // Further reduced margin to pull heading up
                                position: "sticky",
                                top: 0,
                                left: 0,
                                right: 0,
                                zIndex: 1,
                                backgroundColor: '#DBC389', // Ensures background covers content when sticky
                                height: 'auto',
                            }}
                        >
                            <Typography
                                variant="h6"
                                sx={{
                                    fontFamily: 'Montserrat',
                                    fontWeight: '900',
                                    fontSize: '24px',
                                    color: '#fff', // Customize heading color
                                    textAlign: 'center',
                                }}
                            >
                                Remembrall
                            </Typography>
                        </Box>
                        {/* Input Box */}
                        <Box
                            sx={{
                                flex: 1,
                                padding: "10px",
                                backgroundColor: "#f0f0f0",
                                borderRadius: "25px",
                                position: "absolute", // Position the box absolutely within the Item
                                bottom: 0, // Stick to the bottom of the parent Item
                                left: 0,
                                right: 0,
                                margin: "15px", // Add margin if needed
                            }}
                        >
                            <form>
                                <input
                                    type="text"
                                    placeholder="What do you want to remember?"
                                    style={{
                                        color: '#00000066',
                                        fontFamily: 'Raleway',
                                        fontSize: '16px',
                                        width: '100%',
                                        border: 'none',
                                        outline: 'none',
                                        background: 'transparent',
                                        textAlign: 'left',
                                        paddingRight: '40px', // Space for the button
                                    }}
                                />
                                <button
                                    className="comment-send-button"
                                    style={{
                                        position: "absolute",
                                        right: "10px",
                                        top: "50%",
                                        transform: "translateY(-50%)",
                                        border: "none",
                                        background: "transparent",
                                        cursor: "pointer",
                                    }}
                                >
                                    <SendIcon
                                        style={{
                                            fill: "none",
                                            stroke: "#D8BD7D",
                                            strokeWidth: "2",
                                            width: "40px", // Adjusted size for the icon
                                            height: "40px",
                                        }}
                                    />
                                </button>
                            </form>
                        </Box>
                    </Item>
                </Grid>

                <Grid
                    item
                    xs={5}
                    sx={{
                        opacity: isCheckedIn ? 1 : 0.3,
                        filter: isCheckedIn ? 'none' : 'blur(8px)',
                        transition: showTransition ? 'opacity 0.5s ease, filter 0.5s ease' : 'none',
                        position: 'relative', // Set relative position for proper positioning of child elements
                        height: {
                            xs: 'auto',           // Automatic height for extra-small screens
                            sm: '30vh',           // 40% of the viewport height for small screens
                            md: '40vh',           // 50% of the viewport height for medium screens
                            lg: '50vh'            // 60% of the viewport height for large screens
                        },
                        paddingTop: 0, // No top padding on the Grid item
                    }}
                >
                    <Item
                        sx={{
                            height: '100%', // Ensure the Item takes full height of the Grid
                            background: '#FFFFFF',
                            display: 'flex',
                            flexDirection: 'column', // Stack children vertically
                            justifyContent: 'flex-start',
                            padding: { xs: 0, sm: 4 }, // No padding at the top, keep the bottom padding
                            textAlign: 'center',
                            position: 'relative',
                        }}
                    >
                        {/* Heading for the Item */}
                        <Box
                            sx={{
                                width: '100%',
                                paddingBottom: '5px', // Reduced padding below heading
                                marginTop: '-10px', // Further reduced margin to pull heading up
                                position: "sticky",
                                top: 0,
                                left: 0,
                                right: 0,
                                zIndex: 1,
                                backgroundColor: '#FFFFFF', // Ensures background covers content when sticky
                                height: 'auto',
                            }}
                        >
                            <Typography
                                variant="h6"
                                sx={{
                                    fontFamily: 'Montserrat',
                                    fontWeight: '900',
                                    fontSize: '24px',
                                    color: '#333',
                                    textAlign: 'center',
                                }}
                            >
                                Today’s tasks
                            </Typography>
                        </Box>
                        {/* Task List */}
                        <Box
                            sx={{
                                width: '100%',
                                marginTop: '10px', // Spacing between heading and tasks
                                flex: 1, // Task list takes remaining space
                                overflowY: 'auto', // Enable scrolling for tasks
                                maxHeight: 'calc(100% - 20px)', // Adjust maxHeight to prevent overflow with header
                            }}
                        >
                            {tasks.map((task, index) => (
                                <TaskItem key={index} task={task} />
                            ))}
                        </Box>
                    </Item>
                </Grid>

                {/* Show the check-in button only if the user is not checked in */}
                {!isCheckedIn && (
                    <Grid
                        item
                        xs={12}
                        sx={{
                            zIndex: 2, // Ensure button is above the dashboard shadow
                            position: 'absolute',
                            top: '20%',
                            left: '55%',
                            transform: 'translate(-50%, -50%)',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '200px',
                            backgroundColor: 'transparent',
                        }}
                    >
                        <Item
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                boxShadow: 'none',
                                background: 'transparent',
                            }}
                        >
                            <Button
                                variant="contained"
                                onClick={handleCheckIn}
                                sx={{
                                    backgroundColor: '#e8e8e8',
                                    color: '#000',
                                    fontWeight: 'bold',
                                    fontSize: '16px',
                                    padding: '20px 60px',
                                    borderRadius: '20px',
                                    border: '2px solid black',
                                    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)',
                                    '&:hover': {
                                        backgroundColor: '#d0d0d0',
                                    },
                                }}
                            >
                                Check in to get started
                            </Button>
                        </Item>
                    </Grid>
                )}
            </Grid>
        </Box >
    );
};
export default Dashboard3;
