import React from 'react';
// import Header from './layouts/Header';
import SideBar from './layouts/Sidebar';

import './App.css'; // Import the CSS file here


const App = (Component) => ({ ...props }) => (
    <div className="layout">
      
      <SideBar />
      <div className="main-container">
        <Component {...props} />
      </div>
    </div>
  );
  

export default App;

{/* <Header /> */}
      {/* <div className="container"> */}    // </div>
