import React, { useState,useEffect  } from 'react';
import { fetchBlockers,addBlocker, deleteBlocker,updateBlockerStatus } from '../../api/axios';
import { Box, List, ListItem, ListItemText, Button, Stack, TextField } from '@mui/material';


const Blockers = ({cardId,reporterId,userName,userId}) => {
  const [input, setInput] = useState('');
  const [items, setItems] = useState([]);
  console.log("RR",cardId,reporterId,userName,userId);

  useEffect(() => {
    const fetchData = async () => {
        try {
            const items = await fetchBlockers({type:"cardId",cardId:cardId});
            
            setItems(items.map(item => item));
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    fetchData();
    }, [cardId]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (input.trim()) {
        const payload = {cardId:cardId,text:input,reporterId:reporterId,userName:userName,userId:userId,createdAt:new Date()}
        const item = await addBlocker(payload);
        setItems([...items, payload]);
        setInput('');
    }
  };

  const handleToggleDone = (index) => {

    const newItems = items.map((item, i) =>
      i === index ? { ...item, status: !item.status } : item
    );
    const data = updateBlockerStatus(items[index]._id,!items[index].status)
    setItems(newItems);
  };

  const handleDelete = async (index) => {
    const newItems = items.filter((_, i) => i !== index);
    const data = await deleteBlocker(items[index]._id);
    setItems(newItems);
  };

  return (
    // <div>
      
    //   <ul>
    //     {items.map((item, index) => (
    //       <li key={index} style={{ textDecoration: item.status ? 'line-through' : 'none' }}>
    //         {item.text}
    //         <button onClick={() => handleToggleDone(index)}>{item.status ? 'unresolve' : 'resolved'}</button>
    //         <button onClick={() => handleDelete(index)}>delete</button>
    //       </li>
    //     ))}
    //   </ul>
    //   <form onSubmit={handleSubmit}>
    //     <input
    //       type="text"
    //       value={input}
    //       onChange={(e) => setInput(e.target.value)}
    //     />
    //     <button type="submit">Submit</button>
    //   </form>
    // </div>
    <Box>
      <ul>
        {items.map((item, index) => (
          <li 
            key={index} 
            style={{ 
              textDecoration: item.status ? 'line-through' : 'none', 
              display: 'flex', 
              justifyContent: 'space-between', 
              alignItems: 'center' 
            }}
          >
            <span>{item.text}</span>
            <div style={{ marginTop: '10px' }}> {/* Add margin-top to create space */}
              <button onClick={() => handleToggleDone(index)} style={{ marginRight: '10px' }}>
                {item.status ? 'Unresolve' : 'Resolved'}
              </button>
              <button onClick={() => handleDelete(index)}>Delete</button>
            </div>
          </li>
        ))}
      </ul>
      <form onSubmit={handleSubmit}>
        <Stack direction="column" spacing={1} sx={{ width: '100%' }}>
          <TextField
            variant="outlined"
            value={input}
            onChange={(e) => setInput(e.target.value)}
            placeholder="Add new item"
            fullWidth
            sx={{
              flexGrow: 1,
              '& input': {
                textAlign: 'left', // Align text to the left
                padding: '10px',   // Adjust padding to center the placeholder
              },
              '& .MuiOutlinedInput-notchedOutline': {
                border: 'none', // To remove the outline
              },
              '&:focus-visible': {
                outline: 'none',
              },
            }}
            InputProps={{
              sx: {
                padding: '0', // Remove default padding
              },
            }}
          />
          
        </Stack>
        <button type="submit" style={{ marginTop: '10px' }}>Submit</button>

      </form>
    </Box>
  


  );
};

export default Blockers;
