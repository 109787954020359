// StatusChip.jsx
import React from 'react';
import { Chip } from '@mui/material';

const StatusChip = ({ status }) => {
  const getStatusChip = () => {
    switch (status) {
      case 'To Do':
        return <Chip label="To Do" color="primary" style={{ backgroundColor: '#2196F3', color: '#fff' }} />;
      case 'In Progress':
        return <Chip label="In Progress" color="success" style={{ backgroundColor: '#4CAF50', color: '#fff' }} />;
      case 'In Review':
        return <Chip label="In Review" color="warning" style={{ backgroundColor: '#FFC107', color: '#fff' }} />;
      case 'Completed':
        return <Chip label="Completed" color="secondary" style={{ backgroundColor: '#FF5722', color: '#fff' }} />;
      default:
        return <Chip label={status} />;
    }
  };

  return getStatusChip();
};

export default StatusChip;
