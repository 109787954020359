// CircularCheckbox.js
import React from 'react';

const CircularCheckbox = ({ checked, onChange }) => {
  return (
    <div className="checkbox-container" onClick={onChange}>
      <input type="checkbox" checked={checked} onChange={() => {}} />
      <svg viewBox="0 0 24 24" className={`checkbox-svg ${checked ? 'checked' : ''}`}>
        <circle cx="12" cy="12" r="8" />
        {checked && <path d="M7 13l3 3l7-7" />}
      </svg>
    </div>
  );
};

export default CircularCheckbox;
