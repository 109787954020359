import React from "react";

function DeleteIcon(props: any){
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M16.167 9H7.833C7.37295 9 7 9.37295 7 9.833V16.5C7 17.8807 8.11929 19 9.5 19H14.5C15.163 19 15.7989 18.7366 16.2678 18.2678C16.7366 17.7989 17 17.163 17 16.5V9.833C17 9.37295 16.6271 9 16.167 9Z" 
            stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M15 7L14.276 5.553C14.1067 5.21427 13.7607 5.0002 13.382 5H10.618C10.2393 5.0002 9.89326 5.21427 9.724 5.553L9 7H15Z" 
            stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M11.083 12.333C11.083 11.9188 10.7472 11.583 10.333 11.583C9.91879 11.583 9.583 11.9188 9.583 12.333H11.083ZM9.583 15.666C9.583 16.0802 9.91879 16.416 10.333 16.416C10.7472 16.416 11.083 16.0802 11.083 15.666H9.583ZM14.417 12.333C14.417 11.9188 14.0812 11.583 13.667 11.583C13.2528 11.583 12.917 11.9188 12.917 12.333H14.417ZM12.917 15.666C12.917 16.0802 13.2528 16.416 13.667 16.416C14.0812 16.416 14.417 16.0802 14.417 15.666H12.917ZM15 6.25C14.5858 6.25 14.25 6.58579 14.25 7C14.25 7.41421 14.5858 7.75 15 7.75V6.25ZM17 7.75C17.4142 7.75 17.75 7.41421 17.75 7C17.75 6.58579 17.4142 6.25 17 6.25V7.75ZM9 7.75C9.41421 7.75 9.75 7.41421 9.75 7C9.75 6.58579 9.41421 6.25 9 6.25V7.75ZM7 6.25C6.58579 6.25 6.25 6.58579 6.25 7C6.25 7.41421 6.58579 7.75 7 7.75V6.25ZM9.583 12.333V15.666H11.083V12.333H9.583ZM12.917 12.333V15.666H14.417V12.333H12.917ZM15 7.75H17V6.25H15V7.75ZM9 6.25H7V7.75H9V6.25Z" 
            fill="black"/>
        </svg>
    );
}

export default DeleteIcon;
