import { backend } from '../../api/axios';
import React, { useState, useEffect } from 'react';
import './FileInput.css'
import './FileUpload.css';
import './ProgressBarComponent.css'; 
import FileInput from './FIleInput';
import ProgressBar from './ProgressBar';
import useAuth from '../../hooks/useAuth';


const FileUploadComponent = () => {
    const { user } = useAuth(); // Access USER ID FROM AUTH CONTEXT
    console.log("FILE UPLOAD: ", user);
    const [fileList, setFileList] = useState([]);
    
    const [projectName, setProjectName] = useState('');
    
    const [uploading, setUploading] = useState(false);
    const [progress, setProgress] = useState(0);
    const [progressChunks, setProgressChunks] = useState(0);
    const [currentProcess, setCurrentProcess] = useState('');

    const handleFileChange = (e) => {
      const selectedFiles = Array.from(e.target.files);
      const uniqueSelectedFiles = selectedFiles.filter((file) =>
    fileList.every((existingFile) => existingFile.name !== file.name)
    );
      setFileList((prevFiles) => [...prevFiles, ...uniqueSelectedFiles]);
    };

    const handleFileDelete = (index) => {
      setFileList((prevFiles) => prevFiles.filter((_, i) => i !== index));
    };
    
    // Use a useEffect hook to log changes to fileList
    useEffect(() => {
      console.log("FileList Updated: ", fileList);
    }, [fileList]);

    useEffect(() => {
      console.log("Uploading state has changed:", uploading);
  }, [uploading]);
  
    const handleUpload = async () => {
      console.log("handleUpload: Initiating upload process...");
  
      resetProgress();
  
      try {
          if (!fileList || !projectName) {
              console.log("handleUpload: Missing fileList or projectName.");
              console.log("Project Name: ", projectName);
              console.log("File List: ", fileList);
              return;
          }
          
          const formData = new FormData();
          
          // Append files one by one
          for (let i = 0; i < fileList.length; i++) {
              formData.append('files', fileList[i]);
          }
          // Append the project name as a JSON object
          formData.append('project_name', projectName);
          
          setCurrentProcess("Uploading ...");
          setProgress(0);
  
          console.log("handleUpload: Setting uploading to true.");
          setUploading(true);

  
          console.log("handleUpload: Sending FormData...", formData);
          
          try {
          const response = await backend.post(`user/${user}/project`, formData, {
              headers: {
                  "Content-Type": "multipart/form-data",
              },
              timeout: 180000
          
          });
          if (response.data.status!=="Project creation in progress"){
            console.error("Error during project creation:", response.data.status);
            alert(`Error: Error during project creation in the backend`);
          }
          else {
            console.log("Uploading Project")
          }
          
        } catch (error) {
          if (error.code === 'ECONNABORTED') {
              console.error('A timeout occurred.');
              alert(`A timeout occured:', ${error.message}`);

          } else {
              console.error('An error occurred:', error.message);
              alert(`An error occurred:', ${error.message}`);
          }
      
        } 
  
          console.log("handleUpload: Server response received - ");
  
          console.log("handleUpload: Checking progress states...");
          console.log("Progress: ", progress);
          console.log("Progress Chunks: ", progressChunks);
          console.log("Uploading State (Should be true here): ", uploading);
          console.log("Current Process: ", currentProcess);
  
          // After logging server response in handleUpload function
setTimeout(() => {
  checkProgressRecursive();
}, 2000);  // Wait 2 seconds before checking progress

      } catch (err) {
          console.error("handleUpload: Error encountered while uploading.", err);
          console.log("handleUpload: Setting uploading to false due to error.");
          setUploading(false);
          setCurrentProcess(`Upload Failed`);
      }
  };
  
  const checkProgressRecursive = async () => {
      console.log("checkProgressRecursive: Checking upload progress. Uploading state:", uploading);
  
      // if (!uploading) {
      //     console.log("checkProgressRecursive: Not uploading, exiting recursive check.");
      //     return;
      // }
  
      try {
          const response = await backend.get('upload-progress/progress');
          console.log("checkProgressRecursive: Progress response received - ", response.data);
  
          const receivedProgress = response.data['progress'];
          const receivedCurrentProcess = response.data['current_process'];
          const receivedProgressChunks = response.data['progress_chunks'];
  
          setProgress(receivedProgress);
          setProgressChunks(receivedProgressChunks);
          setCurrentProcess(receivedCurrentProcess);
  
          if (receivedProgress < 100 && currentProcess!=="") {
              console.log("checkProgressRecursive: Progress is less than 100%. Checking again in 1 second.");
              setTimeout(checkProgressRecursive, 1000);

          }
           
          else {
              console.log("checkProgressRecursive: Upload is complete. Setting uploading to false.");
              setUploading(false);
              setCurrentProcess('Project Created !');
              setProgressChunks(100);
              resetProgress();
              setProjectName('');
              setFileList([]);
              return
          }

      } catch (error) {
          console.error("checkProgressRecursive: Error encountered while checking progress.", error);
          console.log("checkProgressRecursive: Setting uploading to false due to error.");
          setUploading(false);
      }
  };

    const resetProgress = () => {
        try{
            backend.get(`upload-progress/reset-progress`);
            setCurrentProcess('')
            setProgress(0)
            setProgressChunks(0)

        } catch (error){
            console.error("Error resetting progress:", error);
            };
    };

    return (
        <div className="modal-container">
          <h3>Create new project</h3>
          <div className='form-content'>
            <div className="projectname-container">
              {/* <label htmlFor="projectName" className="input-label">
                Name
              </label> */}
              <input
                type="text"
                value={projectName}
                onChange={(e) => setProjectName(e.target.value)}
                placeholder="Name"
                className="project-name-input"
              />
            </div>
        
            <FileInput
              fileList={fileList}
              handleFileChange={handleFileChange}
              handleFileDelete={handleFileDelete}
              />
            <div className="buttons-container">
              <button onClick={handleUpload} className="upload-button">
                Upload
              </button>
            </div>
        
            {uploading && <div className="spinner"></div>}
                <ProgressBar progress={progress} currentProcess={currentProcess} />
          </div>
        </div>
      );
};

export default FileUploadComponent;
